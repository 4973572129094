import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';

export function useDownloadFiles() {
    const [ loading, setLoading ] = useState<boolean>(false);

    function getFileExtension(url) {
        return url.split('.').pop().split(/\#|\?/)[0];
    }

    return {
        loading,
        download: (items: { url?, buffer?, name?, directory? }[], filename) => {
            return new Promise(async (resolve, reject) => {
                try{
                    setLoading(true);
                    const zip = new JSZip();
                    const fetchPromises = items.map(({ url, buffer, name, directory }, index) => new Promise(async (resolve, reject) => {
                        const fileName = `${name ?? url.split("/").slice(-1)[0].split(".")[0]}.${getFileExtension(url)}`;
                        try {
                            if(buffer){
                                zip.folder(`${directory ?? "geral"}`).file(fileName, buffer);
                                resolve(true);
                            }else{
                                const response = await axios.get(url, { responseType: 'arraybuffer' });
                                zip.folder(`${directory ?? "geral"}`).file(fileName, response.data);
                                resolve(true);
                            }
                        } catch (error) {
                            showNotification({ message: `Falha ao baixar arquivo ${name}:` + error.message, color: "red" });
                            resolve(false);
                        }
                    }));
                    await Promise.all(fetchPromises);
                    zip.generateAsync({ type: 'blob' })
                    .then((content) => {
                        saveAs(content, `${filename}.zip`);
                        resolve(true);
                    })
                    .catch((err) => {
                        reject(err)
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                }catch(err){
                    setLoading(false);
                    reject(err);
                }
            })
        }
    }
}