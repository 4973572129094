import { ActionIcon, Card, Center, Grid, Group, Menu, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { MdOutlineGroups2 } from "react-icons/md";
import { Teams } from '../components';
import { useAuth } from '../contexts/auth.context';
import { DELETE_TEAM, GET_ALL_TEAMS } from '../services/teams';
import { FaEdit, FaEllipsisV } from 'react-icons/fa';
import { useApp } from '../contexts/app.context';
import { showNotification } from '@mantine/notifications';

export default function TeamsPage({ adding = false, onClose = null }) {
    const [open, setOpen] = React.useState(false);
    const [updatingTeam, setUpdatingTeam] = React.useState(null);
    const [teams, setTeams] = React.useState<any[]>([]);

    const listRef = React.useRef<any>();

    const { role } = useAuth();
    const { confirmDialog } = useApp();

    const loadData = () => {
        GET_ALL_TEAMS()
            .then(items => {
                setTeams(items)
            })
            .catch(err => {

            })
    }

    useEffect(() => {
        loadData();
    }, [role])

    useEffect(() => {
        if(!!adding) setOpen(true);
    }, [adding]);
    
    useEffect(() => {
        if(!open) onClose && onClose();
    }, [open]);

    return <>

        <Grid>
            {teams.map(team => <Grid.Col md={3} key={`${team.id}`}>
                <Card shadow='md' p="md" radius={5} style={{minHeight: 140}}>
                    <Group>
                        <ActionIcon style={{opacity: 0}}></ActionIcon>
                        <Center style={{flex: 1}}><MdOutlineGroups2 size="1.5rem" /></Center>
                        <Menu>
                            <Menu.Target>
                                <ActionIcon><FaEllipsisV /></ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() => {
                                        setUpdatingTeam(team);
                                        setOpen(true);
                                    }}
                                >Editar</Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        confirmDialog(
                                            { text: "Realmente deseja deletar esse time?" },
                                            ({ confirmed }) => {
                                                if(confirmed) DELETE_TEAM(team._id)
                                                .then(() => {
                                                    loadData();
                                                })
                                                .catch(err => {
                                                    showNotification({ message: err.message, color: 'red' })
                                                })
                                            }
                                        )
                                        
                                    }}
                                >Deletar</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                    <Group position="center">
                        <Title style={{ flex: 1 }} align='center' order={3} color="black">{team.title}</Title>
                    </Group>
                    <Group position="center">
                        <Text align='center' color="gray" size="sm">{team.total} usuários atribuidos</Text>
                        {/* <Text color="gray" size="xs">{team.total}</Text> */}
                    </Group>
                </Card>
            </Grid.Col>)}
        </Grid >

        {/*<Teams.List ref={listRef} />*/}
        <Teams.Create
            open={open}
            data={updatingTeam}
            onClose={() => setOpen(false)}
            onSave={() => {
                // listRef?.current?.refresh();
                loadData()
                setOpen(false);
            }}
        />

    </>
}
