import { ActionIcon, Avatar, Box, Card, Grid, Group, LoadingOverlay, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useMemo, useState } from "react";
import { FaChartPie, FaClock, FaComment, FaPlus, FaThumbsUp, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SimpleHeader from '../components/simple_header';
import { JobsCalendar } from '../components/jobs_calendar';
import { GET_ALL_CUSTOMERS } from "../services/customers";
import { GET_ALL_JOBS } from "../services/jobs";
import { useAuth } from "../contexts/auth.context";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { groupBy } from "../utility/util";
import { useApp } from "../contexts/app.context";

const localizer = momentLocalizer(moment)
  
export default function Home(){
    const [jobs, setJobs] = useState([]);
    const [loadingItems, setLoadingItems] = React.useState<boolean>(false);
    
    const navigate = useNavigate();
    const {role} = useAuth();
    const {configs} = useApp();

    const qtToApprove = (jobs.filter(j => !j.archived && ["draft", "to-approve"].includes(j.statusKey)).length);
    const qtAdjust = (jobs.filter(j => !j.archived && ["adjust"].includes(j.statusKey)).length)
    const qtApproved = (jobs.filter(j => !j.archived && ["approved"].includes(j.statusKey)).length)

    const loadJobs = () => {
        setLoadingItems(true)
        GET_ALL_JOBS()
        .then(res => {
            setJobs(res);
            setLoadingItems(false)
        })
        .catch(err => {
            setLoadingItems(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    useEffect(loadJobs, [])

    return <div style={{position: 'relative'}}>
        <SimpleHeader
            title="Dashboard"
            subtitle="Página inicial"
            button={role && {title: "Adicionar Job", leftIcon: <FaPlus />, size: 'md', onClick: () => { navigate("/jobs/novo") }}} />
        
        <LoadingOverlay visible={loadingItems} />
        <Grid>
            <Grid.Col xs={12} md={6}>
                <Paper shadow="xs" style={{minHeight: 380}}>
                    <Box style={{background: configs?.primary, borderRadius: 4}}>
                        <Title p="xl" order={2} weight="bold" color="white">Overview de jobs</Title>
                        <div style={{height: 40}}></div>
                    </Box>
                    <div style={{marginTop: -60}}>
                        <Grid p="lg">
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{cursor: 'pointer'}} onClick={() => navigate("/jobs")}>
                                    <Group style={{justifyContent: 'center'}}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaClock /></ActionIcon>
                                        <Title order={2}>{qtToApprove}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Jobs pendentes</Title>
                                </Paper>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{cursor: 'pointer'}} onClick={() => navigate("/jobs")}>
                                    <Group style={{justifyContent: 'center'}}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaComment /></ActionIcon>
                                        <Title order={2}>{qtAdjust}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Pedidos de ajuste</Title>
                                </Paper>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{cursor: 'pointer'}} onClick={() => navigate("/jobs")}>
                                    <Group style={{justifyContent: 'center'}}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaThumbsUp /></ActionIcon>
                                        <Title order={2}>{qtApproved}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Aprovados pelo cliente</Title>
                                </Paper>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{cursor: 'pointer'}} onClick={() => navigate("/jobs")}>
                                    <Group style={{justifyContent: 'center'}}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaChartPie /></ActionIcon>
                                        <Title order={2}>{jobs.length}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Total de jobs realizados</Title>
                                </Paper>
                            </Grid.Col>
                        </Grid>
                    </div>
                </Paper>
            </Grid.Col>
            {role && role?.profile?.title === "Administrador" && <Grid.Col xs={12} md={6}>
                <CustomersPaper />
            </Grid.Col>}
            <Grid.Col md={12}>
                <JobsCalendar jobs={jobs} />
            </Grid.Col>
        </Grid>
    </div>
}

const CustomersPaper = () => {
    const [customers, setCustomers] = useState([]);
    const [loadingCustomers, setLoadingCustomers] = React.useState<boolean>(false);

    const navigate = useNavigate();

    const loadCustomers = () => {
        setLoadingCustomers(true)
        GET_ALL_CUSTOMERS()
        .then(res => {
            setCustomers(res.filter(c => c.active));
            setLoadingCustomers(false)
        })
        .catch(err => {
            setLoadingCustomers(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    useEffect(loadCustomers, [])
    
    return <Paper shadow="xs" p="xl" style={{minHeight: 380}}>
        <LoadingOverlay visible={loadingCustomers} />
        <Title mb="xl" order={4}>Clientes</Title>
        <Group align="flex-start" style={{justifyContent: 'center'}}>
            {customers.slice(0, 11).map(c => (
                <UnstyledButton onClick={() => navigate(`/jobs?customer=${c._id}`)}>
                    <Group style={{width: 100, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                        <Card style={{padding: 0}} shadow="xs"><Avatar size="lg" src={c.image} /></Card>
                        <Text align="center" size="xs" color="lime">{c.name}</Text>
                    </Group>
                </UnstyledButton>
            ))}
            <UnstyledButton onClick={() => navigate("/clientes/novo")}>
                <Group style={{width: 100, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                    <ActionIcon color="lime" variant="outline" size="xl"><FaPlus /></ActionIcon>
                    <Text align="center" size="xs" color="lime">Adicionar Cliente</Text>
                </Group>
            </UnstyledButton>
        </Group>
    </Paper>
}