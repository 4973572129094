import React from 'react';
import { CREATE_TEAM, UPDATE_TEAM } from '../../services/teams';
import { Title } from '@mantine/core';
import { InputFieldTypes } from '../input_field';
import ModalForm from '../modal_form';

export default function Create({ open = true, onClose, onSave, data: dataParent = null }) {
    const [formData, setFormData] = React.useState<any>(null);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [error, setError] = React.useState<any>(null);
    const [profiles, setProfiles] = React.useState<any[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);

    const onConfirm = () => {
        setError(null);
        setLoadingSave(true);

        (
            dataParent?._id
            ? UPDATE_TEAM(dataParent?._id, { ...formData })
            : CREATE_TEAM({ ...formData })
        )
        .then(res => {
            setLoadingSave(false)
            onSave && onSave()
        })
        .catch(err => {
            setLoadingSave(false)
            setError(err.message)
        })
    }

    const COLUMNS = [
        { title: "Nome da equipe", name: 'title', type: InputFieldTypes.STRING },
    ]

    React.useEffect(() => {
        setFormData(open ? {} : null)
    }, [open])
    
    React.useEffect(() => {
        if(open && dataParent) setFormData(dataParent)
    }, [open, dataParent])

    return <ModalForm
        title={<Title pt="md" style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }} order={2}>Adicionar equipe</Title>}
        columns={COLUMNS}
        loading={loadingSave}
        error={error}
        onChangeData={(dt) => setFormData(fd => ({ ...fd, ...dt }))}
        formData={formData}
        open={Boolean(formData)}
        onConfirm={onConfirm}
        onClose={onClose}
    />
}