import * as React from "react";
import {
  FaCalendar,
  FaHome,
  FaLock,
  FaRegFileWord,
  FaSign
} from "react-icons/fa";
import { default as HomePage } from "../pages/home";
import InvitePage from "../pages/invite";
import LoginPage from "../pages/login";
import PolicyPage from "../pages/policy";
import RecoverPage from "../pages/recover";
import SignupPage from "../pages/signup";
import SettingsPage from "../pages/settings";
import JobPage from "../pages/job";
import PublicJobPage from "../pages/public_job";
import PublicPage from "../pages/public";
import JobsPage from "../pages/jobs";
import AdminPage from "../pages/admin";
import CustomersPage from "../pages/customers";
import OAuthPage from "../pages/oauth";
import JobPreviewPage from "../pages/job_preview";
import OnboardingPage from "../pages/onboarding";
import RequestJobPage from "../pages/request_job";

export interface RouteProps {
  title: string;
  key: string;
  icon?: React.ReactNode;
  url: string;
  component: React.ReactNode;
  private?: boolean;
  menu?: boolean;
  layout?: boolean;
  condition?: (data: { user: any; role: any }) => boolean;
}
const routes: RouteProps[] = [
  {
    title: "Login",
    key: "login",
    icon: <FaLock />,
    url: "/login",
    component: <LoginPage />,
  },
  {
    title: "Cadastro",
    key: "signup",
    icon: <FaSign />,
    url: "/cadastrar",
    component: <SignupPage />,
  },
  {
    title: "Cadastro de cliente",
    key: "signup-customer",
    icon: <FaSign />,
    url: "/cadastrar/cliente",
    component: <SignupPage customer />,
  },
  {
    title: "Cadastro com cupom",
    key: "signup-coupon",
    icon: <FaSign />,
    url: "/cadastrar/:coupon",
    component: <SignupPage />,
  },
  {
    title: "Convite",
    key: "invite",
    url: "/convite/:key",
    component: <InvitePage />,
  },
  {
    title: "Recuperar senha",
    key: "recover",
    url: "/recuperacao/:key",
    component: <RecoverPage />,
  },
  {
    title: "Dashboard",
    key: "home",
    icon: <FaHome />,
    url: "/",
    menu: true,
    private: true,
    component: <HomePage />,
  },
  {
    title: "Onboarding",
    key: "home",
    icon: <FaHome />,
    url: "/bem-vindo",
    menu: false,
    private: false,
    component: <OnboardingPage />,
  },
  {
    title: "Jobs",
    key: "jobs",
    icon: <FaHome />,
    url: "/jobs",
    menu: true,
    private: true,
    component: <JobsPage />,
  },
  {
    title: "Calendário",
    key: "calendar",
    icon: <FaCalendar />,
    url: "/jobs?present=calendar",
    menu: true,
    private: true,
    component: <JobsPage />,
  },
  // {
  //   title: "Jobs",
  //   key: "job-preview",
  //   url: "/jobs/:jobId/preview",
  //   menu: false,
  //   private: false,
  //   component: <JobPreviewPage />,
  // },
  {
    title: "Clientes",
    key: "customers",
    icon: <FaHome />,
    url: "/clientes",
    condition: ({role}) => role?.profile?.title === "Administrador",
    menu: true,
    private: true,
    component: <CustomersPage />,
  },
  {
    title: "Clientes",
    key: "customers",
    icon: <FaHome />,
    url: "/clientes/:customerId",
    condition: ({role}) => role?.profile?.title === "Administrador",
    menu: false,
    private: true,
    component: <CustomersPage />,
  },
  {
    title: "Configurações",
    key: "settings",
    icon: <FaHome />,
    url: "/configuracoes",
    menu: true,
    private: true,
    component: <SettingsPage />,
  },
  {
    title: "Configurações da conta",
    key: "settings",
    icon: <FaHome />,
    url: "/checkout",
    menu: false,
    private: true,
    component: <SettingsPage fixed="checkout" />,
  },
  {
    title: "Job",
    key: "job",
    url: "/jobs/:jobId",
    condition: ({role}) => !!role,
    menu: false,
    private: true,
    component: <JobPage />,
  },
  {
    title: "Solicitar Job",
    key: "request",
    url: "/solicitar",
    condition: ({ role }) => !role,
    menu: false,
    private: true,
    component: <RequestJobPage />,
  },
  {
    title: "Job",
    key: "public_job",
    url: "/j/:jobId",
    menu: false,
    private: false,
    component: <PublicJobPage />,
  },
  {
    title: "Share",
    key: "public",
    url: "/:key",
    menu: false,
    private: false,
    component: <PublicPage />,
  },
  {
    title: "Admin",
    key: "admin",
    url: "/admin-aprova-ai",
    condition: ({user}) => !!user?.isAdmin,
    menu: true,
    private: true,
    component: <AdminPage />,
  },
  {
    title: "Política de Privacidade",
    key: "signup-company",
    icon: <FaRegFileWord />,
    url: "/politica-de-privacidade",
    component: <PolicyPage />,
  },
  {
    title: "OAuth",
    key: "oauth",
    url: "/oauth/:origin",
    component: <OAuthPage />,
  },
];

export default routes;
