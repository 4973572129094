import { api } from "./api";

export const GET_PLAN_PAYMENTS = async () => {
    const {data} = await api.get(`/payments/plans`)
    return data;
}

export const GET_PAYMENT_CONFIGS = async (origin : "aprova-ai" = "aprova-ai") => {
    const {data} = await api.get(`/payments/${origin}/configs`)
    return data;
}

export const SAVE_PAYMENT_METHOD = async (
    origin : "aprova-ai" = "aprova-ai",
    data,
    customer,
) => {
    const { data: dt } = await api.post(`/payments/${origin}/payment/method`, {
        data,
        customer,
        type: origin,
    })
    return dt;
}

export const REFUND_PAYMENT = async (id: string, value) => {
    const {data} = await api.post(`/payments/${id}/refund`, { value })
    return data;
}

export const GET_BILLING_DETAILS = async () => {
    const {data} = await api.get(`/payments/billing`)
    return data;
}

export const SAVE_COMPANY_PAYMENT_METHOD = async (params: any) => {
    const {data} = await api.patch("/payments/methods/all", params)
    return data;
}

export const UNSUBSCRIBE_COMPANY = async () => {
    const {data} = await api.patch("/payments/unsubscribe")
    return data;
}