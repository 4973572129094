import { useCallback, useEffect, useState } from 'react';
import lamejs from 'lamejs'
import { UPLOAD_FILES } from '../services/files';
import { useApp } from '../contexts/app.context';

const useRecorder = ({ company = null }) => {
    const [recording, setRecording] = useState(false);
    const [audio, setAudio] = useState<any>('');
    const [audioBlob, setAudioBlob] = useState<any>(null);
    const [timeCounter, setTimeCounter] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [fileId, setFileId] = useState(null);
    
    const { socket } = useApp();

    useEffect(() => {
        let interval: any = null;
        if (recording) {
            setTimeCounter(0);
            interval = setInterval(() => { setTimeCounter(prevCounter => prevCounter + 300); }, 300);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording]);

    const startRecording = () => {        
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks : any[] = [];

            mediaRecorder.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });

            mediaRecorder.addEventListener("start", event => {
                setRecording(true);
            });

            mediaRecorder.addEventListener("stop", async () => {
                setRecording(false);
                const audioBlob = new Blob(audioChunks, {type: "audio/mpeg"});
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioBlob(audioBlob);
                setAudio(audioUrl);
                stream.getTracks().forEach(track => track.stop());
            });

            setMediaRecorder(mediaRecorder);
            mediaRecorder.start();
        });
    };

    const saveAudio = () => {
        console.log({company, socket})
        return new Promise<{url: string}>((resolve, reject) => {
            // resolve({url: "https://www.buildquickbots.com/whatsapp/media/sample/audio/sample01.mp3"})
            UPLOAD_FILES([audioBlob], company)
            .then((files) => {
                const func = (params) => {
                    const { progress, url } = params;
                    if(url){
                        resolve({ url });
                        socket.off(evName, func);
                    };
                }
                const evName = `file_upload_${files[0]?._id}`;
                socket.on(evName, func);
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    const stopRecording = () => {
        mediaRecorder && mediaRecorder.stop();
    }

    const clear = () => {
        stopRecording();
        setRecording(false);
        setAudio(null);
        setAudioBlob(null);
    }
    
    return { startRecording, audioBlob, stopRecording, timeCounter, recording, audio, saveAudio, clear }
};

export { useRecorder };
