import { Accordion, Box, Button, Grid, Group, Loader, Menu, Paper, PasswordInput, Text, TextInput, Title, UnstyledButton } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import InputField, { InputFieldTypes } from "../components/input_field";
import { GET_COMPANY_DOMAINS, SAVE_COMPANY_DOMAIN, UPDATE_SELF_COMPANY } from "../services/companies";
import { GET_USER_DATA } from "../services/users";
import { useApp } from "../contexts/app.context";
import { showNotification } from "@mantine/notifications";
import { FaImage } from "react-icons/fa";

export default function Whitelabel(){
    // const [settings, setSettings] = useState<any>(null);
    const [loading, setLoading] = useState<any>(null);
    const [domains, setDomains] = useState<any[]>([]);
    const [company, setCompany] = React.useState(null);
    const [openImage, setOpenImage] = React.useState(false);
    const [openIcon, setOpenIcon] = React.useState(false);
    const [tempColor, setTempColor] = React.useState("");
    const [tempNavbar, setTempNavbar] = React.useState("");

    const defaultDomain = domains.find(d => d.is_default);
    const notDefaultDomain = domains.find(d => !d.is_default);

    const { configs, setConfigs } = useApp();
    const clipboard = useClipboard();
    const alreadyLoaded = useRef(false);

    const loadData = () => {
        setLoading(true);
        GET_USER_DATA()
        .then(({company}) => {
            setCompany(company);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            showNotification({ message: err.message, color: "red" })
        })
    }
    
    const loadDomains = () => {
        GET_COMPANY_DOMAINS()
        .then((res) => {
            setDomains(res);
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
        })
    }

    const handleSaveDomain = (domain) => {
        SAVE_COMPANY_DOMAIN(domain)
        .then((res) => {
            loadDomains();
            showNotification({ title: "Domínio criado", message: "Crie os registros no seu gerenciador de DNS", color: "green" })
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
        })
    }

    // const handleSaveWhitelabel = () => {
    //     UPDATE_SELF_COMPANY({appearance: settings})
    //     .then(() => {
    //         showNotification({ message: "Atualizado", color: "green" })
    //     })
    //     .catch(err => {
    //         showNotification({title: "Ops.", message: err.message, color: 'red'})
    //     })
    // }

    const handleSaveCompany = (newData) => {
        UPDATE_SELF_COMPANY(newData)
        .then(() => {
            showNotification({ message: "Atualizado", color: "green" })
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    useEffect(() => {
        loadData()
        loadDomains()
    }, [])
    
    useEffect(() => {
        if(alreadyLoaded.current) handleSaveCompany({
            icon: company?.icon,
            image: company?.image,
            appearance: company?.appearance,
        });
    }, [company?.icon, company?.image, company?.appearance,])
    
    useEffect(() => {
        setTempColor(configs?.primary);
        setTempNavbar(configs?.brand);
    }, [configs?.primary])
    
    useEffect(() => {
        if(company) alreadyLoaded.current = true;
    }, [company])

    return <Paper style={{position: 'relative'}} p="md" shadow="xs">
        {loading && <Loader />}
        {/* <Accordion defaultValue="appearance">
            <Accordion.Item mb="md" value="appearance" style={{background: 'white'}}>
                <Accordion.Control><Title order={4}>Aparência</Title></Accordion.Control>
                <Accordion.Panel> */}
                    <Grid>
                        <Grid.Col md={6}>
                            <Paper shadow="xs" p="md">
                                <Box>
                                {
                                    company?.image
                                    ? <img src={company?.image} height={80} width={200} color="gray"  style={{ objectFit: 'contain'}} />
                                    : <FaImage size={60} color="#7a7a7a" />
                                }
                                </Box>
                                <Menu opened={openImage} onOpen={() => setOpenImage(true)} onClose={() => setOpenImage(false)}>
                                    <Menu.Target>
                                        <UnstyledButton>
                                            <Button>Inserir Logo</Button>
                                        </UnstyledButton>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <InputField
                                            name="image"
                                            title='Logo'
                                            value={company?.image}
                                            onChange={({ image }) => {
                                                setOpenIcon(false);
                                                setCompany(c => ({ ...c, image }))
                                                if(image) setConfigs({ image })
                                            }}
                                            fieldType={InputFieldTypes.IMAGE}
                                        />
                                    </Menu.Dropdown>
                                </Menu>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Paper shadow="xs" p="md">
                                <Box>
                                {
                                    company?.icon
                                    ? <img src={company?.icon} height={80} width={200} color="gray" style={{ objectFit: 'contain'}} />
                                    : <FaImage size={60} color="#7a7a7a" />
                                }
                                </Box>
                                <Menu opened={openIcon} onOpen={() => setOpenIcon(true)} onClose={() => setOpenIcon(false)}>
                                    <Menu.Target>
                                        <UnstyledButton>
                                            <Button>Inserir Ícone</Button>
                                        </UnstyledButton>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <InputField
                                            name="icon"
                                            title='Ícone'
                                            value={company?.icon}
                                            onChange={({ icon }) => {
                                                setOpenIcon(false);
                                                setCompany(c => ({ ...c, icon }))
                                                if(icon) setConfigs({ icon })
                                            }}
                                            fieldType={InputFieldTypes.IMAGE}
                                        />
                                    </Menu.Dropdown>
                                </Menu>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Paper p="md" mb="md" shadow="xs">
                                <Title order={4}>Altere sua cor primária</Title>
                                <Text c="gray" size="md">Escolha sua cor primaria que vai ser utilizada na plataforma.</Text>
                                <InputField
                                    mt="md"
                                    name="primary"
                                    fieldType={InputFieldTypes.COLOR}
                                    value={tempColor}
                                    onBlur={() => {
                                        setConfigs({ primary: tempColor });
                                        setCompany(c => ({ ...c, appearance: { ...c.appearance, primary: tempColor } }))
                                    }}
                                    onChange={({primary}) => {
                                        setTempColor(primary)
                                    }}
                                />
                            </Paper>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Paper p="md" mb="md" shadow="xs">
                                <Title order={4}>Cor do Cabeçalho</Title>
                                <Text c="gray" size="md">Escolha a cor a ser utilizada no cabeçalho da plataforma.</Text>
                                <Group mt="md">
                                    <Box style={{
                                        height: 30,
                                        width: 30,
                                        borderRadius: 2,
                                        border: '1px solid #EFEFEF',
                                        background: configs.brand
                                    }}></Box>
                                    <InputField
                                        name="navbar"
                                        style={{flex: 1}}
                                        fieldType={InputFieldTypes.COLOR}
                                        clearable={false}
                                        // searchable={false}
                                        // options={[
                                        //     { label: "Branco", value: "white" },
                                        //     { label: "Preto", value: "black" },
                                        //     { label: "Azul", value: "blue" },
                                        // ]}
                                        value={tempNavbar}
                                        onBlur={() => {
                                            setConfigs({ navbar: tempNavbar });
                                            setCompany(c => ({ ...c, appearance: { ...c.appearance, navbar: tempNavbar } }))
                                        }}
                                        onChange={({navbar}) => {
                                            setTempNavbar(navbar)
                                        }}
                                    />
                                </Group>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col md={12}>
                            <Title order={4}>Seu link customizado</Title>
                            <Title mb="md" order={5} c="gray">Seus clientes vão receber os jobs por esse link</Title>
                            <Paper style={{background: '#EFEFEF'}} p="md" mb="md">
                                <Group>
                                    <Title style={{flex: 1}} order={5} c="gray">{defaultDomain?.url}</Title>
                                    <Button onClick={() => {
                                        clipboard.copy(`https://${defaultDomain?.url}`);
                                        showNotification({ message: "Link copiado", color: "yellow" })
                                    }}>Copiar</Button>
                                </Group>
                            </Paper>

                            <Title mb="md" order={5} c="gray">Conecte seu domínio e tenha uma experiência customizável</Title>
                            <Paper style={{background: '#DFDFDF'}} p="md" mb="md">

                                <Group>
                                    <InputField
                                        name="url"
                                        variant="filled"
                                        color="gray"
                                        placeholder="aprova.seudominio.com.br"
                                        style={{flex: 1}}
                                        value={notDefaultDomain?.url}
                                        onChange={({url}) => {
                                            setDomains(d => notDefaultDomain
                                                ? d.map(item => (item._id === notDefaultDomain?._id ? {...item, url} : item))
                                                : [...d, { url }]
                                            )
                                        }}
                                    />
                                    <Group position="right">
                                        <Button disabled={(notDefaultDomain?.url ?? "").split(".").length < 2} size="sm" onClick={() => handleSaveDomain(notDefaultDomain)} style={{background: 'black'}}>Conectar</Button>
                                    </Group>
                                </Group>
                            </Paper>
                            {notDefaultDomain && <>
                                Apontamento DNS (domínio)
                                <Paper p="md" mt="md" style={{background: "#DFDFDF"}}>
                                    <Grid>
                                        <Grid.Col style={{textAlign: 'center'}} xs={2}>Tipo</Grid.Col>
                                        <Grid.Col style={{textAlign: 'center'}} xs={5}>Origem</Grid.Col>
                                        <Grid.Col style={{textAlign: 'center'}} xs={5}>Destino</Grid.Col>
                                        <Grid.Col style={{textAlign: 'center'}} xs={2}><Paper shadow="xs" p="sm">CNAME</Paper></Grid.Col>
                                        <Grid.Col style={{textAlign: 'center'}} xs={5}><Paper shadow="xs" p="sm">{notDefaultDomain?.url}</Paper></Grid.Col>
                                        <Grid.Col style={{textAlign: 'center'}} xs={5}><Paper shadow="xs" p="sm">app.aprova.ai</Paper></Grid.Col>
                                    </Grid>
                                </Paper>
                            </>}
                        </Grid.Col>
                    </Grid>
                    {/* <Paper style={{padding: 0, margin: '0 0 20px 0'}}>
                        <LoginPreview buttonColor={configs.primary} color={configs.brand} contrast={configs.contrast} />
                    </Paper> */}
                    {/* <Group position="right">
                        <Button size="md" onClick={handleSaveWhitelabel}>Salvar</Button>
                    </Group> */}
                {/* </Accordion.Panel>
            </Accordion.Item> */}
            {/* <Accordion.Item mb="md" value="texts" style={{background: 'white'}}>
                <Accordion.Control><Title order={4}>Headlines & Descriptions</Title></Accordion.Control>
                <Accordion.Panel>
                    <Grid>
                        <Grid.Col md={12}>
                            <Paper p="md" mb="md" shadow="xs">
                                <Title order={4}>Login/Sign-up Page Copy</Title>
                                <Text c="gray" size="md">Edit your texts on login page</Text>
                                <InputField
                                    mt="md"
                                    name="login_footer_one"
                                    title="Client Name"
                                    value={settings?.texts?.login_footer_one ?? "Ready to Experience Excellence?"}
                                    onChange={({login_footer_one}) => setSettings(s => ({...s, texts: {
                                        ...s?.texts,
                                        login_footer_one
                                    }}))}
                                />
                                <InputField
                                    mt="md"
                                    name="login_footer_two"
                                    title="Client Testimonial"
                                    fieldType={InputFieldTypes.TEXTAREA}
                                    value={settings?.texts?.login_footer_two ?? "Explore our array of top-tier services tailored to meet your needs. Sign up today and unlock the gateway to unparalleled solutions delivered by our dedicated team."}
                                    onChange={({login_footer_two}) => setSettings(s => ({...s, texts: {
                                        ...s?.texts,
                                        login_footer_two
                                    }}))}
                                />
                            </Paper>
                        </Grid.Col>
                    </Grid>
                    <Group position="right">
                        <Button size="md" onClick={handleSaveWhitelabel} style={{background: 'black'}}>Save Description</Button>
                    </Group>
                </Accordion.Panel>
            </Accordion.Item> */}
        {/* </Accordion> */}
    </Paper>
}


const LoginPreview = ({ buttonColor, color, contrast }) => <div style={{height: '340px', display: 'flex', flexDirection: 'row', overflow: 'auto'}}>
    <div style={{ flex: 0.3, display: 'flex', flexDirection: 'column', background: color }}>
        <div style={{flex: 1, padding: '20px 20px'}}>
            <Box>
                <Text color={contrast} size="md">Welcome Back</Text>
            </Box>
            <TextInput 
                mt="xs"
                label="E-mail"
                name="email"
                size="xs"
                c={contrast}
            />
            <PasswordInput
                mt="xs"
                label="Password"
                name="password"
                size="xs"
                c={contrast}
            />
            <UnstyledButton style={{width: '100%'}}>
                <Text style={{textDecoration: 'undeline'}} size="xs" c="gray" ta="right">Forgot password?</Text>
            </UnstyledButton>
            <Button style={{background: buttonColor}} className='draw-button' type="submit" mt="xl" size="xs" fullWidth>
                Sign In
            </Button>
        </div>
    </div>
    <div style={{flex: 0.7, background: `url(/assets/login.png)`, height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
</div>