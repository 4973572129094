import React, { useEffect, useState } from 'react'
import InputField, { InputFieldMasks, InputFieldTypes } from './input_field'
import { ActionIcon, Box, Button, Group, Modal, Paper, Text } from '@mantine/core'
import { ufsList } from '../utility/util';
import axios from 'axios';
import { SAVE_PAYMENT_METHOD } from '../services/payments';
import { useAuth } from '../contexts/auth.context';
import { showNotification } from '@mantine/notifications';
import { FaPlus } from 'react-icons/fa';

export const PaymentForm = ({
    data,
    showMethods = true,
    onChange,
    methods = [],
    onSave = null,
    customerData = null,    
}) => {
    const [loading, setLoading] = useState(false);
    const [insertedMethods, setInsertedMethods] = useState([]);
    const [newCard, setNewCard] = useState(null);

    const { userData } = useAuth();

    const loadAddress = () => {
        const zipCode = (newCard?.zip_code ?? "").replace(/[^0-9]/g, "");
        if (zipCode.length === 8) {
            axios
            .get(`https://viacep.com.br/ws/${zipCode}/json/`)
            .then((res) => {
                handleChangeCard({
                    city: res.data.localidade,
                    uf: res.data.uf,
                    neighborhood: res.data.bairro,
                    address: res.data.logradouro,
                });
            })
            .catch(err => {
                
            });
        }
    };

    const handleChangeCard = (dt) => {
        setNewCard(nc => ({
            ...nc,
            ...dt,
        }))
    }

    const saveNewCard = async () => {
        setLoading(true);
        try{
            const method = await SAVE_PAYMENT_METHOD(
                "aprova-ai",
                newCard,
                {
                    email: customerData?.email ?? userData?.company?.email,
                    name: customerData?.name ?? userData?.company?.name,
                    document: customerData?.document ?? userData?.company?.register_number,
                    phone: customerData?.phone ?? userData?.company?.phone,
                }
            )
            setInsertedMethods(m => [...m, method]);
            onChange({ method: method._id });
            onSave && onSave(method._id, true);
            setNewCard(null);
        }catch(err){
            showNotification({ message: err.message, color: "red" })
        }finally{
            setLoading(false);
        }
    }

    // useEffect(() => {
    //     if(methods.length === 0) onChange({ method: "new-card" })
    // }, [methods.length])
    
    useEffect(() => {
        loadAddress();
    }, [newCard?.zip_code])

    const CardForm = <>
        <InputField
            size="md"
            mb="md"
            title={"Número do Cartão"}
            name="number"
            value={newCard?.number}
            onChange={handleChangeCard}
        />
        <InputField
            size="md"
            mb="md"
            title="Nome Impresso no Cartão"
            name="holder"
            value={newCard?.holder}
            onChange={handleChangeCard}
        />
        <InputField
            size="md"
            mb="md"
            title="CPF/CNPJ do Proprietário"
            name="document"
            value={newCard?.document}
            onChange={handleChangeCard}
        />
        <InputField
            size="md"
            title={"Data de Vencimento"}
            name="vl"
            mb="md"
            mask={InputFieldMasks.CUSTOM}
            customMask={"99/99"}
            placeholder='MM/YY'
            value={[
                newCard?.exp_month,
                newCard?.exp_year,
            ].filter(nn => nn).join("/")}
            onChange={({vl}) => {
                let splited = vl.split("/");
                handleChangeCard({exp_month: splited[0] ?? "", exp_year: splited[1] ?? ""})
            }}
        />
        <InputField
            size="md"
            mb="md"
            title={"Código de Segurança"}
            name="cvc"
            value={newCard?.cvc}
            onChange={handleChangeCard}
        />
        <Text size="md" fw="bold" mb="md">Endereço de Cobrança</Text>
        <InputField
            size="md"
            mb="md"
            title="CEP"
            name="zip_code"
            error={(newCard?.zip_code ?? "").replace(/[^0-9]/g, "").length > 8 ? "CEP inválido" : null}
            value={newCard?.zip_code}
            onChange={handleChangeCard}
        />
        <Group mb="md" grow>
            <InputField
                size="md"
                title="Estado"
                name="uf"
                value={newCard?.uf}
                fieldType={InputFieldTypes.SELECT}
                options={ufsList}
                onChange={handleChangeCard}
            />
            <InputField
                size="md"
                title="Cidade"
                name="city"
                value={newCard?.city}
                onChange={handleChangeCard}
            />
        </Group>
        <InputField
            size="md"
            mb="md"
            title="Bairro"
            name="neighborhood"
            value={newCard?.neighborhood}
            onChange={handleChangeCard}
        />
        <InputField
            size="md"
            mb="md"
            title="Logradouro"
            name="address"
            value={newCard?.address}
            onChange={handleChangeCard}
        />
        <InputField
            size="md"
            mb="md"
            title="Complemento"
            name="complement"
            value={newCard?.complement}
            onChange={handleChangeCard}
        />
    </>

    return <>
        {showMethods
        ? <Group mb="md" mt="md">
            <InputField
                size="md"
                name="method"
                value={data?.method}
                searchable={false}
                clearable={false}
                style={{flex: 1}}
                fieldType={InputFieldTypes.SELECT}
                onChange={({method}) => {
                    onChange(({ method: method }));
                    // if(method === "new-card") onSave && onSave(method, true);
                }}
                options={[
                    ...[
                        ...methods,
                        ...insertedMethods,
                    ].map(m => ({label: `${m?.brand?.toUpperCase() ?? ""} **** ${m?.last4 ?? ""}` || "Card", value: m._id})),
                ]}
            />
            <Button leftIcon={<FaPlus />} onClick={() => setNewCard({})}>Cartão</Button>
        </Group>
        : <Box mt="md" mb="md">
            {CardForm}
            <Button mt="md" onClick={saveNewCard} loading={loading}>Salvar</Button>
        </Box>}
        <Modal
            opened={!!newCard && showMethods}
            onClose={() => setNewCard(null)}
        >
            {newCard && CardForm}
            <Group position="right" mt="md">
                <Button disabled={!newCard?.zip_code} onClick={saveNewCard} loading={loading}>Salvar</Button>
            </Group>
        </Modal>
    </>
}