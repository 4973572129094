import { ActionIcon, Avatar, Box, Button, Grid, Group, Loader, Paper, Text, UnstyledButton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { FaPlus, FaSignOutAlt } from "react-icons/fa";
import { DELETE_OAUTH, GET_ALL_OAUTHS, SAVE_OAUTH } from "../../services/oauths";
import Auth, { OAUTH } from '../oauth/oauth';


export default function Canva(){
    const [data, setData] = useState<any[]>([]);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { connect } = Auth();

    const loadData = () => {
        GET_ALL_OAUTHS()
        .then((res) => {
            setData((res ?? []).filter(r => r.origin === "canva"))
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const disconnectOauth = (oauth) => {
        DELETE_OAUTH(oauth._id)
        .then(() => {
            loadData();
        })
        .catch(err => {
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const handleSaveOauth = (code) => {
        setLoadingSave(true)
        
        SAVE_OAUTH({ origin: "canva", data: { code } })
        .then((res) => {
            setLoadingSave(false);
            showNotification({message: "Conta do Canva Conectada", color: 'green'});
            loadData();
        })
        .catch(err => {
            setLoadingSave(false)
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => { loadData() }, [])
    
    return <div style={{position: 'relative'}}>
        {
            (data ?? []).length === 0 &&
            <UnstyledButton disabled={loadingSave} style={{width: '100%'}} onClick={() => { connect(OAUTH.CANVA, loadData) }}>
                <Paper p="xl" shadow="xs" style={{ border: '1px solid #DFDFDF', borderRadius: 5, backgroundColor: '#2587dd' }}>
                    <Group>
                    {loadingSave ? <Loader size={30} /> : <Avatar size={30} src="/assets/canva.png" />}
                        <Box style={{flex: 1}}>
                            <Text size="xl" c="white" fw="bold">Conectar Canva.com</Text>
                        </Box>
                    </Group>
                </Paper>
            </UnstyledButton>
        }
            {(data ?? []).map(connection => (
                <Paper p="xl" shadow="xs" style={{backgroundColor: '#2587dd'}}>
                    <Group style={{whiteSpace: 'nowrap', flexWrap: 'nowrap', overflowX: 'hidden'}}>
                    {loadingSave ? <Loader size={30} /> : <Avatar size={30} src="/assets/canva.png" />}
                        <Box style={{flex: 1}}>
                            <Text c="white" size="xl" fw="bold">{connection?.data?.me?.profile?.display_name}</Text>
                        </Box>
                        <ActionIcon
                            onClick={() => disconnectOauth(connection)}
                            color="gray"
                            variant="light"
                        >
                            <FaSignOutAlt />
                        </ActionIcon>
                    </Group>
                </Paper>
            ))}
            {/* <Grid.Col md={12}>
                <Button color="green" loading={loadingSave} leftIcon={<FaPlus />} onClick={() => {
                    connect(OAUTH.CANVA, loadData);
                }}>Conectar Conta</Button>
            </Grid.Col> */}

    </div>
}
