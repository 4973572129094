import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import passport, { Strategy } from 'passport-instagram';

const DriveAuth = () => {
    const [externalPopup, setExternalPopup] = useState<any>(null);
    const [funcs, setFuncs] = useState<any>(null);
    const logged = useRef<boolean>(true);

    const navigate = useNavigate();

    const handleLogin = ({ onCode, onError }) => {
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;

        const authURL = `${process.env.REACT_APP_SERVER_URL}/google_auth`;
        // const authURL = `https://api.awaz.ai/google_auth`;

        const authWindow : any = window.open(
            authURL,
            'Login Google Drive',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        setExternalPopup(authWindow);
        setFuncs({
            onCode,
            onError,
        });
        logged.current = false;
    };

    useEffect(() => {
        if (!externalPopup) { return; }
    
        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            try{
                const currentUrl = externalPopup.location.href;
                console.log(currentUrl)
                if (!currentUrl || logged.current) {
                    return;
                }
                const searchParams = new URL(currentUrl).searchParams;
                const code = searchParams.get('code');
                const error = searchParams.get('error');
                if (code) {
                    console.log(`The popup URL has URL code param = ${code}`);
                    logged.current = true;
                    externalPopup.close();
                    funcs.onCode && funcs.onCode(code);
                }else if(error){
                    logged.current = true;
                    externalPopup.close();
                    funcs.onError && funcs.onError(error);
                }
            }catch(err){ }
        }, 500);
        
        return () => { clearInterval(timer) };
    }, [externalPopup, funcs])

    return { start: handleLogin };
};

export default DriveAuth;
