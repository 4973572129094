import { NotificationsProvider } from "@mantine/notifications";
import 'dayjs/locale/pt-br';
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppProvider } from "./contexts/app.context";
import { AuthProvider } from "./contexts/auth.context";
import { RequireAuth } from "./utility/requireAuth";
import routes from "./utility/routes";
import './App.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

function App() {
  return <>
    <NotificationsProvider>
    <BrowserRouter>
      <AuthProvider>
      <AppProvider>
        <Routes>
          {routes.filter(r => !r.private).map((r, i) => <Route key={`${i}`} path={r.url} element={r.component} />)}
          <Route element={<RequireAuth />}>
            {routes.filter(r => r.private).map((r, i) => <Route key={`${i}`} path={r.url} element={r.component} />)}
          </Route>
        </Routes>
      </AppProvider>
      </AuthProvider>
    </BrowserRouter>
    </NotificationsProvider>
  </>
}

export default App;
