import { Avatar, Box, Card, Group, Indicator, Text, Title, Tooltip } from "@mantine/core";
import React from "react";

export default function CustomerAvatar({
  size = "lg",
  style = undefined,
  status = "active",
  image = null,
  name,
  showTitle = true,
  color = "black",
  subtitle = "",
  ...others
}) {
  return <Tooltip label={name}>
    <Group style={style}>
      <Card style={{padding: 0}} shadow="xs">
        <Avatar
          style={{
            borderRadius: "5px",
            backgroundColor: `rgba(248, 249, 250, 1)`,
          }}
          size={size}
          src={image}
          {...others}
        />
      </Card>
      {showTitle && <Box style={{flex: 1}}>
        <Text size="sm" color={color} weight="bold">{name}</Text>
        {subtitle && <Text color={color} mt="sm">{subtitle}</Text>}
      </Box>}
    </Group>
  </Tooltip>
}
