import {
  Box,
  Button,
  Center, Divider, Group, Modal, Paper, PasswordInput,
  Space,
  Text,
  TextInput,
  Title,
  UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { Link, Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { AUTH_LOGIN, FORGOT_PASSWORD, RECEIVE_OTP } from "../services/auth";
import { showNotification } from "@mantine/notifications";
import { useApp } from "../contexts/app.context";
import OTPInput from "react-otp-input";
import { formatMilliseconds, phoneCountryCodes } from "../utility/util";
import moment from "moment";
import { api } from "../services/api";

export default function Login(){
  const { login, signIn, companyHost, user } = useAuth();
  const { configs } = useApp();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const [loginType, setLoginType] = useState("");
  const [signingUp, setSigningUp] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [forgot, setForgot] = useState(null);
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState("55");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpRegenerateTimeout, setOtpRegenerateTimeout] = useState(0);
  const [otpSent, setOtpSent] = useState(null);

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/";

  const confirmForgot = e => {
    e.preventDefault();
    const email = forgot.email;
    FORGOT_PASSWORD(email)
    .then(() => {
      setForgot(null);
      showNotification({title: "Recuperação de senha", message: `Foi enviado um e-mail para ${email} com instruções para recuperar sua senha`})

    })
    .catch(err => {
      showNotification({title: "Ops", message: err.message, color: 'red'})
    }) 
  }

  const generateOtp = () => {
    setOtpSent(moment().add(2, "minutes").format());
    RECEIVE_OTP({
      country_code: countryCode,
      phone,
    })
    .then(res => {
      // showNotification({ message: "Insira o código enviado para seu Whatsapp", color: "green" });
    })
    .catch(err => {
      showNotification({ message: err.message, color: "red" });
      setOtpSent(null);
    })
  }

  useEffect(() => {
    if(otpSent){
      const otpInterval = setInterval(() => {
        const diff = moment(otpSent).diff(moment(), "seconds");
        setOtpRegenerateTimeout(diff > 0 ? diff : 0);
      }, 200);
      return () => {
        clearInterval(otpInterval)
      }
    }
  }, [otpSent]);
  
  useEffect(() => {
    setLoginType(companyHost ? "phone" : "email");
  }, [companyHost]);

  return !!user
  ? <Navigate to="/" state={{ from }} />
  : (
    <Center style={{ width: "100vw", minHeight: "100vh", background: `linear-gradient(to top right, ${configs.navbar ?? "#FFFFFF"}, ${configs.navbar ?? "#FFFFFF"}, ${configs.navbar ?? "#FFFFFF"})` }}>
      <div
        style={{
          width: isMobile ? '90vw' : "40vw",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 80,
        }}
      >
        <div style={{display: 'flex', marginBottom: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <img alt="AprovaAi" style={{height: 80, width: "60%", objectFit: 'contain'}} src={configs.image ?? "/logo.png"}/>
        </div>
        <Paper shadow="xs" p="xl" >
          <Box>
            <Title align="center" order={2}>Entrar</Title>
            {loginType === "email" && <Group mt="sm" style={{justifyContent: 'center'}}>
              <Title order={5} color="gray">Ainda não tem uma conta?</Title>
              <Link to={companyHost ? `/cadastrar/cliente${from ? `?from=${from}` : ""}` : `/cadastrar${from ? `?from=${from}` : ""}`}><Text color="lime">Criar conta</Text></Link>
            </Group>}
          </Box>

          {
            loginType === "phone"
            ? otpSent
              ? <Box mt="md">
                <form onSubmit={e => {
                  e.preventDefault();
                  if(signingUp){
                    api.post("/auth/signup", { general: { name, email, phone, phone_country_code: countryCode }, others: { otp } })
                    .then(({data: { user }}) => {
                      signIn(user);
                    })
                    .catch(err => {
                      showNotification({ message: err.message })
                    })
                  }else{
                    AUTH_LOGIN({ phone, country_code: countryCode, otp })
                    .then(({user}) => {
                      signIn(user);
                    })
                    .catch(err => {
                      setSigningUp(true)
                    })
                  }
                }}>
                  {signingUp
                  ? <>
                    <Text ta="center" mb="xl" size="sm">Complete seus dados para continuar</Text>
                    
                    <Group style={{justifyContent: 'unset', alignItems: 'unset', flexDirection: 'column'}}>
                      <InputField
                        style={isMobile ? {} : {flex: 0.3}}
                        title={"Seu Nome"}
                        mb="lg"
                        name="vl"
                        value={name}
                        onChange={({vl}) => setName(vl)}
                      />
                      <InputField
                        style={isMobile ? {} : {flex: 0.3}}
                        title={"Seu E-mail"}
                        mb="lg"
                        name="vl"
                        value={email}
                        onChange={({vl}) => setEmail(vl)}
                      />
                    </Group>

                    <Button mt="md" type="submit" size="md" fullWidth disabled={name.length < 3 && !email.includes("@")}>
                      Entrar
                    </Button>

                    <UnstyledButton mt="md" onClick={() => {
                      setOtpSent(false);
                      setSigningUp(false);
                    }} style={{width: '100%'}}>
                      <Text underline size="sm" color="lime" align="right">Voltar</Text>
                    </UnstyledButton>
                  </>
                  : <>
                    <Text ta="center" mb="xl" size="sm">Insira o código enviado para seu Whatsapp <b>+{countryCode}{(phone ?? "").replace(/ |\-|\(|\)|\_/g, "")}</b></Text>
                    <Group style={{justifyContent: 'center'}}>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputType="number"
                        inputStyle={{ fontSize: 40, width: '1.4em' }}
                        renderSeparator={<span>&nbsp;</span>}
                        renderInput={(props) => <input inputMode="numeric" type="number" {...props} />}
                      />
                    </Group>

                    <Button mt="md" type="submit" size="md" fullWidth disabled={otp.length < 4}>
                      Entrar
                    </Button>
      
                    <Text mt="xl" ta="center" size="sm">Ainda não recebeu o código? {
                      otpRegenerateTimeout > 0
                      ? <b>Envie novamente em {formatMilliseconds(otpRegenerateTimeout * 1000)}</b>
                      : <a href="javascript:void(0)" onClick={generateOtp}>Re-enviar Código</a>
                    }
                    </Text>
                    <UnstyledButton mt="md" onClick={() => setOtpSent(false)} style={{width: '100%'}}>
                      <Text underline size="sm" color="lime" align="right">Voltar</Text>
                    </UnstyledButton>
                  </>}
                </form>
              </Box>
              : <Box mt="md">
                <form onSubmit={e => {
                  e.preventDefault();
                  generateOtp();
                }}>
                  <Group style={isMobile ? {display: 'block'} : {}}>
                    <InputField
                      style={isMobile ? {} : {flex: 0.3}}
                      title={"País"}
                      mb="lg"
                      name="vl"
                      autoComplete="tel-country-code"
                      fieldType={InputFieldTypes.SELECT}
                      value={countryCode}
                      onChange={({vl}) => setCountryCode(vl)}
                      nothingFound="No country code found"
                      options={phoneCountryCodes}
                    />
                    <InputField
                      title="Whatsapp"
                      mb="lg"
                      name="vl"
                      inputMode="numeric"
                      style={{flex: 1}}
                      mask={((countryCode) === "55") ? InputFieldMasks.CUSTOM : undefined}
                      customMask={phone?.replace(/ |\(|\)|\-/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"}
                      value={phone}
                      onChange={({vl}) => setPhone(vl)}
                    />
                  </Group>

                  <Button type="submit" size="md" fullWidth disabled={(phone ?? "").replace(/[^0-9]/g, "").length < 8}>
                    Entrar
                  </Button>
                </form>
            </Box>
            : <form
              onSubmit={(e) => {
                e.preventDefault();
                login({ email, password }, () => {
                  navigate(from, { replace: true });
                });
              }}
            >
              <TextInput
                mt="md"
                label="E-mail"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                />
              <PasswordInput
                mt="md"
                label="Senha"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <UnstyledButton mt="md" onClick={() => setForgot({email: ""})} style={{width: '100%'}}>
                <Text underline size="sm" color="lime" align="right">Esqueci minha senha</Text>
              </UnstyledButton>
              <Button type="submit" mt="xl" size="md" fullWidth>
                Entrar
              </Button>
            </form>}
            <Group mt="md" align="center" position="center">
              <Text size="xs" ta="center" style={{cursor: 'pointer'}} color="gray" onClick={() => window.open("https://aprova.ai/privacy-policy/", "_blank")}>Políticas de Privacidade</Text>
              <Divider orientation="vertical" />
              <Text size="xs" ta="center" style={{cursor: 'pointer'}} color="gray" onClick={() => window.open("https://aprova.ai/terms-of-use/", "_blank")}>Termos de Uso</Text>
            </Group>
        </Paper>
      </div>

      <Modal size="sm" opened={!!forgot} onClose={() => setForgot(null)}>
        <form onSubmit={confirmForgot}>
          <InputField name="email" placeholder="Digite seu e-mail" title="E-mail" value={forgot?.email} onChange={({email}) => setForgot(f => ({...f, email}))} />
          
          <Group position="right" mt="lg">
            <Button color="lime" variant="light" type="button" mr="xs" onClick={() => setForgot(null)}>Cancelar</Button>
            <Button color="lime" variant="filled" type="submit">Recuperar Senha</Button>
          </Group>
        </form>
      </Modal>
    </Center>
  );
};
