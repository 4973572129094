import React, { useEffect, useRef, useState } from 'react'
import { ActionIcon, Button, Group, Image, Loader, Text } from '@mantine/core'
import CanvasDraw from "react-canvas-draw";
import { FaUndo } from 'react-icons/fa';

export default function RenderImage(props){
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [renderSize, setRenderSize] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(false);

    const parentRef = useRef<any>();
    const drawRef = useRef<any>();

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        const sz = { height: naturalHeight, width: naturalWidth };
        setImageSize(sz);
        setLoading(false);
        props.onImageSize && props.onImageSize(sz)
    }

    useEffect(() => {
        const containerWidth = parentRef.current.offsetWidth;
        let height = props.height || 420;
        let width = props.width || containerWidth;
        width = (imageSize.width && imageSize.width < width) ? imageSize.width : width;
        setRenderSize({ width, height })
    }, [props, imageSize, parentRef.current])

    // useEffect(() => {
    //     if(!loaded.current) setLoading(true);
    // }, [props.src]);

    return <div ref={parentRef} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
        {loading && <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#EFEFEF', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 420, minWidth: '100%'}}>
            <Loader />
        </div>}
        {(props.draw && renderSize.width > 0 && renderSize.height > 0)
        ? <>
            <CanvasDraw
                ref={drawRef}
                canvasHeight={renderSize.height}
                canvasWidth={renderSize.width}
                // gridColor={}
                brushRadius={2}
                lazyRadius={0}
                hideGrid={true}
                brushColor="rgba(155,12,60,1)"
                imgSrc={props.src}
            />
            <Group mt="xl">
                <ActionIcon variant='light' size="xl" color="lime" onClick={() => drawRef.current.undo() }><FaUndo /></ActionIcon>
                <Button color="lime" onClick={() => props.onSave && props.onSave(drawRef.current.getSaveData()) }>Salvar</Button>
            </Group>
        </> : <Image
            src={props.src}
            height={renderSize.height}
            width={renderSize.width}
            onLoadStart={() => setLoading(true)}
            fit='contain'
            onLoad={handleImageLoad}
            style={{visibility: loading ? "hidden" : "visible"}}
        />}
    </div>
}