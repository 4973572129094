import { Avatar, Indicator } from "@mantine/core";
import React from "react";

export default function AgentAvatar({
  size = "md",
  style = null,
  status = "active",
  image = null,
  ...others
}) {
  const avatarColor =
    {
      active: "green",
      busy: "yellow",
      inactive: "red",
    }[status] || "gray";
  return (
    <Indicator
      inline
      size={8}
      offset={7}
      position="bottom-end"
      color={avatarColor}
    >
      <Avatar
        style={{
          ...style,
          borderRadius: "5px",
          border: `2px solid #ffffff`,
          backgroundColor: `rgba(248, 249, 250, 1)`,
        }}
        size={size}
        src={image}
        {...others}
      />
    </Indicator>
  );
}
