import { Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React from 'react'
import { useApp } from '../../contexts/app.context';
import { GET_ALL_INVITES, DELETE_INVITE } from '../../services/companies';
import { GET_ALL_USERS, DELETE_USER } from '../../services/users';
import { FaPen, FaTrash } from 'react-icons/fa';
import Table from '../table';

const COLUMNS = [
    {title: "Nome", key: 'name'},
    {title: "E-mail", key: 'email'},
    {title: "Telefone", key: 'phone'},
    {title: "Perfil", key: 'profile', render: (item) => item.profile?.title},
    {title: "Equipes", key: 'teams', render: (item) => item.teams?.map(t => t.title).join(", ")},
]
interface ListProps{
    onEdit?: any
}
interface ListHandle{
    refresh: () => void;
}

const List : React.ForwardRefRenderFunction<ListHandle, ListProps> = ({onEdit}, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState({});
    const [error, setError] = React.useState<any>(null);
    const [data, setData] = React.useState([]);
    const [invites, setInvites] = React.useState([]);
    const app = useApp();

    const deleteUser = (id) => {
        setLoadingDelete(d => ({...d, [id]: true}));
        
        DELETE_USER(id)
        .then(() => {
            setLoadingDelete(d => ({...d, [id]: false}));
            refresh()
        })
        .catch(err => {
            setLoadingDelete(d => ({...d, [id]: false}));
            alert(err.message)
        })
    }

    const loadUsers = () => {
        setError(null);
        setLoading(true);

        GET_ALL_USERS()
        .then(users => {
            setLoading(false);
            setData(users);
        })
        .catch(err => {
            setLoading(false);
            setError(err.message);
            showNotification({message: err.message, color: 'red'});
        })
    }

    const loadInvites = () => {
        GET_ALL_INVITES()
        .then(invites => {
            setInvites(invites);
        })
        .catch(err => {
            showNotification({message: err.message, color: 'red'});
        })
    }

    const refresh = () => { 
        loadInvites();
        loadUsers();
    }

    React.useEffect(refresh, []);

    React.useImperativeHandle(ref, () => ({
        refresh: () => { refresh(); }
    }));

    return <>
        <Paper p="sm" shadow='xs'>
            <Table 
                style={{border: `1px solid white`}}
                data={data}
                columns={COLUMNS}
                error={error}
                loading={loading}
                actions={[
                    {
                        title: "Editar",
                        color: "gray",
                        leftIcon: <FaPen />,
                        onClick: ({item}) => {
                            onEdit && onEdit(item._id);
                        }
                    },
                    {
                        color: "red",
                        title: "Deletar",
                        leftIcon: <FaTrash />,
                        loading: item => Boolean(loadingDelete[item._id]), 
                        onClick: ({item}) => {
                            app.confirmDialog(
                                {text: "Realmente deseja deletar esse usuário?"},
                                (({confirmed}) => {
                                    if(confirmed) deleteUser(item._id);
                                })
                            )
                        }
                    },
                ]}
            />
        </Paper>
        <Paper p="sm" mt="md" shadow='xs'>
            <Table 
                data={invites.filter(i => i.status === 0)}
                actions={[
                    {
                        title: "Deletar",
                        disabled: item => item.status !== 0,
                        onClick: ({ item }) => {
                            app.confirmDialog({ text: "Deletar convite?" }, ({confirmed}) => {
                                if(confirmed) DELETE_INVITE(item._id)
                                .then(() => {
                                    loadInvites();
                                })
                                .catch(err => {
                                    showNotification({ message: err.message, color: "red" })
                                })
                            })
                        },
                        leftIcon: <FaTrash />
                    }
                ]}
                columns={[
                    {title: "Convite", key: 'email'},
                    {title: "Equipes", key: 'teams', render: (item) => (item.teams || []).map(t => t.title).join(", ")},
                    {title: "Status", key: 'status', render: (item) => item.status === 1 ? "Aceito" : "Enviado"},
                ]}
            />
        </Paper>
    </>
}

export default React.forwardRef(List);