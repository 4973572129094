import { Button, Divider, Group, Paper, Text, Title } from '@mantine/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth.context';
import InputField, { InputFieldMasks, InputFieldTypes } from '../components/input_field';
import { UPDATE_USER } from '../services/users';
import { showNotification } from '@mantine/notifications';
import { UPDATE_SELF_COMPANY } from '../services/companies';
import { phoneCountryCodes } from '../utility/util';

export default function OverviewPage(){
    const [updated, setUpdated] = useState<any>({});
    const [user, setUser] = useState<any>({});
    const [company, setCompany] = useState<any>({});

    const { userData, role } = useAuth();

    useEffect(() => {
        setUser(u => ({...u, ...userData?.user}))
        setCompany(u => ({...u, ...userData?.company}))
    }, [userData])

    const handleSaveUser = () => {
        UPDATE_USER("self", { ...user })
        .then(() => {
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
        })
    }
    
    const handleSaveCompany = () => {
        UPDATE_SELF_COMPANY({ ...company })
        .then(() => {
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
        })
    }

    return <>
        <Paper p="md" shadow='xs'>
            {[
                { title: "Usuário:", key: "name", origin: "user", editable: true, value: user.name },
                { title: "E-mail:", key: "email", origin: "user", editable: true, value: user.email },
                { title: "País:", key: "phone_country_code", origin: "user", editable: true, value: user.phone_country_code ?? "55",
                    fieldType: InputFieldTypes.SELECT,
                    options: phoneCountryCodes,
                },
                { title: "Celular:", key: "phone", origin: "user", editable: true, value: user.phone,
                    mask: ((user?.phone_country_code ?? "55") === "55") ? InputFieldMasks.CUSTOM : undefined,
                    customMask: user?.phone?.replace(/ |\(|\)|\-/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999",

                },
                role && role?.profile?.title === "Administrador" && { title: "Empresa:", origin: "company", value: company.name, key: "name", editable: true },
                role && role?.profile?.title === "Administrador" && { title: "País:", key: "phone_country_code", origin: "company", editable: true, value: company.phone_country_code ?? "55",
                    fieldType: InputFieldTypes.SELECT,
                    options: phoneCountryCodes,
                },
                role && role?.profile?.title === "Administrador" && { title: "Celular:", origin: "company", value: company.phone, key: "phone", editable: true,
                    mask: ((company.phone_country_code ?? "55") === "55") ? InputFieldMasks.CUSTOM : undefined,
                    customMask: company?.phone?.replace(/ |\(|\)|\-/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999",
                },
                role && role?.profile?.title === "Administrador" && { title: "CPF/CNPJ:", origin: "company", value: company.register_number, key: "register_number", editable: true },
                role && role?.profile?.title === "Administrador" && { title: "Tipo da empresa:", origin: "company", value: company.type === "1" ? "Conta pessoal" : "Conta empresarial" },
                role && role?.profile?.title === "Administrador" && { title: "Quantidade de colaboradores:", origin: "company", value: company.employees || "N/A" },
                role && role?.profile?.title === "Administrador" && { title: "Data de registro:", origin: "company", value: moment(company.createdAt).format("DD/MM/YYYY") },
            ].filter(nn => nn).map(item => <>
            <Group mb="xl">
                <Group grow style={{flex: 1}}>
                    <Title order={4}>{item.title}</Title>
                    {
                        item.editable
                        ? <InputField
                            style={{flex: 1}}
                            {...item}
                            title={undefined}
                            name={item.key}
                            value={item.value}
                            onChange={(vl) => {
                                setUpdated(u => ({...u, [`${item.origin}-${item.key}`]: true}))
                                item.origin === "company" ? setCompany(u => ({...u, ...vl})) : setUser(u => ({...u, ...vl}));
                            }}
                        />
                        : <Text color="lime">{item.value}</Text>
                    }
                </Group>
                {
                    <Button disabled={false} style={{opacity: updated[`${item.origin}-${item.key}`] ? 1 : 0}} onClick={() => {
                        setUpdated(u => ({...u, [`${item.origin}-${item.key}`]: false}));
                        item.origin === "company" ? handleSaveCompany() : handleSaveUser();
                    }} variant='outline' color="gray">Salvar</Button>
                }
                </Group>
                <Divider mb="xl" />
            </>
        )}
        </Paper>
    </>
}
