import { Button, Group, Paper } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { FaGoogleDrive, FaInstagram, FaWhatsapp } from "react-icons/fa";
import GoogleDrive from "../components/integrations/google_drive";
import Canva from "../components/integrations/canva";
import Instagram from "../components/integrations/instagram";
import { useQuery } from "../utility/util";
import Whatsapp from "./whatsapp";
import Zapier from "./zapier";

export default function Integrations(){
    const [selectedItem, setSelectedItem] = useState<string | null>("whatsapp");

    const query = useQuery();
    const platform = query.get("platform");

    useEffect(() => {
        if(platform) setSelectedItem(platform)
    }, [platform])

    return <div style={{position: 'relative'}}>
        <Paper p="md" m="md" shadow="md">
            <Group>
                <Button onClick={() => setSelectedItem("whatsapp")} leftIcon={<FaWhatsapp />} color="green" variant={selectedItem === "whatsapp" ? "filled" : "outline"}>
                    Whatsapp
                </Button>
                <Button onClick={() => setSelectedItem("zapier")} leftIcon={<img src="/assets/zapier.png" style={{width: 15, height: 15, objectFit: 'contain'}} />} color="orange" variant={selectedItem === "zapier" ? "filled" : "outline"}>
                    Zapier
                </Button>
                <Button onClick={() => setSelectedItem("drive")} leftIcon={<FaGoogleDrive />} color="yellow" variant={selectedItem === "drive" ? "filled" : "outline"}>
                    Google Drive
                </Button>
                <Button onClick={() => setSelectedItem("canva")} leftIcon={<img src="/assets/canva.png" style={{width: 15, height: 15, objectFit: 'contain'}} />} color="blue" variant={selectedItem === "canva" ? "filled" : "outline"}>
                    Canva
                </Button>
            </Group>
        </Paper>

        <Paper p="md" shadow="md" m="md">
            {selectedItem === "whatsapp" && <Whatsapp />}
            {selectedItem === "zapier" && <Zapier />}
            {selectedItem === "drive" && <GoogleDrive />}
            {selectedItem === "canva" && <Canva />}
        </Paper>
    </div>
}
