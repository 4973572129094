import { Button, Group } from "@mantine/core";
import React,{ useEffect, useRef, useState } from "react";
import InputField, { InputFieldTypes } from "./input_field";

export function CanvasDrawer(props: any) {
    const { image, actions = true, onClose, onDraw, draw: drawParent = null } = props;

    const [isDrawing, setIsDrawing] = useState(false);
    const containerRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const contextRef = useRef<any>(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [color, setColor] = useState("#FF0000");

    const prepareCanvas = () => {
        const width = containerRef.current.offsetWidth;
        const height = (width / imageSize.width) * imageSize.height;
        const canvas = canvasRef.current;
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        const context = canvas.getContext("2d");
        context.scale(1, 1);
        if(drawParent){
            const img = new Image();
            img.crossOrigin = "";
            img.onload = () => {
                context.drawImage(img, 0, 0);
            }
            img.src = drawParent;
        }
        contextRef.current = context;
    };

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    };

    const finishDrawing = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };

    const startDrawingTouch = ({ touches, target }) => {
        const { clientX, clientY } = touches[0];
        var bcr = target.getBoundingClientRect();
        var x = clientX - bcr.x;
        var y = clientY - bcr.y;
        contextRef.current.beginPath();
        contextRef.current.moveTo(x, y);
        setIsDrawing(true);
    };

    const finishDrawingTouch = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) {
            return;
        }
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };
    
    const drawTouch = ({ touches, target }) => {
        if (!isDrawing) {
            return;
        }
        const { clientX, clientY } = touches[0];
        var bcr = target.getBoundingClientRect();
        var x = clientX - bcr.x;
        var y = clientY - bcr.y;
        contextRef.current.lineTo(x, y);
        contextRef.current.stroke();
    };

    // Once the canvas is cleared it return to the default colour
    const clearCanvas = () => {
        // const canvas = canvasRef.current;
        // const context = canvas.getContext("2d");
        if(canvasRef.current){
            const ctx = canvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
        onDraw(null);
    };

    useEffect(() => {
        if(image){
            var img : any = new Image();
            img.onload = function() {
                setImageSize({ width: img.width, height: img.height })
            };
            img.src = image;
        }
    }, [image]);
    
    useEffect(() => {
        if(imageSize.height && imageSize.width) prepareCanvas();
    }, [imageSize, drawParent]);
    
    useEffect(() => {
        if(contextRef.current){
            contextRef.current.lineCap = "round";
            contextRef.current.strokeStyle = color;
            contextRef.current.lineWidth = 4;
        }
    }, [color, contextRef.current]);

    const saveDraw = () => {
        var canvasContents = canvasRef.current.toDataURL();
        onDraw(canvasContents);
        onClose();
    }
  
    return <>
        <div
            ref={containerRef}
            style={{border: '1px solid #DFDFDF', backgroundImage: `url(${image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
            <canvas
                onMouseDown={actions ? startDrawing : undefined}
                onMouseUp={actions ? finishDrawing : undefined}
                onMouseMove={actions ? draw : undefined}
                onTouchStart={actions ? startDrawingTouch : undefined}
                onTouchEnd={actions ? finishDrawingTouch : undefined}
                onTouchMove={actions ? drawTouch : undefined}
                ref={canvasRef}
                style={{zIndex: 1}}
            />
        </div>
        {actions && <Group mt='md' style={{justifyContent: 'center'}}>
            <InputField
                name="c"
                value={color}
                fieldType={InputFieldTypes.COLOR}
                onChange={({c}) => setColor(c)}
            />
            <Button variant="outline" onClick={onClose}>Cancelar</Button>
            <Button variant="light" onClick={clearCanvas}>Limpar</Button>
            <Button onClick={saveDraw}>Aplicar</Button>
        </Group>}
    </>
  }