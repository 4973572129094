import { Button, Grid, Paper } from '@mantine/core';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import SimpleHeader from '../components/simple_header';
import { useAuth } from '../contexts/auth.context';
import OverviewPage from './overview';
import FilesPage from './files';
import PreferencesPage from './preferences';
import TeamsPage from './teams';
import Integrations from './integrations';
import WhitelabelPage from './whitelabel';
import UsersPage from './users';
import BillingPage from './billing';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Channels, Flows } from '../components';

export default function SettingsPage({ fixed = null }){
    const {role} = useAuth();

    const [selected, setSelected] = React.useState("overview");
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openTeams, setOpenTeams] = React.useState(false);

    const { hash } = useLocation();
    const navigate = useNavigate();

    const options = [
        {key: "overview", title: "Overview", buttons: [
            
        ]},
        role && role?.profile?.title === "Administrador" && {key: "whitelabel", title: "Whitelabel", buttons: [
            
        ]},
        role && role?.profile?.title === "Administrador" && {key: "integrations", title: "Integrações", buttons: [
            
        ]},
        role && role?.profile?.title === "Administrador" && {key: "files", title: "Arquivos", buttons: [
            
        ]},
        role && role?.profile?.title === "Administrador" && {key: "preferences", title: "Preferências", buttons: [
            
        ]},
        role && role?.profile?.title === "Administrador" && {key: "users", title: "Usuários", buttons: [
            { leftIcon: <FaPlus />, title: "Usuário", onClick: () => {setOpenUsers(true)} }
        ]},
        role && role?.profile?.title === "Administrador" && {key: "teams", title: "Equipes", buttons: [
            { leftIcon: <FaPlus />, title: "Equipe", onClick: () => {setOpenTeams(true)} }
        ]},
        role && role?.profile?.title === "Administrador" && {key: "billing", title: "Financeiro", buttons: []},
    ].filter(nn => nn)

    React.useEffect(() => {
        if(fixed){
            setSelected(fixed)
        }else{
            const h = (hash.split("#")[1] ?? "").split("?")[0];
            if(h) setSelected(h);
        };
      }, [hash, fixed]);

    return <>
        <Grid>
            <Grid.Col xs={12} md={12}>
                <SimpleHeader
                    title={options.find(opt => opt.key === selected)?.title}
                    buttons={options.find(opt => opt.key === selected)?.buttons}
                />
            </Grid.Col>
            <Grid.Col xs={12} md={9}>
                {selected === "overview" && <OverviewPage />}
                {selected === "whitelabel" && <WhitelabelPage />}
                {selected === "preferences" && <PreferencesPage />}
                {selected === "users" && <UsersPage adding={openUsers} onClose={() => setOpenUsers(false)}/>}
                {selected === "teams" && <TeamsPage adding={openTeams} onClose={() => setOpenTeams(false)}/>}
                {selected === "files" && <FilesPage />}
                {selected === "integrations" && <Integrations />}
                {selected === "billing" && <BillingPage />}
                {selected === "checkout" && <BillingPage checkout />}
            </Grid.Col>

            <Grid.Col xs={12} md={3}>
                <Paper p="md" shadow='xs'>
                    {options.map(({key, title}) => (
                        <Button key={key} mb="md" onClick={() => navigate(`/configuracoes#${key}`)} fullWidth variant={selected === key ? "filled" : "light"}>
                            {title}
                        </Button>
                    ))}
                </Paper>
            </Grid.Col>
        </Grid>
    </>
    
}
