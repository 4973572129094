import { Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React from 'react';
import { useApp } from '../../contexts/app.context';
import { DELETE_TEAM, GET_ALL_TEAMS } from '../../services/teams';
import Table from '../table';

const COLUMNS = [
    {title: "Título", key: 'title'},
]

interface ListProps{}
interface ListHandle{
    refresh: () => void;
}

const List : React.ForwardRefRenderFunction<ListHandle, ListProps> = ({}, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState({});
    const [error, setError] = React.useState<any>(null);
    const [data, setData] = React.useState([]);

    const app = useApp();

    const deleteTeam = (id) => {
        setLoadingDelete(d => ({...d, [id]: true}));
        
        DELETE_TEAM(id)
        .then(() => {
            setLoadingDelete(d => ({...d, [id]: false}));
            refresh()
        })
        .catch(err => {
            setLoadingDelete(d => ({...d, [id]: false}));
            alert(err.message)
        })
    }

    const loadTeams = () => {
        setError(null);
        setLoading(true);

        GET_ALL_TEAMS()
        .then(teams => {
            setLoading(false);
            setData(teams);
        })
        .catch(err => {
            setLoading(false);
            setError(err.message);
            showNotification({message: err.message, color: 'red'});
        })
    }

    const refresh = () => { 
        loadTeams();
    }

    React.useEffect(refresh, []);

    React.useImperativeHandle(ref, () => ({
        refresh: () => { refresh(); }
    }));

    return <>
        <Paper p="sm" mt="md">
            <Table 
                data={data}
                columns={COLUMNS}
                error={error}
                loading={loading}
                actions={[
                    {
                        title: "Deletar", 
                        color: "red", 
                        loading: item => Boolean(loadingDelete[item._id]), 
                        onClick: ({item}) => {
                            app.confirmDialog(
                                {text: "Realmente deseja deletar esse usuário?"}, 
                                (({confirmed}) => {
                                    if(confirmed) deleteTeam(item._id);
                                })
                            )
                        }
                    }
                ]}
            />
        </Paper>
    </>
}

export default React.forwardRef(List);