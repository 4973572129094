import React, { useEffect } from 'react';
import { Users } from '../components';

export default function UsersPage({ adding = false, onClose = null }){
    const [open, setOpen] = React.useState(false);
    const [updating, setUpdating] = React.useState(null);

    const listRef = React.useRef<any>();

    useEffect(() => {
        if(adding) setOpen(true);
    }, [adding]);
    
    useEffect(() => {
        if(!open) onClose && onClose();
    }, [open]);

    return <>
        <Users.List ref={listRef} onEdit={id => setUpdating(id)} />
        <Users.Create
            open={open || updating}
            id={updating}
            onClose={() => { setOpen(false); setUpdating(null) }} 
            onSave={() => {
                listRef?.current?.refresh();
                setOpen(false);
                setUpdating(null)
            }}
        />
    </>
}
