import React, { useEffect, useState } from 'react'
import {Box, Button, Group, Modal, Stack, Table as MantineTable} from '@mantine/core'
import InputField, { InputFieldProps } from './input_field';
import Form, { FormProps } from './form';

interface ModalFormProps{
    loading? : boolean;
    error? : string;
    open: boolean;
    disabled?: boolean;
    onChangeData: any;
    onConfirm: any;
    onClose: any;
    size? : string;
    title :  any;
    columns?: any;
    formData: any;
    forms?: FormProps[];
}
export default function ModalForm(props : ModalFormProps){
    const {open, disabled = false, columns = [], forms = [], onChangeData, onClose, onConfirm, title, size = "xl", loading = false, error, formData} = props;
    const [data, setData] = useState<any>({});
    
    useEffect(() => {
        setData(formData);
    }, [formData]);

    return <Modal
        opened={open}
        onClose={onClose}
        centered
        size={size}
        title={title}
    >
        <Stack>
            {forms.map(f => <Form {...f} />)}
            {columns.map(column => <InputField mb="md"
                onChange={onChangeData}
                value={(data && data[column.name]) ? data[column.name] : null}
                {...column}
            />)}
            {error && <Group style={{color: "#FF9a9a", textAlign: 'right'}}>{error}</Group>}
            <Group position="center">
                <Button style={{width: '30%'}}
                    onClick={onConfirm}
                    disabled={loading || disabled}
                    loading={loading}
                >Salvar</Button>
            </Group>
        </Stack>
        
    </Modal>
}