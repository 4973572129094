import { api } from "./api"

export const GET_ALL_CUSTOMERS = async () => {
    const {data} = await api.get(`/customers/all`)
    return data;
}

export const GET_CUSTOMER_DETAILS = async (customerId : string) => {
    const {data} = await api.get(`/customers/${customerId}/details`)
    return data;
}

export const DELETE_CUSTOMER = async (customerId) => {
    const {data} = await api.delete(`/customers/${customerId}`)
    return data;
}

export const SAVE_CUSTOMER = async (params) => {
    const {data} = await api.post(`/customers/save`, params)
    return data;
}

export const CONNECT_CUSTOMER_OAUTH = async (media, customerId, params) => {
    const {data} = await api.patch(`/customers/oauth/${customerId}/${media}`, params)
    return data;
}
