import { Box, Button, Group, Modal, Pagination, Table, Tabs } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';

const CsvReader = ({ onData }) => {
    const [readSheets, setReadSheets] = useState<any[]>([]);
    const [selectedList, setSelectedList] = useState('0');

    const handleChange = (files) => {
        const file = files[0];
        const reader = new FileReader();

        reader.onload = (event: any) => {
        const data = event.target.result;
        const lines = data.split("\n");
        const columns : string[] = lines[0].split(";").map(k => (k ?? "").replace(/\"/g, "").trim());
        const items: any[] = [];
        for(let line of lines.slice(1)){
            const splited = line.split(";");
            let it : any = {};
            columns.forEach((c,i) => { it[c] = (splited[i] ?? "").replace(/\"/g, "").trim(); })
            items.push(it);
        }
        let sheets = [
            { title: "Principal", items, columns }
        ]
        // setReadSheets(sheets);
        onData(sheets[0]);
        };

        reader.readAsText(file);
    };

    return (
        <div>
            <Dropzone
                onDrop={handleChange}
                accept={[MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx]}
                multiple={false}
                style={{ margin: 0, padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                mt="lg"
            >
                <Box p="xs">
                    <FaUpload size={20} />
                </Box>
            </Dropzone>

            <Modal
                size="xl"
                opened={readSheets.length > 0}
                onClose={() => setReadSheets([])}
                title={
                    <Button onClick={() => {
                        onData(readSheets[parseInt(selectedList)]);
                        setReadSheets([]);
                    }}>Inserir Lista</Button>
                }
            >
                {/* <Tabs value={selectedList} onChange={setSelectedList}>
                    <Tabs.List>
                        {readSheets.map((s,i) => <Tabs.Tab value={`${i}`}>{s.title}</Tabs.Tab>)}
                    </Tabs.List>
                </Tabs> */}

                {/* <Table.ScrollContainer minWidth={400}> */}
                    <Table>
                        <tr>
                            {({...readSheets[parseInt(selectedList)]}.columns ?? []).map(c => <th>{c}</th>)}
                        </tr>
                        {({...readSheets[parseInt(selectedList)]}.items ?? []).map(item => <tr>
                            {({...readSheets[parseInt(selectedList)]}.columns ?? []).map(c => <td>{item[c]}</td>)}
                        </tr>)}
                    </Table>
                {/* </Table.ScrollContainer> */}
            </Modal>
        </div>
    );
};

export default CsvReader;
