import React, { useEffect } from 'react'
import { useQuery } from '../utility/util';
import { useParams } from 'react-router-dom';

export default function OAuthPage(){
    const query = useQuery();

    const { origin } = useParams();
    const code = query.get("code");
    const error = query.get("error");

    useEffect(() => {
        if(code) localStorage.setItem(`${origin}_oauth_code`, code);
        if(error) localStorage.setItem(`${origin}_oauth_error`, error);
        window.close();
    }, [code, error, origin]);

    return <div></div>

}