import UsersList from "./users/list";
import UsersCreate from "./users/create";
import TeamsList from "./teams/list";
import TeamsCreate from "./teams/create";
import CompaniesList from "../pages/companies";
import ChannelsList from "./channels/list";
import FlowsList from "./flows/list";
import SaveChannelModal from "./channels/save_channel";
import NewContact from "./conversations/new_contact";
import NewGroupCustomMessages from "./custom_messages/new_group_custom_messages";
import NewCustomMessages from "./custom_messages/new_custom_messages";
import ListGroupCustomMessages from "./custom_messages/list_group_custom_messages";


const Users = {
    List: UsersList,
    Create: UsersCreate
}

const Teams = {
    List: TeamsList,
    Create: TeamsCreate
}

const Companies = {
    List: CompaniesList,
}

const Channels = {
    List: ChannelsList,
    SaveModal: SaveChannelModal,
}

const Flows = {
    List: FlowsList
}

const Conversations = {
    NewContact,
}

const CustomMessages = {
    NewGroupCustomMessages,
    NewCustomMessages,
    ListGroupCustomMessages,
}



export { Users, Companies, Conversations, Channels, Teams, Flows, CustomMessages };