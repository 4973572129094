import { ActionIcon, Loader, UnstyledButton } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { FaPlay, FaStop } from "react-icons/fa";

const AudioPlayer = ({ url, children = null }) => {
  const [audio, setAudio] = useState<any>(null);
  const [playing, setPlaying] = useState<boolean>(false);

  const previewVoice = () => {
    audio.play();
    setPlaying(true);
    audio.addEventListener("ended", function(){
      audio.currentTime = 0;
      setPlaying(false);
    });
  }
  
  const stop = () => {
    setPlaying(false)
    audio.pause();
    audio.currentTime = 0;
  }

  useEffect(() => {
    setAudio(new Audio(url));
  },[url]);

  return <ActionIcon variant="outline" color="gray" size="lg" onClick={playing ? stop : previewVoice} disabled={!url}>
    {!playing ? <FaPlay /> : <FaStop />}
  </ActionIcon>
}

export default AudioPlayer;