import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import InputField, { InputFieldProps } from './input_field';

export interface FormProps{
    loading? : boolean;
    error? : string;
    onChangeData: any;
    onConfirm?: any;
    columns: InputFieldProps[];
    formData: any;
}
export default function Form(props : FormProps){
    const {columns = [], onChangeData, onConfirm, loading = false, error, formData} = props; 

    return <Stack>
        {columns.map(column => <InputField 
            {...column}
            onChange={onChangeData}
            value={(formData && formData[column.name]) ? formData[column.name] : null}
        />)}
        {error && <Group style={{color: "#FF9a9a", textAlign: 'right'}}>{error}</Group>}
        {onConfirm && <Group position="right">
            <Button
                onClick={onConfirm}
                disabled={loading}
            >Salvar</Button>
        </Group>}
    </Stack>
}