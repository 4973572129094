import { Accordion, ActionIcon, Avatar, Badge, Box, Button, Checkbox, Divider, Grid, Group, Image, Loader, Menu, Modal, Paper, Switch, Text, Title, Tooltip, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import useDrivePicker from 'react-google-drive-picker';
import { FaArrowDown, FaArrowUp, FaCheck, FaClone, FaEllipsisV, FaExclamation, FaEye, FaFileUpload, FaGoogleDrive, FaListAlt, FaPhotoVideo, FaPlus, FaTrash, FaYoutube } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import AudioPlayer from '../components/audio_player';
import CustomerAvatar from '../components/customer_avatar';
import DrawView from '../components/draw_view';
import InputField, { InputFieldTypes, UploadFile } from '../components/input_field';
import DriveAuth from '../components/oauth/google_drive';
import SimpleHeader from '../components/simple_header';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_ALL_CUSTOMERS } from '../services/customers';
import { CLONE_JOB, GET_JOB_DETAILS, GET_JOB_STATUS_OPTIONS, SAVE_JOB, SAVE_JOB_STATUS } from '../services/jobs';
import { GET_ALL_OAUTHS, SAVE_OAUTH } from '../services/oauths';
import { getExtenseDatetime, getMedia, getStatusColor, getTypeText, medias, useQuery } from '../utility/util';
import PublicJobPage from './public_job';
import InstagramAuth from '../components/oauth/instagram';
import TwitterAuth from '../components/oauth/twitter';
import useOauth, { OAUTH } from '../components/oauth/oauth';
import { api } from '../services/api';
import { JobsCalendar } from '../components/jobs_calendar';

export default function Job(props){
    const { jobId } = useParams()
    const navigate = useNavigate()
    const app = useApp();

    const { role, user: currentUser, userData } = useAuth();
    const { start: startAuth } = DriveAuth();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const query = useQuery();
    const update = query.get("u") === "true";
    const customer = query.get("customer");

    const [customers, setCustomers] = React.useState<any[]>([]);
    const [drives, setDrives] = React.useState<any[]>([]);
    const [canvas, setCanvas] = React.useState<any[]>([]);
    const [loadingData, setLoadingData] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [loadingSaveOauth, setLoadingSaveOauth] = React.useState<boolean>(false);
    const [loadingChangeStatus, setLoadingChangeStatus] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>({ tasks: [{
    }] });
    const [statusOptions, setStatusOptions] = React.useState([])
    const [openAdding, setOpenAdding] = React.useState(null)
    const [tempText, setTempText] = React.useState("")

    const handleChange = dt => setData(d => ({...d, ...dt}));
    const status = (data?.job_status || []).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status;
    const draftStatus = statusOptions.find(s => s.key === "draft");
    const toApproveStatus = statusOptions.find(s => s.key === "to-approve");

    const selectedCustomer = customers.find(c => c._id === (data?.customer?._id ?? data?.customer));
    
    const loadData = () => {
        setLoadingData(true)
        GET_JOB_DETAILS(jobId)
        .then((dt) => {
            setLoadingData(false)
            setData({...dt})
        })
        .catch(err => {
            setLoadingData(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const handleSave = (dt = null) => {
        if(!dt) dt = data;
        setLoadingSave(true)
        SAVE_JOB({ ...dt, id: jobId !== "novo" ? jobId : undefined })
        .then((res) => {
            setLoadingSave(false)
            showNotification({ message: "Job salvo!", color: 'green'})
            setUpdating(true);
            navigate(`/jobs/${res._id}?u=true`);
            if(jobId !== "novo"){
                loadData();
            }
        })
        .catch(err => {
            setLoadingSave(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        });
    }
    
    const changeStatus = (status, redirect = true) => {
        const customer = customers.find(c => c._id === (data?.customer?._id ?? data?.customer));
        const approvers = (customer?.flow ?? []).reduce((pv, f) => pv.concat(f.approvers), []);
        if(approvers.length === 0){
            showNotification({ message: "Selecione um cliente com pelo menos 1 aprovador no fluxo para enviar para aprovação.", color: "yellow" })
        }else{
            setLoadingChangeStatus(true)
            SAVE_JOB_STATUS(jobId, status)
            .then(() => {
                setLoadingChangeStatus(false)
                if(redirect){
                    navigate(`/jobs?customer=${data?.customer?._id ?? data?.customer}`)
                    setUpdating(false);
                }else{
                    loadData();
                }
                showNotification({ message: "Status alterado com sucesso!", color: 'green'})
            })
            .catch(err => {
                setLoadingChangeStatus(false)
                showNotification({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }

    const loadCustomers = () => {
        if(role){
            GET_ALL_CUSTOMERS()
            .then((res) => {
                setCustomers(res)
            })
            .catch(err => {
                showNotification({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }

    const loadStatusOptions = () => {
        GET_JOB_STATUS_OPTIONS()
        .then((res) => {
            setStatusOptions(res)
        })
        .catch(err => {
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const loadDrives = () => {
        GET_ALL_OAUTHS()
        .then((res) => {
            setDrives((res ?? []).filter(r => r.origin === "drive"));
            setCanvas((res ?? []).filter(r => r.origin === "canva"));
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const handleDragEnd = (taskIndex) => (result) => {
        if (!result.destination) return;
      
        const newData = (data.tasks[taskIndex]?.items || []);
        const [reorderedItem] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, reorderedItem);
        
        setData(d => ({...d, tasks: d.tasks.map((t, j) => taskIndex === j
            ? {...t, items: newData}
            : t) }));
    };
    
    const handleSortTasks = (taskIndex) => (dropIndex) => {
        if (dropIndex < 0) return;
      
        const newData = (data.tasks ?? []);
        const [reorderedItem] = newData.splice(taskIndex, 1);
        newData.splice(dropIndex, 0, reorderedItem);
        
        setData(d => ({...d, tasks: newData }));
    };

    const handleSaveOauthDrive = (code, callback) => {
        setLoadingSaveOauth(true)
        
        SAVE_OAUTH({ origin: "drive", data: { code } })
        .then((res) => {
            callback && callback(res);
            setLoadingSaveOauth(false);
            showNotification({message: "Google Drive Conectado", color: 'green'});
            loadData();
        })
        .catch(err => {
            setLoadingSaveOauth(false)
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const handleAddTask = (i = 0, data = null) => setData(d => {
        let tasks = [
            ...(d.tasks ?? []).slice(0, i + 1),
            { ...data, _id: undefined, id: undefined, job_status: [], adjustment: [], views: [], publications: [] },
            ...(d.tasks ?? []).slice(i + 1),
        ];
        return { ...d, tasks }
    })

    useEffect(() => {
        if(jobId !== "novo"){
            loadData()
        }else{
            setData(d => ({
                ...d,
                deadline: moment().add(5, "days").format(),
            }))
        }
    }, [jobId])
    
    useEffect(() => {
        if(customer){ setData(d => ({ ...d, customer })) }
    }, [customer])
    
    useEffect(() => {
        setUpdating(update);
    }, [update])
    
    useEffect(() => {
        loadCustomers()
        loadStatusOptions()
        loadDrives()
    }, [role])
    
    useEffect(() => {
        setTempText("");
    }, [openAdding]);

    if(jobId !== "novo" && !updating) return <PublicJobPage jobId={jobId} update={() => navigate(`/jobs/${jobId}?u=true`)} approver={!role && currentUser} />
    return <div style={{position: 'relative'}}>
        <Grid>
            <Grid.Col xs={12} md={12}>
                <SimpleHeader title={jobId === "novo" ? "Novo Job" : data?.name} right={
                    selectedCustomer && <CustomerAvatar size="md" color="white" name={selectedCustomer.name} image={selectedCustomer.image} />
                } buttons={[
                    jobId !== "novo" && { title: "Visão do cliente", onClick: () => navigate(`/jobs/${jobId}?u=false`) },
                ].filter(nn => nn)} />
            </Grid.Col>
            <Grid.Col xs={12} md={3}>
                <Paper p="xl" shadow='xs'>
                    <InputField
                        mb="lg"
                        name="customer"
                        title="Cliente *"
                        disabled={data?._id && data?.customer}
                        fieldType={InputFieldTypes.SELECT}
                        options={customers.filter(c => c.active).map(c => ({value: c._id, label: c.name}))}
                        value={data?.customer?._id ?? data?.customer}
                        onChange={({customer}) => {
                            if(data?._id){
                                handleSave({ ...data, customer })
                            }
                            handleChange({ customer })
                        }}
                    />

                    <InputField
                        mt="lg"
                        mb="lg"
                        name="name"
                        title="Nome do Job *"
                        value={data?.name}
                        onChange={handleChange}
                    />
                </Paper>
                <Paper mt="md" p="xl" shadow='xs'>
                    <Title order={5}>Calendário</Title>
                    <InputField
                        mt="sm"
                        name="deadline"
                        title="Deadline de Aprovação"
                        // error={moment(data?.publish_date).diff(moment(data?.deadline), "days") < 0 ? "Aprovação deveria ser anterior que a data de publicação" : null}
                        fieldType={InputFieldTypes.DATETIME}
                        value={data?.deadline ? moment(data?.deadline).toDate() : undefined}
                        onChange={({deadline}) => {
                            handleChange({ deadline: deadline ? moment(deadline).format() : undefined })
                        }}
                    />
                </Paper>
                <Box mt="lg">
                    <JobHistory data={data} />
                </Box>
            </Grid.Col>
            <Grid.Col xs={12} md={9}>
                {
                    data?.tasks.length === 0 &&
                    <Group>
                    <Text style={{flex: 1}} size="sm" weight="bold"></Text>
                    <Button
                        onClick={() => {
                            handleAddTask();
                        }}
                        color="lime" size="xs" variant="subtle" leftIcon={<FaPlus />}>Adicionar Tarefa</Button>
                </Group>
                }
                {
                    (data?.tasks || []).map((task, i) => <>
                        <RenderTask
                            data={task}
                            loadDrives={loadDrives}
                            loadCustomers={loadCustomers}
                            loadCanvas={loadDrives}
                            drives={drives}
                            canvas={canvas}
                            jobStatus={status}
                            customer={selectedCustomer}
                            onAddTask={
                                status?.key !== "approved"
                                ? (data = null) => handleAddTask(i, data)
                                : null
                            }
                            onSortItems={handleDragEnd(i)}
                            onSortDown={i < data.tasks.length - 1 ? () => { handleSortTasks(i)(i + 1) } : null}
                            onSortUp={i > 0 ? () => { handleSortTasks(i)(i - 1) } : null}
                            onRemove={
                                (i > 0 || (data?.tasks || []).length > 1)
                                ? () => {
                                    setData(d => ({
                                        ...d,
                                        tasks: d.tasks.filter((a2, j) => i !== j)
                                    }))
                                }
                                : undefined
                            }
                            onChange={(dt) => {
                                if(typeof dt === "function"){
                                    setData(d => ({
                                        ...d,
                                        tasks: d.tasks.map((a2, j) => i === j ? { ...a2, ...dt(a2) } : a2)
                                    }))
                                }else{
                                    setData(d => ({
                                        ...d,
                                        tasks: d.tasks.map((a2, j) => i === j ? { ...a2, ...dt } : a2)
                                    }))
                                }
                            }}
                        />
                    </>)
                } 
            </Grid.Col>
        </Grid>
        <Paper p="xs" shadow='xl' style={{position: 'fixed', bottom: 0, right: 0, left: 0, borderTop: '1px solid #DFDFDF', padding: `10px ${isMobile ? 0 : 100}px`}}>
            <Group>
                <div style={{flex: 1}}></div>
                {
                    (["to-approve", "approved", ].includes(status?.key) && draftStatus)
                    ? <Menu>
                        <Menu.Target>
                            <Button variant='light' loading={loadingSave || loadingChangeStatus}>Salvar</Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item disabled={loadingData} onClick={() => handleSave()}>Salvar</Menu.Item>
                            <Menu.Item disabled={loadingData} onClick={() => {
                                handleSave()
                                changeStatus(draftStatus._id, false);
                            }}>Salvar e voltar pra rascunho</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                    : <Button
                        variant='light'
                        disabled={loadingData}
                        loading={loadingSave || loadingChangeStatus}
                        onClick={() => handleSave()}
                    >Salvar</Button>
                }
                {jobId !== "novo" && 
                    (
                        <Button
                            disabled={(
                                (status && ["to-approve", "approved"].includes(status?.key)) ||
                                !toApproveStatus ||
                                !data?.customer
                            )}
                            onClick={() => { changeStatus(toApproveStatus._id) }}
                        >Enviar para aprovação</Button>
                    )}
                {jobId !== "novo" && <Menu>
                    <Menu.Target><ActionIcon
                        variant="light"
                        color="gray"
                        size="lg"
                    ><FaEllipsisV /></ActionIcon></Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            onClick={() => {
                                app.confirmDialog({ text: "Deseja clonar/duplicar esse job?" }, ({ confirmed }) => {
                                    if(confirmed){
                                        CLONE_JOB(jobId)
                                        .then(job => {
                                            navigate(`/jobs/${job._id}?u=true`);
                                            showNotification({ message: "O job foi clonado como rascunho, selecione o cliente para continuar.", color: "green" })
                                        })
                                        .catch(err => {
                                            showNotification({ message: err.message, color: "red" });
                                        })
                                    }
                                })
                            }}
                        >Criar Cópia</Menu.Item>
                    </Menu.Dropdown>
                </Menu>}
            </Group>
        </Paper>

        {/* <Modal
            opened={openAdding}
            onClose={() => setOpenAdding(null)}
            styles={{body: { minHeight: 300 }}}
        >
            <Modal.Body>
                <InputField
                    name="type"
                    title="Formato"
                    mb="md"
                    fieldType={InputFieldTypes.SELECT}
                    options={[
                        {label: "Imagem", value: "image"},
                        {label: "Arquivo", value: "file"},
                        {label: "Áudio", value: "audio"},
                        {label: "Texto", value: "text"},
                        {label: "Vídeo", value: "video"},
                    ]}
                    value={openAdding?.type}
                    onChange={({type}) => {
                        setOpenAdding(oa => ({ ...oa, type, items: [] }));
                    }}
                />
                {openAdding?.type && (
                    openAdding?.type === "text"
                    ? <>
                        <InputField
                            name="d"
                            title="Texto"
                            fieldType={InputFieldTypes.TEXTAREA}
                            value={(openAdding?.items ?? [])[0]?.value}
                            onChange={({d}) => { setOpenAdding(oa => ({ ...oa, items: [{ type: "text", value: d }] })) }}
                        />
                    </>
                    : <InputField
                        name={"vl"}
                        title="Arquivo"
                        fieldType={
                            (!openAdding?.type || openAdding?.type === "image") ? InputFieldTypes.IMAGE : InputFieldTypes.FILE
                        }
                        accept={openAdding?.type === "video" ? ["video/*"] : undefined}
                        multiple
                        onChange={({vl}) => {
                            setOpenAdding(oa => ({ ...oa, items: [...(oa?.items ?? []), ...(vl ?? []).map(value => ({ value, type: openAdding?.type }))] }))
                        }}
                    />
                )}
            </Modal.Body>
            <Group position='right'>
                <Button variant="transparent" onClick={() => setOpenAdding(null)}>Cancelar</Button>
                <Button
                    disabled={!(openAdding?.items ?? [])[0]?.value}
                    onClick={() => {
                        setData(d => ({
                            ...d,
                            tasks: d.tasks.map((a2, j) => openAdding?.index === j ? {
                                ...a2,
                                items: [...(a2?.items ?? []), ...(openAdding?.items ?? [])]
                            } : a2)
                        }))
                        setOpenAdding(null)
                    }}
                >Adicionar {(openAdding?.items ?? []).length} itens</Button>
            </Group>
        </Modal> */}
    </div>
}

export function jobComments({ data }){
    const comments : {
        datetime: string,
        text: any,
        author: any,
        component,
    }[] = [
        {
            author: "Sistema",
            datetime: data?.createdAt,
            text: "Foi cadastrado",
        },
        ...(data?.views || []).map(s => ({
            datetime: s.datetime,
            text: <span><b>{s.createdBy?.name || s.approver?.name || "Cliente"}</b> visualizou o job.</span>,
            author: s.createdBy?.name || s.approver?.name || "Cliente",
        })),
        ...(data?.adjustment || []).map(s => ({
            datetime: s.datetime,
            author: s.createdBy?.name || s.approver?.name || "Cliente",
            component: <Group>
                <Box style={{flex: 1}}>
                    <Text size="xs"><b>{s.createdBy?.name || s.approver?.name || "Usuário"}</b> solicitou ajustes: <i>{s.text}</i></Text>
                </Box>
                {s.draws && <DrawView items={(data?.items ?? []).filter(item => item.type === "image").map(item => ({ image: item.value, draw: {...s.draws}[item.value] }))} />}
                {s.audio && <AudioPlayer url={s.audio} />}
            </Group>
        })),
        ...(data?.logs || []).map(s => ({
            datetime: s.datetime,
            author: s.createdBy?.name || s.approver?.name || "Cliente",
            text: <span><b>{s.createdBy?.name || s.approver?.name || "Usuário"}</b> {s.type === "description-update" ? "editou a descrição" : ""}</span>,
        })),
        ...(data?.job_status || []).filter(s => s.status?.key !== "adjust").map(s => ({
            datetime: s.createdAt,
            author: s.createdBy?.name || s.approver?.name || "Cliente",
            text: <span><b>{s.createdBy?.name || s.approver?.name || "Usuário"}</b> alterou o status para <Text component='span' weight="bold" color={getStatusColor(s.status)}>{s.status?.title}</Text></span>
        })),
    ].sort((a,b) => moment(a.datetime) > moment(b.datetime) ? -1 : 1);

    return comments;
}

export function JobHistory({ data }){
    const comments = jobComments({ data });
    return <Paper p="xl" shadow='xs'>
        <Title order={4} mb="lg">Histórico</Title>
        {comments.map(s => <div>
            <Divider mt="sm" mb="sm" />
            <Group style={{flexDirection: 'column', alignItems: 'normal'}}>
                <Text size="xs" color="lime" weight="bold">{moment(s.datetime).format("DD/MM/YYYY HH:mm")}</Text>
                {s.component ?? <Text size="xs">{s.text}</Text>}
            </Group>
        </div>)}
    </Paper>
}

const RenderTask = ({ customer, jobStatus, loadCustomers, data: task, onAddTask, drives, canvas, loadDrives, loadCanvas, onChange, onRemove, onSortItems, onSortDown = null, onSortUp = null }) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [openAdding, setOpenAdding] = useState(false);
    const [openCanva, setOpenCanva] = useState(null);
    const [isValidAdding, setIsValidAdding] = useState(false);
    const [taskStatus, setTasksStatus] = useState<any>(null);
    const [addingModal, setAddingModal] = useState<any>(null);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [tempText, setTempText] = useState<string>("");

    const app = useApp();
    const { userData } = useAuth();
    const { pickFiles } = useApp();
    const bottomRef = useRef(null);

    const taskMedia = getMedia(task?.destination);
    const taskMediaOption = (taskMedia?.options ?? []).find(opt => opt.key === task?.option);
    const taskOauth = (customer?.oauths ?? []).find(oauth => oauth.origin === taskMedia?.key)
    const MediaIcon = taskMedia?.Icon ?? FaPhotoVideo;

    const { connect } = useOauth();
    const [openPicker, authResponse] = useDrivePicker();  
    
    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.items).map((item: any) =>
            item.getAsFile()
        );
        setUploadedFiles((uf) => [...uf, ...files]);
        setIsDraggingOver(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const getDriveData = async (link) => {
        const match = link.match(/[-\w]{25,}/);
        const fileId = match ? match[0] : null;
        if (!fileId) {
            return { error: "Esse arquivo não existe ou não é público." }
        }
        
        try {
            const dataUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=mimeType,hasThumbnail,thumbnailLink,webViewLink&key=${process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}`;
            const { data: { mimeType, hasThumbnail, webViewLink: value, thumbnailLink: thumb } } = await axios.get(dataUrl);
            const type = {
                "application": "file",
                "image": "image",
                "video": "video",
                "audio": "audio",
            }[(mimeType ?? "").split("/")[0]] ?? "file";
            return { id: fileId, mimeType, type, thumb: hasThumbnail ? thumb : undefined, value }
        } catch (error) {
            console.log(error)
            return { error: "Esse arquivo não existe ou não é público."}
        }
    }

    const getYouTubeData = async (link) => {
        const match = link.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})|youtu\.be\/([a-zA-Z0-9_-]{11})/);
        const videoId = match ? (match[1] || match[2]) : null;
      
        if (!videoId) {
          return { error: "Esse vídeo não existe ou não é público." };
        }
      
        try {
          const dataUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;
          const { data: { items } } = await axios.get(dataUrl);
      
          if (items.length === 0) {
            return { error: "Esse vídeo não existe ou não é público." };
          }
      
          const { title, thumbnails } = items[0].snippet;
          const thumb = thumbnails.high.url;
          const value = `https://www.youtube.com/embed/${videoId}`;
      
          return { id: videoId, type: "video", title, thumb, value };
        } catch (error) {
          return { error: "Esse vídeo não existe ou não é público." };
        }
    };

    const handleOpenPicker = (
        token,
        viewId: "DOCS" | "DOCS_IMAGES" | "DOCS_IMAGES_AND_VIDEOS" | "DOCS_VIDEOS" | "DOCUMENTS" | "PDFS" = "DOCUMENTS",
    ) => {
        return new Promise((resolve, reject) => {
            openPicker({
                clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
                developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
                viewId,
                token,
                showUploadView: true,
                showUploadFolders: true,
                supportDrives: true,
                multiselect: true,
                setSelectFolderEnabled: true,
                setIncludeFolders: true,
                locale: "pt-BR",
                callbackFunction: (data) => {
                    if(data.action === "picked"){
                        resolve(data.docs)
                    }
                },
            })
        })
    }


    const openDrive = (oauth) => {
        handleOpenPicker(oauth?.data?.auth?.access_token, "DOCS")
        .then((items: any[]) => {
            if(items.length > 0){
                console.log(items);
                setUploadedFiles(uf => [
                    ...uf,
                    ...items.map(i => ({
                        data: i,
                        origin: "drive",
                        auth: { oauthId: oauth._id },
                    }))
                ])
            }
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" });
        })
    }

    useEffect(() => {
        (async () => {
            if(addingModal?.link_key && addingModal?.origin === "youtube"){
                setAddingModal(am => ({ ...am, loading: true }))
                const fileData: any = await getYouTubeData(addingModal?.link_key);
                const { error, type, thumb, value } = fileData; 
                if(error){
                    setAddingModal(am => ({ ...am, error: error }));
                }else{
                    setAddingModal(am => ({
                        ...am, error: false, externalId: fileData.id, type, value, thumb,
                    }));
                }
                setAddingModal(am => ({ ...am, loading: false }))
            }
        })();
    }, [addingModal?.origin, addingModal?.link_key]);

    useEffect(() => {
        const status = (task?.job_status || []).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status;
        setTasksStatus(status);
    }, [task]);
    
    useEffect(() => {
        if(addingModal){
            if(addingModal?.type === "text"){
                setIsValidAdding((addingModal?.value ?? "").length >= 3);
            }else{
                setIsValidAdding(addingModal?.value);
            }
        }
    }, [addingModal]);
    
    useEffect(() => {
        if(openCanva){
            const canvaAccount = (canvas ?? [])[0]?.data?.accounts[0]?.id;
            if(addingModal?.origin === "canva" && !addingModal?.canva_account && canvaAccount){
                setAddingModal(am => ({ ...am, canva_account: canvaAccount }))
            }
        }
    }, [openCanva, canvas]);

    const startConnect = () => {
        if(userData?.user?.isAdmin && [
            "instagram",
            "twitter",
            "pinterest",
            "facebook",
            "linkedin",
            "youtube",
            "tiktok",
        ].includes(taskMedia.key)){
            connect(taskMedia.key as OAUTH, loadCustomers, { customer: customer._id });
        }else{
            showNotification({ message: "Funcionalidade disponível em breve", color: "yellow" });
        }
    }

    return <>
        <Paper mb="lg" shadow='xs' style={{ padding: 0 }}
            className={`${isDraggingOver ? "dragging-over" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <Grid gutter={0}>
                <Grid.Col xs={12} md={12} style={{borderBottom: '1px solid #EFEFEF', padding: 20}}>
                    {taskStatus && <Badge mb="lg">{taskStatus.title}</Badge>}
        
                    <Grid gutter={20}>
                        <Grid.Col xs={12} md={8} style={{padding: '0 12px', margin: '12px 0', borderRight: '2px solid #EFEFEF'}}>
                            <Group align="flex-end">
                                <InputField
                                    name="name"
                                    placeholder="Título *"
                                    value={task.name}
                                    style={{flex: 1}}
                                    onChange={({name}) => { onChange({ name }) }}
                                />
                                <ActionIcon
                                    size="lg"
                                    variant="filled"
                                    disabled={!onSortDown}
                                    onClick={() => { onSortDown() }}
                                ><FaArrowDown /></ActionIcon>
                                <ActionIcon
                                    size="lg"
                                    variant="filled"
                                    disabled={!onSortUp}
                                    onClick={() => { onSortUp() }}
                                ><FaArrowUp /></ActionIcon>
                            </Group>
                            <InputField
                                name="description"
                                placeholder="Descrição"
                                mt="md"
                                fieldType={InputFieldTypes.TEXTAREA}
                                value={task.description}
                                onChange={({description}) => { onChange({ description }) }}
                            />
                            <Text fw="bold" size="sm" mt="md">Arraste seus arquivos:</Text>
                            <DragDropContext onDragEnd={onSortItems}>
                                <Droppable droppableId="directions" direction="horizontal">
                                    {(provided) => (
                                        <Group pt="lg" pb="lg" align='flex-start' ref={provided.innerRef} {...provided.droppableProps}>
                                            {(task.items || []).map((c, itemIndex) => (
                                                <Draggable key={itemIndex} draggableId={itemIndex.toString()} index={itemIndex}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Group style={{position: 'relative'}}>
                                                                <Box
                                                                    style={{border: '1px solid #228be6', borderRadius: 5}}
                                                                    onClick={()=> {
                                                                        if(c.type === "text"){
                                                                            app.expandText(c.value)
                                                                        }else if((!c.type || c.type === "image")){
                                                                            app.expandImage(c.value)
                                                                        }else{
                                                                            window.open(c.value, "_blank")
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        (c.thumb || !c.type || c.type === "image")
                                                                        ? <Image  width={84} height={84} src={c.thumb ?? c.value} />
                                                                        : c.type === "video"
                                                                        ? <video
                                                                            src={`${c.value}#t=0.1`}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                width: 84,
                                                                                height: 84,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                            preload='metadata' 
                                                                            muted
                                                                            controls={false}
                                                                        />
                                                                        : <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 84, height: 84}}>
                                                                            <Text color="lime" size="xs">{getTypeText(c.type)}</Text>
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                                <ActionIcon 
                                                                    onClick={() => {
                                                                        app.confirmDialog({
                                                                            text: "Realmente deseja remover esse arquivo?",
                                                                            buttonText: "Remover"
                                                                        }, ({confirmed}) => {if(confirmed){
                                                                            onChange({
                                                                                items: (task?.items ?? []).filter((item, itemIndexJ) => itemIndex !== itemIndexJ)
                                                                            })
                                                                        }})
                                                                    }}
                                                                    variant='filled'
                                                                    color="yellow"
                                                                    style={{position: 'absolute', bottom: 5, right: 5}}
                                                                    size="sm"
                                                                ><FaTrash size={12}/></ActionIcon>
                                                                {/* <Text>{c.type}</Text> */}
                                                            </Group>
                                                    </div>)}
                                                </Draggable>
                                            ))}
                                            {jobStatus?.key !== "approved" && <Menu>
                                                <Menu.Target>
                                                    <Box style={{width: 100, textAlign: 'center'}}>
                                                        <ActionIcon color="lime" variant="outline" size={84}><FaPlus /></ActionIcon>
                                                    </Box>
                                                </Menu.Target>
                                                <Menu.Dropdown>
                                                    <Menu.Label>Google Drive</Menu.Label>
                                                    {
                                                        drives.map(drive => (
                                                            <Menu.Item
                                                                icon={<FaGoogleDrive />}
                                                                onClick={() => {
                                                                    openDrive(drives[0]);
                                                                }}
                                                            >{drive?.data?.me?.email}</Menu.Item>
                                                        ))
                                                    }
                                                    <Menu.Item
                                                        icon={<FaGoogleDrive />}
                                                        rightSection={<FaPlus />}
                                                        onClick={() => {
                                                            connect(OAUTH.DRIVE, (oauth) => {
                                                                loadDrives();
                                                                openDrive(oauth);
                                                            })
                                                        }}
                                                    >Conectar</Menu.Item>
                                                    <Menu.Label>Canva</Menu.Label>
                                                    {canvas.map(canva => (
                                                        <Menu.Item
                                                            icon={<Avatar size={15} src="/assets/canva.png" />}
                                                            onClick={() => {
                                                                setOpenCanva(canvas[0]);
                                                            }}
                                                        >{canva?.data?.me?.profile?.display_name}</Menu.Item>
                                                    ))}
                                                    <Menu.Item
                                                        icon={<Avatar size={15} src="/assets/canva.png" />}
                                                        rightSection={<FaPlus />}
                                                        onClick={() => {
                                                            connect(OAUTH.CANVA, (oauth) => {
                                                                loadCanvas();
                                                                setOpenCanva(oauth);
                                                            })
                                                        }}
                                                    >Conectar</Menu.Item>
                                                    <Menu.Label>Outros</Menu.Label>
                                                    <Menu.Item onClick={() => setAddingModal({
                                                        origin: "youtube"
                                                    })} icon={<FaYoutube />}>Youtube</Menu.Item>
                                                    <Menu.Item onClick={() => setAddingModal({
                                                        type: "text"
                                                    })} icon={<FaListAlt />}>Texto</Menu.Item>
                                                    <Menu.Item icon={<FaFileUpload />} onClick={() => {
                                                        pickFiles({
                                                            onSelect: (files) => {
                                                                setUploadedFiles(uf => [...uf, ...files])
                                                            }
                                                        })
                                                    }}>Do meu computador</Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>}
                                        </Group>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {uploadedFiles.map(file => {
                                return <UploadFile
                                    progressOnFinish={false}
                                    file={file}
                                    onFileUploaded={({ url, thumb, type }) => {
                                        onChange(dt => ({
                                            ...dt,
                                            items: [...(dt?.items || []), { value: url, type, thumb }]
                                        }))
                                    }}
                                />
                            })}
                            
                            <Group position='right'>
                                {onAddTask && <UnstyledButton onClick={() => {
                                    onAddTask(task)
                                }}><Badge color="gray" size="xs">Duplicar Tarefa</Badge></UnstyledButton>}
                                {onRemove && <UnstyledButton onClick={() => {
                                    onRemove()
                                }}><Badge color="red" size="xs">Remover Item</Badge></UnstyledButton>}
                            </Group>
                        </Grid.Col>
                        {
                            (task?.publications ?? []).length === 0
                            ? <Grid.Col xs={12} md={4} style={{padding: '0 12px', margin: '12px 0'}}>
                                <Menu position="bottom" width={260}>
                                    <Menu.Target>
                                        <Button
                                            fullWidth
                                            leftIcon={<MediaIcon />}
                                            variant='filled' color={taskMedia?.color ?? "gray"} size="sm">
                                            {taskMedia?.title ?? "Rede Social"}
                                        </Button>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        {medias
                                        .filter(m => m.key !== task?.destination)
                                        .map(({ Icon, key, title, color, options = [] }) => <Menu.Item
                                            p="2px"
                                            onClick={() => onChange({ destination: key, option: options[0]?.key })}
                                        >
                                            <Button
                                                color={color}
                                                leftIcon={<Icon />}
                                                variant='filled'
                                                fullWidth
                                            >
                                                {title}
                                            </Button>
                                        </Menu.Item>)}
                                    </Menu.Dropdown>
                                </Menu>

                                {(taskMedia?.options ?? []).length > 0 && <InputField
                                    name="option"
                                    mt="md"
                                    value={task?.option}
                                    clearable={false}
                                    searchable={false}
                                    onChange={({ option }) => onChange({ option })}
                                    fieldType={InputFieldTypes.SELECT}
                                    options={(taskMedia?.options ?? []).map(opt => ({ value: opt.key, label: opt.title }))}
                                />}

                                {
                                    taskMediaOption && 
                                    <>
                                        <InputField
                                            mt="sm"
                                            name="publish_date"
                                            title="Data de Publicação"
                                            fieldType={InputFieldTypes.DATETIME}
                                            value={task?.publish_date ? moment(task?.publish_date).toDate() : undefined}
                                            onChange={({publish_date}) => {
                                                onChange({ publish_date })
                                            }}
                                        />
                                        {
                                            (taskMediaOption?.assets ?? []).map(asset => <Box mt="md">
                                                <InputField
                                                    name="value"
                                                    value={{...task?.assets}[asset.key] ?? asset.default}
                                                    onChange={({ value }) => onChange(dt => ({ ...dt, assets: { ...dt?.assets, [asset.key]: value } }))}
                                                    title={asset.title}
                                                    fieldType={{
                                                        "text": InputFieldTypes.TEXTAREA,
                                                        "image": InputFieldTypes.IMAGE,
                                                        "options": InputFieldTypes.SELECT,
                                                        "boolean": InputFieldTypes.SWITCH,
                                                    }[asset.type] ?? InputFieldTypes.FILE}
                                                    accept={{
                                                        "audio": ["audio/*"],
                                                        "video": ["video/*"],
                                                    }[asset.type]}
                                                    options={(asset?.options ?? [])}
                                                />
                                            </Box>)
                                        }
            
                                        <Switch
                                            mt="xl"
                                            checked={!!task?.allow_schedule}
                                            disabled={!customer}
                                            onChange={() => {
                                                onChange(dt => ({ allow_schedule: !dt.allow_schedule }))
                                            }}
                                            label="Agendar post automáticamente após aprovação"
                                        />

                                        {
                                            task?.allow_schedule &&
                                            (
                                                taskOauth
                                                ? <InputField
                                                    name="publish_account"
                                                    value={task?.publish_account}
                                                    onChange={({publish_account}) => {
                                                        if(publish_account === "connect"){
                                                            startConnect();
                                                        }else{
                                                            onChange({ publish_account });
                                                        }
                                                    }}
                                                    fieldType={InputFieldTypes.SELECT}
                                                    clearable={false}
                                                    mt="md"
                                                    title="Selecione a conta"
                                                    options={
                                                        [
                                                            ...(taskOauth?.data?.accounts ?? []).map(account => ({
                                                                value: account.id,
                                                                label: account.title,
                                                            })),
                                                            {
                                                                label: "Conectar Conta",
                                                                value: "connect",
                                                            }
                                                        ]
                                                    }
                                                />
                                                : <Button
                                                    color="gray"
                                                    mt="md"
                                                    fullWidth
                                                    variant='outline'
                                                    onClick={() => { startConnect() }}
                                                >
                                                    Conectar
                                                </Button>
                                            )
                                        }
                                    
                                    </>
                                }

                            </Grid.Col>
                            : <Grid.Col xs={12} md={4} style={{padding: '0 12px', margin: '12px 0'}}>
                                {
                                    task?.publications.map(pub => {
                                        const media = getMedia(pub.destination);
                                        const { Icon: MediaIcon } = media;
                                        return <Paper p="xs" mb="md" shadow='xs'>
                                            <Group>
                                                <Avatar
                                                    variant='filled'
                                                    color={media?.color ?? "gray"}
                                                    size="md"
                                                ><MediaIcon /></Avatar>
                                                <Box style={{flex: 1}}>
                                                    <Text color="gray" size="xs">
                                                        {{
                                                            "done": `Publicado ${getExtenseDatetime(pub.datetime)} no ${media?.title}`,
                                                            "failed": `Falha ao publicar no ${media?.title}`,
                                                        }[pub.status]}
                                                    </Text>
                                                    {pub?.error && <Text color="red" size="xs">{pub?.error}</Text>}
                                                </Box>
                                                {pub.link && <ActionIcon variant="subtle" color="lime" onClick={() => window.open(pub.link, "_blank")} size="sm">
                                                    <FaEye />
                                                </ActionIcon>}
                                            </Group>
                                        </Paper>
                                    })
                                }
                            </Grid.Col>
                        }
                    </Grid>
                </Grid.Col>
                <Grid.Col xs={12} md={12}>
                    <Accordion>
                        <Accordion.Item value="Expandir histórico">
                            <Accordion.Control>Histórico</Accordion.Control>
                            <Accordion.Panel>
                                <JobHistory data={task} />
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </Grid.Col>
            </Grid>
        </Paper>

        {onAddTask && <Group>
            <Text style={{flex: 1}} size="sm" weight="bold"></Text>
            <Button
                onClick={() => {
                    onAddTask();
                    bottomRef.current.scrollIntoView({behavior: "smooth"});
                }}
                color="lime" size="xs" variant="subtle" leftIcon={<FaPlus />}>Adicionar Tarefa</Button>
        </Group>}
        <div ref={bottomRef}>&nbsp;</div>

        <Modal
            opened={!!addingModal}
            onClose={() => setAddingModal(null)}
            size="xl"
            title={
                addingModal?.type === "text"
                ? "Adicionar Texto"
                : addingModal?.origin === "drive"
                ? "Adicionar Link do Google Drive"
                : addingModal?.origin === "youtube"
                ? "Adicionar Link do Youtube"
                : ""
            }
        >
            {
                addingModal?.type === "text" &&
                <InputField
                    name="value"
                    title="Texto"
                    fieldType={InputFieldTypes.TEXTAREA}
                    value={addingModal?.value}
                    onChange={({value}) => { setAddingModal(am => ({ ...am, value })) }}
                />
            }
            {
                ["youtube"].includes(addingModal?.origin) &&
                <>
                    <InputField
                        name="link_key"
                        title="Link"
                        value={addingModal?.link_key}
                        onChange={({link_key}) => { setAddingModal(am => ({ ...am, link_key })) }}
                    />

                    {addingModal?.origin === "youtube" && addingModal?.externalId && (
                        <iframe
                            frameBorder="0"
                            width="100%"
                            height="300px"
                            style={{marginTop: 30}}
                            src={addingModal?.value}>
                        </iframe>
                    )}
                </>
            }
            {addingModal?.error && <Text mt="md" fw="bold" c="red" size="xs">{addingModal?.error}</Text>}
            <Group position='right' mt="md">
                {addingModal?.origin !== "canva" && <Button
                    disabled={!isValidAdding}
                    onClick={() => { 
                        onChange({ items: [...(task.items ?? []), addingModal] });
                        setAddingModal(null);
                    }}
                    loading={addingModal?.loading}
                >Adicionar</Button>}
            </Group>
        </Modal>
       
        <Modal
            opened={!!openCanva}
            onClose={() => setOpenCanva(null)}
            size="xl"
        >
            {/* <InputField
                fieldType={InputFieldTypes.SELECT}
                value={openCanva?.canva_account}
                name="canva_account"
                onChange={({canva_account}) => { setOpenCanva(am => ({ ...am, canva_account })) }}
                options={canvas.reduce((pv, c) => pv.concat((c.data?.accounts ?? []).map(account => ({
                    value: account?.id,
                    label: account?.title,
                }))), [])}
            /> */}

            <CanvaSelector
                oauthId={openCanva?._id}
                onSelect={(items = []) => {
                    setUploadedFiles(uf => [...uf, ...items.map(i => ({ origin: 'canva', data: i, auth: { oauthId: openCanva?._id } }))]);
                    setOpenCanva(null);
                }}
            />
            {openCanva?.error && <Text mt="md" fw="bold" c="red" size="xs">{openCanva?.error}</Text>}
            <Group position='right' mt="md">
                {/* {openCanva?.origin !== "canva" && <Button
                    disabled={!isValidAdding}
                    onClick={() => { 
                        onChange({ items: [...(task.items ?? []), openCanva] });
                        setOpenCanva(null);
                    }}
                    loading={openCanva?.loading}
                >Adicionar</Button>} */}
            </Group>
        </Modal>
    </>
}

const getCanvaFiles = async (oauthId) => {
  try {
    const { data: { items = [] } } = await api.post(
        `${process.env.REACT_APP_SERVER_URL}/oauths/${oauthId}/execute`, {
            method: "GET",
            url: "https://api.canva.com/rest/v1/designs",
        }
    );
    return items;
  } catch (error) {
    console.error('Falha ao carregar arquivos:', error);
    throw error;
  }
};

const CanvaSelector = ({ oauthId, onSelect }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(oauthId){
        setError(null);
        setLoading(true);
        const fetchFiles = async () => {
        try {
            const files = await getCanvaFiles(oauthId);
            setFiles(files);
            setLoading(false);
        } catch (err) {
            setError('Falha ao carregar arquivos: ' + err.message);
            setLoading(false);
        }
        };

        fetchFiles();
    }
  }, [oauthId]);

  if (loading) return <Loader />;
  if (error) return <Text size="sm" c="red" fw="bold">{error}</Text>;

  return (
    <Grid mt="md" mb="md">
        {
            files.map((file) => (
            <Grid.Col md={4} key={file.id}>
                <Menu>
                    <Menu.Target>
                        <UnstyledButton
                            onClick={() => {
                                onSelect([ { designId: file.id, format: {type: "jpg", quality: 100, as_single_image: true} } ])
                            }}
                            style={{width: '100%'}}>
                            <Image src={file.thumbnail?.url} style={{background: '#EFEFEF'}} height={160} width={'100%'} fit="cover" />
                            <Box style={{backgroundColor: '#2587dd'}} p="md">
                                <Text size="xs" fw="bold" c="white" ta="center">{file.title ?? file.id}</Text>
                            </Box>
                        </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                        {/* <Menu.Item onClick={() => setDownloading({ file, thumb: file?.thumbnail?.url, format: { type: "jpg" } })}>JPG</Menu.Item>
                        <Menu.Item onClick={() => setDownloading({ file, thumb: file?.thumbnail?.url, format: { type: "pdf" } })}>PDF</Menu.Item>
                        <Menu.Item onClick={() => setDownloading({ file, thumb: file?.thumbnail?.url, format: { type: "png" } })}>PNG</Menu.Item>
                        <Menu.Item onClick={() => setDownloading({ file, thumb: file?.thumbnail?.url, format: { type: "pptx" } })}>PPTX</Menu.Item>
                        <Menu.Item onClick={() => setDownloading({ file, thumb: file?.thumbnail?.url, format: { type: "gif" } })}>GIF</Menu.Item>
                        <Menu.Item onClick={() => setDownloading({ file, thumb: file?.thumbnail?.url, format: { type: "mp4" } })}>MP4</Menu.Item> */}
                    </Menu.Dropdown>
                </Menu>
            </Grid.Col>
            ))
        }
    </Grid>
  );
};
