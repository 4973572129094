import { api } from "./api"

export const GET_ALL_PLANS = async () => {
    const {data} = await api.get("/plans")
    return data.plans || [];
}

export const DELETE_PLAN = async (id) => {
    const {data} = await api.delete(`/plans/${id}`)
    return data;
}

export const SAVE_PLAN = async (params : any) => {
    const {data} = await api.post(`/plans`, params)
    return data;
}
