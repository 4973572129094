import { Button, Grid, Paper } from '@mantine/core';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Companies } from '../components';
import SimpleHeader from '../components/simple_header';
import { useAuth } from '../contexts/auth.context';
import Plans from './plans';
import Coupons from './coupons';
import AdminWhatsapps from './admin_whatsapps';
// import { Channels, Flows } from '../components';

export default function SettingsPage(){
    const {role} = useAuth();

    const [selected, setSelected] = React.useState("plans");
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openTeams, setOpenTeams] = React.useState(false);

    const { hash } = useLocation();
    const navigate = useNavigate();

    const options = [
        {key: "plans", title: "Planos", buttons: []},
        {key: "companies", title: "Empresas", buttons: []},
        {key: "coupons", title: "Cupons", buttons: []},
        {key: "whatsapps", title: "Whatsapps", buttons: []},
    ].filter(nn => nn)

    React.useEffect(() => {
        if (hash) {
            setSelected(hash.split("#")[1]);
        };
      }, [hash]);

    return <>
        <Grid>
            <Grid.Col xs={12} md={12}>
                <SimpleHeader
                    title={options.find(opt => opt.key === selected)?.title}
                    buttons={options.find(opt => opt.key === selected)?.buttons}
                />
            </Grid.Col>
            <Grid.Col xs={12} md={9}>
                {selected === "plans" && <Plans />}
                {selected === "companies" && <Companies.List />}
                {selected === "coupons" && <Coupons />}
                {selected === "whatsapps" && <AdminWhatsapps />}
            </Grid.Col>

            <Grid.Col xs={12} md={3}>
                <Paper p="md" shadow='xs'>
                    {options.map(({key, title}) => (
                        <Button key={key} mb="md" onClick={() => navigate(`/admin-aprova-ai#${key}`)} fullWidth variant={selected === key ? "filled" : "light"}>
                            {title}
                        </Button>
                    ))}
                </Paper>
            </Grid.Col>
        </Grid>
    </>
    
}
