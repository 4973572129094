import React from 'react';
import { SEND_INVITE } from '../../services/companies';
import { GET_ALL_PROFILES } from '../../services/profiles';
import { GET_ALL_TEAMS } from '../../services/teams';
import { InputFieldTypes } from '../input_field';
import ModalForm from '../modal_form';
import { GET_USER_DATA, UPDATE_USER } from '../../services/users';
import { showNotification } from '@mantine/notifications';
import { userInfo } from 'os';

export default function Create({open = true, onClose, onSave, id = null}){
    const [formData, setFormData] = React.useState<any>(null);
    const [data, setData] = React.useState([]);
    const [loadingData, setLoadingData] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [error, setError] = React.useState<any>(null);
    const [profile, setProfile] = React.useState<any[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);

    const loadProfile = () => {
        GET_ALL_PROFILES()
        .then(res => {
            setProfile(res)
        })
        .catch(err => {
            showNotification({ message: err.message })
        })
    }
    
    const loadTeams = () => {
        GET_ALL_TEAMS()
        .then(res => {
            setTeams(res)
        })
        .catch(err => {})
    }

    const onConfirm = () => {
        try{
            setError(null)
            setLoadingSave(true)
            if(!formData?.email) throw new Error("Informe o e-mail do usuário!!");
            if(id){
                UPDATE_USER(id, {...formData})
                .then(res => {
                    setLoadingSave(false)
                    onSave && onSave()
                    showNotification({title: "Atualização", message: `Usuário atualizado com sucesso!`})
                })
                .catch(err => {
                    setLoadingSave(false)
                    setError(err.message)
                })
            }else{
                SEND_INVITE({...formData})
                .then(res => {
                    setLoadingSave(false)
                    onSave && onSave()
                    showNotification({title: "Novo Usuário", message: `Foi enviado um convite para o e-mail ${formData?.email}`})
                })
                .catch(err => {
                    setLoadingSave(false)
                    setError(err.message)
                })
            }
        }catch(err){
        setLoadingSave(false)
        showNotification({title: "Ops", message: err.message, color: 'red'})
    }
    }
    
    const COLUMNS = [
        id ? null : {title: "Nome *", name: 'name', type: InputFieldTypes.STRING},
        id ? null : {title: "E-mail *", name: 'email', type: InputFieldTypes.STRING},
        id 
        ? {
            title: "Perfil *",
            name: 'profile',
            fieldType: InputFieldTypes.SELECT,
            value: formData?.profile ?? (formData?.roles ?? [])[0]?.profile?._id,
            options: profile.map(p => ({ label: p.title, value: p._id }))
        }
        : {
            title: "Perfil *",
            name: 'profile',
            fieldType: InputFieldTypes.SELECT,
            options: profile.map(p => ({ label: p.title, value: p._id }))
        },
        {
            title: "Equipes",
            name: 'teams',
            multiple: true,
            fieldType: InputFieldTypes.SELECT,
            options: teams.map(p => ({label: p.title, value: p._id}))
        },
    ].filter(nn => nn)

    React.useEffect(() => {
        loadProfile();
        loadTeams();
    }, [])
    
    React.useEffect(() => {
    }, [formData])

    React.useEffect(() => {
        if(id){
            setLoadingData(true)
            GET_USER_DATA(id)
            .then(({teams, data, email, profile}) => {
                setLoadingData(false)
                setFormData({
                    teams,
                    data,
                    email,
                })
            })
            .catch(err => {
                setLoadingData(false)
                showNotification({title: "Ops", message: err.message, color: 'red'})
            })
        }else{
            setFormData({});
        }
    }, [id]);

    return <ModalForm
        title={id ? "Editar usuário" : "Inserir usuário"}
        columns={COLUMNS}
        loading={loadingSave}
        error={error}
        onChangeData={(dt) => setFormData(fd => ({...fd, ...dt}))}
        formData={formData}
        open={open}
        onConfirm={onConfirm}
        onClose={onClose}
    />
}