import {
  Button,
  Center, Group, Paper, PasswordInput,
  Space, Title
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";
import { useMediaQuery } from "@mantine/hooks";
import { phoneCountryCodes } from "../utility/util";

export default function Invite(){
  const { signIn } = useAuth();
  const {key} = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const [inviteData, setInviteData] = useState<any>(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  React.useEffect(() => {
    if(key){
      api.get(`/auth/invite/${key}`)
      .then(({data}) => {
        setInviteData(data);
      })
      .catch((data) => {
        showNotification({title: "Ops", message: data.message, color: 'red'});
        navigate("/")
      })
    }
  }, [key, navigate])

  return (
    <Center style={{ width: "100vw", height: "100vh", background: `linear-gradient(to top right, #FFFFFF, #FFFFFF, #FFFFFF)` }}>
      <div
        style={{
          width: isMobile ? '90vw' : "40vw",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 80,
        }}
      >
        <div style={{display: 'flex', marginBottom: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <img alt="AprovaAi" src={`/logo.png`}/>
        </div>
        <Paper shadow="xs" p="xl" >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              api.post("/auth/invite/confirm", {
                uuid: key,
                password,
                name,
                phone,
                phone_country_code: phoneCode,
              })
              .then(({data: {user}}) => {
                signIn(user, () => navigate("/", { replace: true }));
              })
              .catch(err => {
                showNotification({title: 'Ops', message: err.message, color: 'red'})
              })
            }}
          >
            {!!inviteData?.user
            ? <>
              <Title order={3}>Aceitar convite para administrar a empresa {inviteData?.invite?.company?.name}?</Title>
            </>
            : <>
              <Title order={3} align="center">Confirmar cadastro</Title>
              <Title order={5} align="center" color="gray" mb="xl">{inviteData?.invite?.email}</Title>
              <InputField
                title="Nome"
                mt="md"
                name='name'
                value={name}
                onChange={({name}) => setName(name)}
                fieldType={InputFieldTypes.STRING}
              />
              <Group mb="lg" style={isMobile ? {display: 'block'} : {}}>
                <InputField
                  style={isMobile ? {} : {flex: 0.3}}
                  title={"País"}
                  name="phone_country_code"
                  onChange={({phone_country_code}) => setPhoneCode(phone_country_code)}
                  autoComplete="tel-country-code"
                  fieldType={InputFieldTypes.SELECT}
                  value={phoneCode ?? "55"}
                  options={phoneCountryCodes}
                />
                <InputField
                  title="Celular"
                  name='phone'
                  onChange={({phone}) => setPhone(phone)}
                  style={{flex: 1}}
                  mask={((phoneCode ?? "55") === "55") ? InputFieldMasks.CUSTOM : undefined}
                  customMask={phone?.replace(/ |\(|\)|\-/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"}
                  value={phone}
                />
              </Group>
              <PasswordInput
                mt="md"
                label="Escolha sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <PasswordInput
                mt="md"
                label="Confirmar senha"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </>}
            <Space h="xl" />
            <Button
              type="submit"
              mt="xl"
              size="md"
              fullWidth
            >Aceitar</Button>
          </form>
        </Paper>
      </div>
    </Center>
  );
};
