import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { FaSnapchatGhost, FaYoutube } from "react-icons/fa";
import {
  FaCameraRetro,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaThreads,
  FaTiktok,
  FaXTwitter
} from 'react-icons/fa6';
import { useLocation } from "react-router-dom";

interface GroupByFuncProps<T> {
    (item: T): any
}
export const groupBy = <T>(
    list: T[],
    func: GroupByFuncProps<T>,
    keyValidator?: string,
) : [any, T[]][] => {
    const map: any[] = [];
    list.forEach((item) => {
        const key = func(item);
        let i = -1;
        map.forEach((mapItem, index) => {
            if (i === -1 && (
            (mapItem[0] === key)
            || (keyValidator && mapItem[0][keyValidator] === key[keyValidator])
            )) i = index;
        });
        if (i === -1) map.push([key, [item]]);
        else map[i][1].push(item);
    });
    return map;
};

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const extenseWeekday = (weekday) => [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
][weekday];

export const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
]

export const spentTime = dt => {
    const months = moment().diff(moment(dt), "months")
    const ds = moment().diff(moment(dt), "days")
    const hours = moment().diff(moment(dt), "hours")
    const minutes = moment().diff(moment(dt), "minutes")
    return months > 0
        ? `${months} meses atrás`
        : ds > 0
            ? `${ds} dias atrás`
            : hours > 0 ? `${hours} horas atrás` : `${minutes} minutos atrás`
}

export function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = React.useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )
  
    React.useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }


export const getEntityProfile = item => ({
    "senador": "Senador",
    "deputado_federal": "Deputado Federal",
    "deputado_distrital": "Deputado Distrital",
    "orgao": "Órgão",
}[item.type] || item.type);

export function lightenColor(hexColor, percentage) {
  // Ensure the hex color is in the format #RRGGBB
  if (hexColor.charAt(0) === '#') {
      hexColor = hexColor.slice(1);
  }

  if (hexColor.length !== 6) {
      throw new Error("Invalid hex color format. Expected #RRGGBB.");
  }

  // Convert hex to RGB
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the lightened color
  r = Math.min(255, Math.floor(r + (255 - r) * (percentage / 100)));
  g = Math.min(255, Math.floor(g + (255 - g) * (percentage / 100)));
  b = Math.min(255, Math.floor(b + (255 - b) * (percentage / 100)));

  // Convert RGB back to hex
  const toHex = (value) => {
      let hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
  };

  let lightenedHex = "#" + toHex(r) + toHex(g) + toHex(b);
  return lightenedHex;
}

export function bestContrast(hexColor) {
  // Ensure the hex color is in the format #RRGGBB
  if (hexColor.charAt(0) === '#') {
      hexColor = hexColor.slice(1);
  }

  if (hexColor.length !== 6) {
    return "#000000"
  }

  // Convert hex to RGB
  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate relative luminance
  // Normalize the RGB values to the range [0, 1]
  r = r / 255;
  g = g / 255;
  b = b / 255;

  // Apply the sRGB formula for luminance
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  // Calculate the luminance
  let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return white or black based on the luminance
  return luminance > 0.179 ? "#000000" : "#FFFFFF";
}

export const parseKeyword = (key) => key ? key
  .normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/[^\w\s]/gi, '')
  .toLowerCase() : "";

export const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);
    const [interacted, setInteracted] = useState(false);
  
    const toggle = () => setPlaying(!playing);
    const play = () => setPlaying(true);
    const pause = () => setPlaying(false);

    const handleInteraction = () => setInteracted(true);

    const setVolume = volume => {
      audio.volume = volume;
    }
  
    useEffect(() => {
      if(interacted){
        audio.currentTime = 0;
        audio.loop = false;
        playing ? audio.play() : audio.pause();
      }
      }, [playing, interacted]
    );
  
    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);

    useEffect(() => {
      document.addEventListener('click', handleInteraction);
      return () => { document.removeEventListener('click', handleInteraction); };
    }, [])
  
    return {playing, play, pause, toggle, setVolume};
};

export function formatMoney(value : any = 0){
  return parseFloat(value).toLocaleString("pt-BR", { currency: "BRL", style: 'currency', })
}

export const formatMilliseconds = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
  const seconds = (totalSeconds % 60).toString().padStart(2, '0');
  return parseInt((totalSeconds / 3600).toFixed(0)) > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

export const getExtenseDatetime = (datetime, time = true) => {
  const extenseDate = moment(datetime).format("YYYY-MM-DD") === moment().subtract(1, "days").format("YYYY-MM-DD")
    ? "Ontem"
    : moment(datetime).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "Hoje"
      : moment(datetime).format("DD/MM/YYYY")
  return `${extenseDate}${time ? `, ${moment(datetime).format("HH:mm")}` : ""}`;
}

export const markdown = (text) => {
  if(!text) return "";
  text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  text = text.replace(/_(.*?)_/g, '<em>$1</em>');
  text = text.replace(/~(.*?)~/g, '<del>$1</del>');
  text = text.replace(/`(.*?)`/g, '<code>$1</code>');
  text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
  text = text.replace(/^\s*-\s(.+)$/gm, '<ul><li>$1</li></ul>');
  text = text.replace(/^\s*\d+\.\s(.+)$/gm, '<ol><li>$1</li></ol>');

  // Handle website links (http/https/www)
  text = text.replace(
    /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+)(?=$|\s)/g,
    (match, before, link) => {
      if (link.startsWith('www.')) {
        link = 'http://' + link;
      }
      return before + `<a href="${link}" target="_blank">${link}</a>`;
    }
  );

  return ReactHtmlParser(text);
};

export const getStatusColor = status => (
  status?.category === "done" ? "green" : status?.category === "in-progress" ? "blue" : "gray"
)

export const getTypeText = (type : string) => ({
  "text": "Texto",
  "video": "Vídeo",
  "audio": "Áudio",
  "image": "Imagem",
}[type] || "Arquivo")


export function formatFileSize(bytes: number): string {
  if (bytes < 1000) {
    return bytes + ' B';
  } else if (bytes < 1000 * 1000) {
    return (bytes / 1000).toFixed(2) + ' KB';
  } else if (bytes < 1000 * 1000 * 1000) {
    return (bytes / (1000 * 1000)).toFixed(2) + ' MB';
  } else {
    return (bytes / (1000 * 1000 * 1000)).toFixed(2) + ' GB';
  }
}

export const ufsList = [
  { label: "Acre", value: "AC" },
	{ label: "Alagoas", value: "AL" },
	{ label: "Amapá", value: "AP" },
	{ label: "Amazonas", value: "AM" },
	{ label: "Bahia", value: "BA" },
	{ label: "Ceará", value: "CE" },
	{ label: "Distrito Federal", value: "DF" },
	{ label: "Espírito Santo", value: "ES" },
	{ label: "Goiás", value: "GO" },
	{ label: "Maranhão", value: "MA" },
	{ label: "Mato Grosso", value: "MT" },
	{ label: "Mato Grosso do Sul", value: "MS" },
	{ label: "Minas Gerais", value: "MG" },
	{ label: "Pará", value: "PA" },
	{ label: "Paraíba", value: "PB" },
	{ label: "Paraná", value: "PR" },
	{ label: "Pernambuco", value: "PE" },
	{ label: "Piauí", value: "PI" },
	{ label: "Rio de Janeiro", value: "RJ" },
	{ label: "Rio Grande do Norte", value: "RN" },
	{ label: "Rio Grande do Sul", value: "RS" },
	{ label: "Rondônia", value: "RO" },
	{ label: "Roraima", value: "RR" },
	{ label: "Santa Catarina", value: "SC" },
	{ label: "São Paulo", value: "SP" },
	{ label: "Sergipe", value: "SE" },
	{ label: "Tocantins", value: "TO" },
]

export const extenseRecurrenceType = (type: string) => ({
  "month": "Mensal",
  "week": "Semanal",
  "year": "Anual",
}[type] || "Mensal")

export const extenseRecurrenceTypeDescription = (type: string, plural = false) => ({
  "month": plural ? "Meses" : "Mês",
  "week": plural ? "Semanas" : "Semana",
  "year": plural ? "Anos" : "Ano",
}[type] || "Mensal")


export const phoneCountryCodes = [
  { "label": "(+93) Afghanistan", "value": "93" },
  { "label": "(+355) Albania", "value": "355" },
  { "label": "(+213) Algeria", "value": "213" },
  { "label": "(+376) Andorra", "value": "376" },
  { "label": "(+244) Angola", "value": "244" },
  { "label": "(+54) Argentina", "value": "54" },
  { "label": "(+374) Armenia", "value": "374" },
  { "label": "(+61) Australia", "value": "61" },
  { "label": "(+43) Austria", "value": "43" },
  { "label": "(+994) Azerbaijan", "value": "994" },
  { "label": "(+973) Bahrain", "value": "973" },
  { "label": "(+880) Bangladesh", "value": "880" },
  { "label": "(+375) Belarus", "value": "375" },
  { "label": "(+32) Belgium", "value": "32" },
  { "label": "(+501) Belize", "value": "501" },
  { "label": "(+229) Benin", "value": "229" },
  { "label": "(+975) Bhutan", "value": "975" },
  { "label": "(+591) Bolivia", "value": "591" },
  { "label": "(+387) Bosnia and Herzegovina", "value": "387" },
  { "label": "(+267) Botswana", "value": "267" },
  { "label": "(+55) Brazil", "value": "55" },
  { "label": "(+673) Brunei", "value": "673" },
  { "label": "(+359) Bulgaria", "value": "359" },
  { "label": "(+226) Burkina Faso", "value": "226" },
  { "label": "(+257) Burundi", "value": "257" },
  { "label": "(+855) Cambodia", "value": "855" },
  { "label": "(+237) Cameroon", "value": "237" },
  { "label": "(+238) Cape Verde", "value": "238" },
  { "label": "(+236) Central African Republic", "value": "236" },
  { "label": "(+235) Chad", "value": "235" },
  { "label": "(+56) Chile", "value": "56" },
  { "label": "(+86) China", "value": "86" },
  { "label": "(+57) Colombia", "value": "57" },
  { "label": "(+269) Comoros", "value": "269" },
  { "label": "(+506) Costa Rica", "value": "506" },
  { "label": "(+225) Côte d'Ivoire", "value": "225" },
  { "label": "(+385) Croatia", "value": "385" },
  { "label": "(+53) Cuba", "value": "53" },
  { "label": "(+357) Cyprus", "value": "357" },
  { "label": "(+420) Czech Republic", "value": "420" },
  { "label": "(+243) Democratic Republic of the Congo", "value": "243" },
  { "label": "(+45) Denmark", "value": "45" },
  { "label": "(+253) Djibouti", "value": "253" },
  { "label": "(+593) Ecuador", "value": "593" },
  { "label": "(+20) Egypt", "value": "20" },
  { "label": "(+503) El Salvador", "value": "503" },
  { "label": "(+240) Equatorial Guinea", "value": "240" },
  { "label": "(+291) Eritrea", "value": "291" },
  { "label": "(+372) Estonia", "value": "372" },
  { "label": "(+251) Ethiopia", "value": "251" },
  { "label": "(+679) Fiji", "value": "679" },
  { "label": "(+358) Finland", "value": "358" },
  { "label": "(+33) France", "value": "33" },
  { "label": "(+241) Gabon", "value": "241" },
  { "label": "(+220) Gambia", "value": "220" },
  { "label": "(+995) Georgia", "value": "995" },
  { "label": "(+49) Germany", "value": "49" },
  { "label": "(+233) Ghana", "value": "233" },
  { "label": "(+30) Greece", "value": "30" },
  { "label": "(+502) Guatemala", "value": "502" },
  { "label": "(+224) Guinea", "value": "224" },
  { "label": "(+245) Guinea-Bissau", "value": "245" },
  { "label": "(+592) Guyana", "value": "592" },
  { "label": "(+509) Haiti", "value": "509" },
  { "label": "(+504) Honduras", "value": "504" },
  { "label": "(+852) Hong Kong", "value": "852" },
  { "label": "(+36) Hungary", "value": "36" },
  { "label": "(+354) Iceland", "value": "354" },
  { "label": "(+91) India", "value": "91" },
  { "label": "(+62) Indonesia", "value": "62" },
  { "label": "(+98) Iran", "value": "98" },
  { "label": "(+964) Iraq", "value": "964" },
  { "label": "(+353) Ireland", "value": "353" },
  { "label": "(+972) Israel", "value": "972" },
  { "label": "(+39) Italy", "value": "39" },
  { "label": "(+81) Japan", "value": "81" },
  { "label": "(+962) Jordan", "value": "962" },
  { "label": "(+254) Kenya", "value": "254" },
  { "label": "(+686) Kiribati", "value": "686" },
  { "label": "(+965) Kuwait", "value": "965" },
  { "label": "(+996) Kyrgyzstan", "value": "996" },
  { "label": "(+856) Laos", "value": "856" },
  { "label": "(+371) Latvia", "value": "371" },
  { "label": "(+961) Lebanon", "value": "961" },
  { "label": "(+266) Lesotho", "value": "266" },
  { "label": "(+231) Liberia", "value": "231" },
  { "label": "(+218) Libya", "value": "218" },
  { "label": "(+423) Liechtenstein", "value": "423" },
  { "label": "(+370) Lithuania", "value": "370" },
  { "label": "(+352) Luxembourg", "value": "352" },
  { "label": "(+853) Macau", "value": "853" },
  { "label": "(+389) Macedonia", "value": "389" },
  { "label": "(+261) Madagascar", "value": "261" },
  { "label": "(+265) Malawi", "value": "265" },
  { "label": "(+60) Malaysia", "value": "60" },
  { "label": "(+960) Maldives", "value": "960" },
  { "label": "(+223) Mali", "value": "223" },
  { "label": "(+356) Malta", "value": "356" },
  { "label": "(+692) Marshall Islands", "value": "692" },
  { "label": "(+222) Mauritania", "value": "222" },
  { "label": "(+230) Mauritius", "value": "230" },
  { "label": "(+52) Mexico", "value": "52" },
  { "label": "(+691) Micronesia", "value": "691" },
  { "label": "(+373) Moldova", "value": "373" },
  { "label": "(+377) Monaco", "value": "377" },
  { "label": "(+976) Mongolia", "value": "976" },
  { "label": "(+382) Montenegro", "value": "382" },
  { "label": "(+212) Morocco", "value": "212" },
  { "label": "(+258) Mozambique", "value": "258" },
  { "label": "(+95) Myanmar", "value": "95" },
  { "label": "(+264) Namibia", "value": "264" },
  { "label": "(+674) Nauru", "value": "674" },
  { "label": "(+977) Nepal", "value": "977" },
  { "label": "(+31) Netherlands", "value": "31" },
  { "label": "(+64) New Zealand", "value": "64" },
  { "label": "(+505) Nicaragua", "value": "505" },
  { "label": "(+227) Niger", "value": "227" },
  { "label": "(+234) Nigeria", "value": "234" },
  { "label": "(+850) North Korea", "value": "850" },
  { "label": "(+47) Norway", "value": "47" },
  { "label": "(+968) Oman", "value": "968" },
  { "label": "(+92) Pakistan", "value": "92" },
  { "label": "(+680) Palau", "value": "680" },
  { "label": "(+970) Palestine", "value": "970" },
  { "label": "(+507) Panama", "value": "507" },
  { "label": "(+675) Papua New Guinea", "value": "675" },
  { "label": "(+595) Paraguay", "value": "595" },
  { "label": "(+51) Peru", "value": "51" },
  { "label": "(+63) Philippines", "value": "63" },
  { "label": "(+48) Poland", "value": "48" },
  { "label": "(+351) Portugal", "value": "351" },
  { "label": "(+974) Qatar", "value": "974" },
  { "label": "(+242) Republic of the Congo", "value": "242" },
  { "label": "(+40) Romania", "value": "40" },
  { "label": "(+7) Russia", "value": "7" },
  { "label": "(+250) Rwanda", "value": "250" },
  { "label": "(+685) Samoa", "value": "685" },
  { "label": "(+378) San Marino", "value": "378" },
  { "label": "(+239) São Tomé and Príncipe", "value": "239" },
  { "label": "(+966) Saudi Arabia", "value": "966" },
  { "label": "(+221) Senegal", "value": "221" },
  { "label": "(+381) Serbia", "value": "381" },
  { "label": "(+248) Seychelles", "value": "248" },
  { "label": "(+232) Sierra Leone", "value": "232" },
  { "label": "(+65) Singapore", "value": "65" },
  { "label": "(+421) Slovakia", "value": "421" },
  { "label": "(+386) Slovenia", "value": "386" },
  { "label": "(+677) Solomon Islands", "value": "677" },
  { "label": "(+252) Somalia", "value": "252" },
  { "label": "(+27) South Africa", "value": "27" },
  { "label": "(+82) South Korea", "value": "82" },
  { "label": "(+211) South Sudan", "value": "211" },
  { "label": "(+34) Spain", "value": "34" },
  { "label": "(+94) Sri Lanka", "value": "94" },
  { "label": "(+249) Sudan", "value": "249" },
  { "label": "(+597) Suriname", "value": "597" },
  { "label": "(+268) Swaziland", "value": "268" },
  { "label": "(+46) Sweden", "value": "46" },
  { "label": "(+41) Switzerland", "value": "41" },
  { "label": "(+963) Syria", "value": "963" },
  { "label": "(+886) Taiwan", "value": "886" },
  { "label": "(+992) Tajikistan", "value": "992" },
  { "label": "(+255) Tanzania", "value": "255" },
  { "label": "(+66) Thailand", "value": "66" },
  { "label": "(+670) Timor-Leste", "value": "670" },
  { "label": "(+228) Togo", "value": "228" },
  { "label": "(+676) Tonga", "value": "676" },
  { "label": "(+1868) Trinidad and Tobago", "value": "1868" },
  { "label": "(+216) Tunisia", "value": "216" },
  { "label": "(+90) Turkey", "value": "90" },
  { "label": "(+993) Turkmenistan", "value": "993" },
  { "label": "(+688) Tuvalu", "value": "688" },
  { "label": "(+256) Uganda", "value": "256" },
  { "label": "(+380) Ukraine", "value": "380" },
  { "label": "(+971) United Arab Emirates", "value": "971" },
  { "label": "(+44) United Kingdom", "value": "44" },
  { "label": "(+1) United States of America", "value": "1" },
  { "label": "(+598) Uruguay", "value": "598" },
  { "label": "(+998) Uzbekistan", "value": "998" },
  { "label": "(+678) Vanuatu", "value": "678" },
  { "label": "(+379) Vatican City", "value": "379" },
  { "label": "(+58) Venezuela", "value": "58" },
  { "label": "(+84) Vietnam", "value": "84" },
  { "label": "(+967) Yemen", "value": "967" },
  { "label": "(+260) Zambia", "value": "260" },
  { "label": "(+263) Zimbabwe", "value": "263" }
]

export const medias : any[] = [
  {
    Icon: FaInstagram,
    key: "instagram",
    title: "Instagram",
    color: "pink",
    options: [
      {
        key: "reels",
        title: "Reels",
        assets: [
          {
            key: "thumb",
            title: "Thumbnail",
            type: "image",
          },
        ]
      },
      {
        key: "story",
        title: "Story",
        assets: []
      },
      {
        key: "post",
        title: "Post",
        assets: [
          {
            key: "thumb",
            title: "Thumbnail",
            type: "image",
          }
        ]
      },
      {
        key: "carrosel",
        title: "Carousel",
        assets: [
          {
            key: "thumb",
            title: "Thumbnail",
            type: "image",
          }
        ]
      },
    ]
  },
  {
    Icon: FaXTwitter,
    key: "twitter",
    title: "Twitter",
    color: "dark",
    options: [
      {
        key: "tweet",
        title: "Tweet",
        assets: []
      }
    ]
  },
  {
    Icon: FaTiktok,
    key: "tiktok",
    title: "Tiktok",
    color: "dark",
    options: [
      {
        key: "video",
        title: "Vídeo",
        assets: [
          { key: "disable_comment", type: "boolean", title: "Habilitar Comentários", default: true },
          { key: "disable_stitch", type: "boolean", title: "Habilitar Costurar", default: true },
          { key: "disable_duet", type: "boolean", title: "Habilitar Dueto", default: true },
          { key: "privacy_level", type: "options", title: "Privacidade", default: "PUBLIC_TO_EVERYONE", options: [
            { value: "PUBLIC_TO_EVERYONE", label: "Público" },
            { value: "MUTUAL_FOLLOW_FRIENDS", label: "Amigos em comum" },
            { value: "FOLLOWER_OF_CREATOR", label: "Seguidores" },
            { value: "SELF_ONLY", label: "Somente você" },
          ] },
        ]
      },
      {
        key: "post",
        title: "Carousel",
        assets: [
          { key: "disable_comment", type: "boolean", title: "Habilitar Comentários", default: true },
          { key: "disable_stitch", type: "boolean", title: "Habilitar Costurar", default: true },
          { key: "disable_duet", type: "boolean", title: "Habilitar Dueto", default: true },
          { key: "privacy_level", type: "options", title: "Privacidade", default: "PUBLIC_TO_EVERYONE", options: [
            { value: "PUBLIC_TO_EVERYONE", label: "Público" },
            { value: "MUTUAL_FOLLOW_FRIENDS", label: "Amigos em comum" },
            { value: "FOLLOWER_OF_CREATOR", label: "Seguidores" },
            { value: "SELF_ONLY", label: "Somente você" },
          ] },
        ]
      },
    ]
  },
  {
    Icon: FaPinterest,
    key: "pinterest",
    title: "Pinterest",
    color: "red",
    options: [
      {
        key: "pin",
        title: "Post",
        assets: []
      }
    ]
  },
  {
    Icon: FaThreads,
    key: "threads",
    title: "Threads",
    color: "dark",
    options: [
      {
        key: "post",
        title: "Post",
        assets: []
      },
    ],
  },
  {
    Icon: FaFacebook,
    key: "facebook",
    title: "Facebook",
    color: "blue",
    options: [
      // {
      //   key: "reels",
      //   title: "Reels",
      //   assets: []
      // },
      // {
      //   key: "story",
      //   title: "Story",
      //   assets: []
      // },
      {
        key: "post",
        title: "Post",
        assets: []
      }
    ]
  },
  {
    Icon: FaLinkedin,
    key: "linkedin",
    title: "LinkedIn",
    color: "blue",
    options: [
      {
        key: "post",
        title: "Post",
        assets: []
      },
      {
        key: "article",
        title: "Artigo",
        assets: []
      }
    ]
  },
  {
    Icon: FaYoutube,
    key: "youtube",
    title: "Youtube",
    color: "red",
    options: [
      {
        key: "video",
        title: "Video",
        assets: [
          {
            key: "thumbnail",
            title: "Thumbnail",
            type: "image",
          }
        ]
      },
      {
        key: "short",
        title: "Shorts",
        assets: [
          {
            key: "thumbnail",
            title: "Thumbnail",
            type: "image",
          }
        ]
      }
    ]
  },
  {
    key: "kwai",
    title: "Kwai",
    color: "orange",
    Icon: FaCameraRetro,
    options: [
      {
        key: "post",
        title: "Post",
        assets: [
          {
            key: "thumbnail",
            title: "Thumbnail",
            type: "image",
          }
        ]
      },
      {
        key: "story",
        title: "Story",
        assets: []
      }
    ]
  },
  {
    key: "snapchat",
    title: "Snapchat",
    color: "yellow",
    Icon: FaSnapchatGhost,
    options: [
      {
        key: "snap",
        title: "Snap",
        assets: [
          {
            key: "thumbnail",
            title: "Thumbnail",
            type: "image",
          }
        ]
      },
      {
        key: "story",
        title: "Story",
        assets: []
      }
    ]
  }
].sort((a,b) => a.title > b.title ? 1 : -1)

export const getMedia = (key: string) => medias.find(m => m.key === key);

export const getOauthTitle = ({ origin, data: { me } }) => ({
  "tiktok": me.display_name,
  "linkedin": me.username,
  "pinterest": me.name,
  "facebook": me.username,
  "instagram": me.username,
  "twitter": me.username,
  "youtube": me.email,
}[origin] ?? me.name ?? me.username)
