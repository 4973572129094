import { ActionIcon, Box, Button, Grid, Group, Loader, Menu, Modal, Paper, Text, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { FaPhone, FaPlus, FaWhatsapp } from "react-icons/fa";
import QRCode from "react-qr-code";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { GET_ALL_ADMIN_WHATSAPPS, SAVE_WHATSAPP } from "../services/phones";
import { GET_ALL_SCHEDULES, SAVE_SCHEDULE } from "../services/schedules";
import InputField, { InputFieldTypes } from "../components/input_field";

export default function AdminWhatsapps(){
    const [data, setData] = useState<any[]>([]);
    const [loadingSaveSchedule, setLoadingSaveSchedule] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [schedules, setSchedules] = useState<any[]>([]);
    const [updatingSchedule, setUpdatingSchedule] = useState<any>(null);
    
    const { socket } = useApp();
    const { userData } = useAuth();

    const handleChange = dt => setData(u => ({ ...u, ...dt }));
    
    const loadWhatsapps = () => {
        setLoading(true);
        GET_ALL_ADMIN_WHATSAPPS()
        .then((res) => {
            setLoading(false);
            setData(res)
            if(res.some(r => r.instance?.status?.isQr)){
                setTimeout(loadWhatsapps, 10000);
            }
        })
        .catch(err => {
            setLoading(false);
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const loadSchedules = () => {
        GET_ALL_SCHEDULES()
        .then((res) => {
            setSchedules(res)
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => {
        const func = ({ configured, data, whatsapp_id }) => {
            loadWhatsapps();
        }
        socket.on(`whatsapp_updated_${userData?.company?._id}`, func);
        return () => {
            socket.removeAllListeners(`whatsapp_updated_${userData?.company?._id}`);
        };
    }, [socket, userData])

    React.useEffect(() => {
        loadWhatsapps();
        loadSchedules();
    }, [])

    return <>
        <Grid>
            {data.map((wpp : any) => (
                <Grid.Col md={6}>
                    <Paper p="md" shadow="xs" ta="center">
                        <Text size="xl">{wpp.title}</Text>
                        {
                            (!wpp?.configured && wpp?.data?.qrcode)
                            ? <>
                                <Text ta="center" mb="lg" fw="bold" size="md">Leia o qrcode abaixo com sua conta de Whatsapp para conectar sua conta.</Text>
                                <QRCode value={wpp?.data?.qrcode} />
                                {/* <img
                                    style={{width: '100%'}}
                                    src={`${process.env.REACT_APP_SERVER_URL}/wpps/whatsapps/${wpp?._id}/qrcode`} /> */}
                            </>
                            : <Group>
                                <ActionIcon
                                    size="lg"
                                    color={wpp?.configured ? "green" : "red"}
                                    // onClick={() => {
                                        
                                    // }}
                                ><FaWhatsapp /></ActionIcon>
                                <Box style={{flex: 1}}>
                                    <Text ta="center" fw="bold" size="sm">{wpp?.data?.phone}</Text>
                                </Box>
                            </Group>
                        }
                    </Paper>
                </Grid.Col>
            ))}
            <Grid.Col md={12}>
                {[
                    ...schedules,
                    {...(updatingSchedule?._id ? {} : updatingSchedule)}
                ].map(schedule => <Paper p="md" shadow="xs" mb="md">
                    <Group mb="md" grow align="flex-end">
                        <InputField
                            name="period"
                            title="Enviar após"
                            disabled={updatingSchedule?._id !== schedule._id}
                            value={(updatingSchedule?._id === schedule._id ? updatingSchedule : schedule)?.period}
                            onChange={({period}) => setUpdatingSchedule(us => ({...us, period}))}
                        />
                        <InputField
                            name="type_period"
                            value={(updatingSchedule?._id === schedule._id ? updatingSchedule : schedule)?.type_period}
                            onChange={({type_period}) => setUpdatingSchedule(us => ({...us, type_period}))}
                            disabled={updatingSchedule?._id !== schedule._id}
                            fieldType={InputFieldTypes.SELECT}
                            options={[
                                { value: "minutes", label: "minutos" },
                                { value: "hours", label: "horas" },
                                { value: "days", label: "dias" },
                                { value: "weeks", label: "semanas" },
                                { value: "months", label: "meses" },
                                { value: "years", label: "anos" },
                            ]}
                        />
                        <InputField
                            name="user_type"
                            title="Enviar para"
                            value={(updatingSchedule?._id === schedule._id ? updatingSchedule : schedule)?.user_type}
                            onChange={({user_type}) => setUpdatingSchedule(us => ({...us, user_type}))}
                            fieldType={InputFieldTypes.SELECT}
                            disabled={updatingSchedule?._id !== schedule._id}
                            options={[
                                { value: "trial", label: "Trials" },
                                { value: "sub", label: "Assinantes" },
                                { value: "unsub", label: "Cancelados" },
                            ]}
                        />
                    </Group>
                    <InputField
                        name="message"
                        title="Mensagem"
                        mb="md"
                        value={(updatingSchedule?._id === schedule._id ? updatingSchedule : schedule)?.message}
                        onChange={({message}) => setUpdatingSchedule(us => ({...us, message}))}
                        disabled={updatingSchedule?._id !== schedule._id}
                        fieldType={InputFieldTypes.TEXTAREA}
                    />
                    <Menu>
                        <Menu.Target><Button mb="xs" color="white" variant="outline" size="xs" leftIcon={<FaPlus />}>Variável</Button></Menu.Target>
                        <Menu.Dropdown>
                            {
                                [
                                    { value: "User.Name", label: "Nome do usuário" },
                                    { value: "User.FirstName", label: "Primeiro nome do usuário" },
                                    { value: "Company.Name", label: "Nome da agência" },
                                ].map(v => (
                                <Menu.Item onClick={() => {
                                    setUpdatingSchedule(s => ({...s, message: `${s?.message ?? ""}{{${v.value}}}`}))
                                }}>{v.label}</Menu.Item>
                                ))
                            }
                        </Menu.Dropdown>
                    </Menu>
                    <Group position="right">
                        {
                            updatingSchedule?._id === schedule._id
                            ? <Button
                                loading={loadingSaveSchedule}
                                onClick={() => {
                                    setLoadingSaveSchedule(true)
                                    SAVE_SCHEDULE(updatingSchedule)
                                    .then(res => {
                                        loadSchedules();
                                        setLoadingSaveSchedule(false);
                                        setUpdatingSchedule(null);
                                    })
                                    .catch(err => {
                                        setLoadingSaveSchedule(false)
                                        showNotification({ message: err.message, color: "red" })
                                    })
                                }}
                            >{updatingSchedule?._id ? "Salvar" : "Inserir"}</Button>
                            : schedule._id && <Button variant="outline" onClick={() => setUpdatingSchedule(schedule)}>Editar</Button>
                        }
                    </Group>
                </Paper>)}
            </Grid.Col>
        </Grid>
    </>
}
