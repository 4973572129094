import { Badge, Button, Group, Modal, Paper, Text, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { FaExclamationCircle, FaPlus } from 'react-icons/fa';
import InputField, { InputFieldMasks, InputFieldTypes } from '../../components/input_field';
import { SAVE_CUSTOMER } from '../../services/customers';
import { phoneCountryCodes } from '../../utility/util';

export const CustomerFlow = (props) => {
    const { data : dataParent = null, onClose, onSave, opened = false } = props;
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>(null);
    const [importingItems, setImportingItems] = React.useState<any[]>([]);
    
    const isMobile = useMediaQuery('(max-width: 900px)');

    const handleChange = dt => setData(d => ({...d, ...dt}));
    const handleChangeProps = dt => setData(d => ({...d, props: { ...d?.props, ...dt }}));

    const handleSaveItem = (dt) => {
        return new Promise((resolve, reject) => {
            SAVE_CUSTOMER(dt)
            .then((res) => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    const handleSave = async () => {
        const params = { ...data };
        delete params.typeInsert;
        
        try{
            if(data?.typeInsert === 'multiple'){
                setLoadingSave(true)
                await Promise.all(importingItems.map(handleSaveItem))
                setLoadingSave(false);
                onSave && onSave();
            }else{
                setLoadingSave(true)
                await handleSaveItem({ ...params, id: dataParent?._id })
                setLoadingSave(false);
                onSave && onSave();
            }
        }catch(err){
            setLoadingSave(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        }
    }

    useEffect(() => {
        if(opened) setData(d => ({flow: [{ approvers: dataParent?.approvers ?? [{}] }], ...dataParent}))
    }, [opened, dataParent])

    return <>
        <Modal
            opened={!!opened}
            onClose={onClose}
            size="xl"
            title={<Text fw="bold">Fluxo de Aprovação</Text>}
        >
            {
                (data?.flow ?? []).map((item, ci) => <>
                    <Group align='flex-start'>
                        <Text fw="bold" c="oragen" size="md" mb="xs">Nível {ci+1}</Text>
                        <Text>-</Text>
                        <div
                            contentEditable
                            onFocus={() => {if(!item.title){
                                setData(d => ({
                                    ...d,
                                    flow: d.flow.map((a2, bi) => ci === bi ? (
                                        {...a2, title: ""}
                                    ) : a2)
                                })) 
                            }}}
                            onBlur={(e) => {
                                const { innerText } = e.currentTarget;
                                setData(d => ({
                                    ...d,
                                    flow: d.flow.map((a2, bi) => ci === bi ? (
                                        {...a2, title: innerText ? innerText : null}
                                    ) : a2)
                                })) 
                            }}
                        >{item.title ?? "Nomear Nível"}</div>
                    </Group>
                    <Paper p="md" shadow='xs' mb="md">
                        {(item.approvers ?? []).map((approver, cj) => {
                            return <Paper mb="xs" p="sm">
                                <Group grow>
                                    <InputField
                                        name="name"
                                        title="Nome"
                                        value={approver.name}
                                        onChange={({name}) => { setData(d => ({
                                            ...d,
                                            flow: d.flow.map((a2, bi) => ci === bi ? {
                                                ...a2, approvers: a2.approvers.map((b2, bj) => cj === bj ? {...b2, name} : b2)
                                            } : a2)
                                        })) }}
                                    />
                                    <InputField
                                        name="email"
                                        title="E-mail"
                                        value={approver.email}
                                        onChange={({email}) => { setData(d => ({
                                            ...d,
                                            flow: d.flow.map((a2, bi) => ci === bi ? {
                                                ...a2, approvers: a2.approvers.map((b2, bj) => cj === bj ? {...b2, email} : b2)
                                            } : a2)
                                        })) }}
                                    />
                                </Group>
                                <Group mt="md">
                                    <InputField
                                        style={isMobile ? {} : {flex: 0.3}}
                                        title={"País"}
                                        name="phone_country_code"
                                        autoComplete="tel-country-code"
                                        fieldType={InputFieldTypes.SELECT}
                                        value={approver.phone_country_code ?? "55"}
                                        onChange={({phone_country_code}) => { setData(d => ({
                                            ...d,
                                            flow: d.flow.map((a2, bi) => ci === bi ? {
                                                ...a2, approvers: a2.approvers.map((b2, bj) => cj === bj ? {...b2, phone_country_code} : b2)
                                            } : a2)
                                        })) }}
                                        options={phoneCountryCodes}
                                    />
                                    <InputField
                                        name="phone"
                                        title="Whatsapp"
                                        style={{flex: 1}}
                                        value={approver.phone}
                                        mask={
                                            ((approver.phone_country_code ?? "55") === "55")
                                            ? InputFieldMasks.CUSTOM
                                            : undefined
                                        }
                                        customMask={approver?.phone?.replace(/ |\(|\)|\-/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"}
                                        onChange={({phone}) => { setData(d => ({
                                            ...d,
                                            flow: d.flow.map((a2, bi) => ci === bi ? {
                                                ...a2, approvers: a2.approvers.map((b2, bj) => cj === bj ? {...b2, phone} : b2)
                                            } : a2)
                                        })) }}
                                    />
                                </Group>
                                <Group mt="md">
                                    {((approver.phone ?? "").length < 5 && (approver.email ?? "").length < 5) && <Button leftIcon={<FaExclamationCircle />} variant="white" color="red">
                                        O aprovador precisa de pelo menos um e-mail ou telefone
                                    </Button>}
                                    <div style={{flex: 1}}></div>
                                    <UnstyledButton onClick={() => {
                                        setData(d => ({
                                            ...d,
                                            flow: item.approvers.length === 1
                                            ? d.flow.filter((a2, bi) => ci !== bi)
                                            : d.flow.map((a2, bi) => ci === bi ? {
                                                ...a2, approvers: a2.approvers.filter((b2, bj) => cj !== bj)
                                            } : a2)
                                        }))
                                    }}><Badge variant="filled" color="red" size="xs">Remover</Badge></UnstyledButton>
                                </Group>
                            </Paper>
                        })}
                        {
                        <Button
                        variant="outline"
                        size="xs"
                        leftIcon={<FaPlus />}
                        onClick={() => setData(d => ({
                            ...d,
                            flow: d.flow.map((a2, bi) => ci === bi ? {...a2, approvers: [...a2.approvers, {}]} : a2)
                        }))}>Aprovador</Button>}
                        </Paper>
                    <Group>
                    
                    </Group>
                </>)
            }
            {
                <Button
                    mt="md"
                    variant="outline"
                    size="xs"
                    leftIcon={<FaPlus />}
                    onClick={() => setData(d => ({
                        ...d,
                        flow: [...(d?.flow ?? []), {approvers: [{}]}]
                    }))}>Nível</Button>}

            <Group position='right'>
                <Button variant='subtle' disabled={loadingSave} onClick={onClose}>Cancelar</Button>
                <Button variant='filled' loading={loadingSave} onClick={handleSave}>Salvar</Button>
            </Group>
        </Modal>
    </>
}