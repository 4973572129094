import { ActionIcon, Box, Button, Grid, Group, Loader, Modal, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { FaPhone, FaPlus, FaWhatsapp } from "react-icons/fa";
import QRCode from "react-qr-code";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { GET_ALL_WHATSAPPS, SAVE_WHATSAPP } from "../services/phones";

export default function Whatsapps(){
    const [data, setData] = useState<any[]>([]);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [newPhone, setNewPhone] = useState<any>(null);
    const [availableWhatsapps, setAvailableWhatsapps] = useState<any[]>([]);
    
    const { socket } = useApp();
    const { userData } = useAuth();

    const handleChange = dt => setData(u => ({ ...u, ...dt }));
    
    const loadWhatsapps = () => {
        setLoading(true);
        GET_ALL_WHATSAPPS()
        .then((res) => {
            setLoading(false);
            setData(res)
            if(res.some(r => r.instance?.status?.isQr)){
                setTimeout(loadWhatsapps, 10000);
            }
        })
        .catch(err => {
            setLoading(false);
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        SAVE_WHATSAPP({ })
        .then((res) => {
            setLoadingSave(false)
            loadWhatsapps()
            setNewPhone(null);
            showNotification({message: "Siga os passos para conectar sua conta", color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => {
        const func = ({ configured, data, whatsapp_id }) => {
            loadWhatsapps();
        }
        socket.on(`whatsapp_updated_${userData?.company?._id}`, func);
        return () => {
            socket.removeAllListeners(`whatsapp_updated_${userData?.company?._id}`);
        };
    }, [socket, userData])
    React.useEffect(() => { loadWhatsapps() }, [])

    return <>
        {data.length === 0 && <UnstyledButton disabled={loadingSave || loading} style={{width: '100%'}} onClick={() => handleSave()}>
            <Paper p="xl" shadow="xs" style={{ border: '1px solid #DFDFDF', borderRadius: 5, backgroundColor: '#25d366' }}>
                <Group>
                    {loadingSave ? <Loader size={30} /> : <FaWhatsapp color="white" size={30} />}
                    <Box style={{flex: 1}}>
                        <Text size="xl" c="white" fw="bold">Conectar Whatsapp</Text>
                    </Box>
                </Group>
            </Paper>
        </UnstyledButton>}

        {data.map((wpp : any) => (
            <Paper p="xl" shadow="xs" ta="center" style={{backgroundColor: '#25d366'}} mb="xs">
                {
                    (!wpp?.configured && wpp?.data?.qrcode)
                    ? <Group>
                        <QRCode size={200} value={wpp?.data?.qrcode} />
                        <Text style={{flex: 1}} ta="center" c="white" mb="lg" fw="bold" size="xl">Leia o qrcode com sua conta de Whatsapp para conectar.</Text>
                        {/* <img
                            style={{width: '100%'}}
                            src={`${process.env.REACT_APP_SERVER_URL}/wpps/whatsapps/${wpp?._id}/qrcode`} /> */}
                    </Group>
                    : <Group>
                        <FaWhatsapp color="white" size={30} />
                            <Text c="white" fw="bold" size="xl">{wpp?.data?.phone}</Text>
                    </Group>
                }
            </Paper>
        ))}
        
        <Modal
            opened={!!newPhone}
            onClose={() => setNewPhone(null)}
        >
            <Text size="md" ta="center" mb="md">Configurar seu número de whatsapp para envio de notificações</Text>

            <Group style={{justifyContent: "flex-end"}}>
                <Button onClick={handleSave}>Configurar</Button>
            </Group>
        </Modal>
    </>
}
