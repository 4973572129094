import { ActionIcon, Avatar, Button, Group, Menu, Text, Title } from '@mantine/core';
import * as React from 'react';
import { useApp } from '../contexts/app.context';

interface SimpleHeaderProps{
    title : string;
    subtitle? : string;
    right?: any;
    Icon?: any;
    button?: { title: string; onClick?: any, [key: string]: any, variant?: string };
    buttons?: { title: string; onClick?: any, [key: string]: any, variant?: string }[];
}

export default function SimpleHeader(props : SimpleHeaderProps){
    const { configs } = useApp();
    let {Icon, title, subtitle, button, right, buttons} = props;
    buttons = buttons || (button ? [button] : [])

    const btn = (button) => button.action
    ? <ActionIcon
        variant={button.variant? button.variant : 'filled'}
        size="xl"
        ml="md"
        {...button}
    >{button.icon}</ActionIcon>
    : <Button
        variant={button.variant? button.variant : 'filled'}
        size="md"
        ml="md"
        {...button}
    >{button.title}</Button>

    return <Group position="apart" align="flex-start" mt="md" mb="md">
        <Group style={{flex: 1}} mb="xl" align='flex-start'>
            {Icon && <Avatar color="purple"><Icon color="white" size="1.5rem" /></Avatar>}
            <div>
                <Title order={3} color={configs?.contrast}>{title}</Title>
                {subtitle && <Text mt="xs" size={14} color="#EFEFEF">{subtitle}</Text>}
            </div>
        </Group>
        <Group align='flex-start'>
            {right}
            {buttons.map(btn)}
        </Group>
    </Group>
}