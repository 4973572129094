import { Box, Button, Group, Menu, Paper, Text, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import React, { useEffect } from 'react';
import { GET_ALL_COMPANIES } from '../services/admin';
import { UPDATE_COMPANY } from '../services/companies';
import { GET_ALL_PLANS } from '../services/plans';
import InputField, { InputFieldTypes } from '../components/input_field';
import Table from '../components/table';
import { Buffer } from 'buffer';

interface ListProps{}
interface ListHandle{
    refresh: () => void;
}

const List : React.ForwardRefRenderFunction<ListHandle, ListProps> = ({}, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [loadingExport, setLoadingExport] = React.useState(false);
    const [tempActiveUntil, setTempActiveUntil] = React.useState(null);
    const [tempActivePlan, setTempActivePlan] = React.useState(null);
    const [error, setError] = React.useState<any>(null);
    const [data, setData] = React.useState([]);
    const [filters, setFilters] = React.useState({
        status: "active",
        // trial: "no",
        sub: "sub",
    });

    const [plans, setPlans] = React.useState<any[]>([]);
    const shownCompanies = data.filter(d => (
        ( (moment(d.active_until).format() > moment().format() ? "active" : "inactive") === filters.status ) &&
        ( ["all", (d.active_plan && d.payment_methods.length > 0) ? d.active_payment_method ? "sub" : "canceled" : "trial"].includes(filters.sub) )
        // ( ["all", d.active_plan ? "no" : "yes"].includes(filters.trial) )
    ));

    const loadPlans = () => {
        GET_ALL_PLANS()
        .then(res => {
            setPlans(res);
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
        })
    }

    useEffect(loadPlans, []);

    const updateCompany = (id, params, callback = null) => {
        const previousValue = data.filter(c => c._id === id);
        setData(d => d.map(c => c._id === id ? { ...c, ...params } : c))
        UPDATE_COMPANY(id, params)
        .then(() => {
            callback && callback()
        })
        .catch(err => {
            setData(d => d.map(c => c._id === id ? previousValue : c))
            showNotification({message: err.message, color: 'red'});
        })
    }
    
    const COLUMNS = [
        {title: "Brand", key: 'image', render: item => <Box>
            <Group>
                {item.image && <img src={item.image} style={{height: 50, width: 50, objectFit: "contain"}} />}
                {item.icon && <img src={item.icon} style={{height: 50, width: 50, objectFit: "contain"}} />}
            </Group>
            <Group spacing={0}>
                <div style={{height: 25, width: 25, backgroundColor: item.appearance?.primary ?? "#FFFFFF", border: "1px solid #EFEFEF", borderRadius: 10}}></div>
                <div style={{height: 25, width: 25, backgroundColor: item.appearance?.navbar ?? "#FFFFFF", border: "1px solid #EFEFEF", borderRadius: 10}}></div>
            </Group>
        </Box> },
        {title: "Perfil", key: 'profile', render: (item) => <Box>
            <Text size="sm" fw="bold">{item.name}</Text>
            <Text size="sm">{item.email}</Text>
            <Text size="sm">{item.phone}</Text>
            <Text size="sm">{(item.domains ?? [])[0]?.url}</Text>
        </Box>},
        {title: "Dados", key: 'data', render: (item) => <Box>
            <Text size="sm">Usuários: {(item.users || []).length}</Text>
            <Text size="sm">Clientes: {(item.customers || []).length}</Text>
            <Text size="sm">Jobs: {(item.jobs || []).length}</Text>
        </Box>},
        {title: "Plano", key: 'active_plan', render: (item) => <Box>
            <Box>
                <Menu
                    onOpen={() => setTempActivePlan(item?.active_plan?._id)}
                >
                    <Menu.Target>
                        <Tooltip label="Plano Ativo">
                            <Button fullWidth variant="light">
                                {item?.active_plan?.title ?? "Inserir"}
                            </Button>
                        </Tooltip>
                    </Menu.Target>
                    <Menu.Dropdown p="md">
                        <InputField
                            name="plan"
                            fieldType={InputFieldTypes.SELECT}
                            value={tempActivePlan}
                            options={plans.map(p => ({label: p.title, value: p._id}))}
                            onChange={({plan}) => setTempActivePlan(plan)}
                        />
                        <Button onClick={() => {
                            updateCompany(item._id, { active_plan: tempActivePlan }, () => {
                                const pl = plans.find(p => p._id === tempActivePlan);
                                setData(d => d.map(c => c._id === item._id ? { ...c, active_plan: pl } : c))
                            });
                        }} mt="md">Salvar</Button>
                    </Menu.Dropdown>
                </Menu>
            </Box>
            <Box mt="xs">
                <Menu
                    // opened={!!tempActiveUntil}
                    onOpen={() => setTempActiveUntil(item.active_until ?? moment().format())}
                    // onClose={() => setTempActiveUntil(null)}
                >
                    <Menu.Target>
                        <Tooltip label="Plano válido até">
                            <Button fullWidth variant="light">
                                {item.active_until ? moment(item.active_until).format("DD/MM/YYYY") : "Inserir"}
                            </Button>
                        </Tooltip>
                    </Menu.Target>
                    <Menu.Dropdown p="md">
                        <InputField
                            name="date"
                            fieldType={InputFieldTypes.DATE}
                            value={moment(tempActiveUntil ?? "").toDate()}
                            onChange={({date}) => setTempActiveUntil(moment(date).format())}
                        />
                        <Button onClick={() => {
                            updateCompany(item._id, { active_until: tempActiveUntil })
                        }} mt="md">Salvar</Button>
                    </Menu.Dropdown>
                </Menu>
            </Box>
        </Box>},
        // {title: "", key: 'actions', render: (item) => <Group>
        //     <ActionIcon
        //         variant={item.pendent ? "filled" : "light"}
        //         color={item.pendent ? "red" : "gray"}
        //         onClick={() => { updateCompany(item._id, { pendent: !item.pendent }) }}
        //     ><FaTimesCircle /></ActionIcon>
        // </Group>},
    ]

    const loadCompanies = () => {
        setError(null);
        setLoading(true);

        GET_ALL_COMPANIES()
        .then(companies => {
            setLoading(false);
            setData(companies.sort((a,b) => a.createdAt > b.createdAt ? -1 : 1));
        })
        .catch(err => {
            setLoading(false);
            setError(err.message);
            showNotification({message: err.message, color: 'red'});
        })
    }

    const refresh = () => { 
        loadCompanies();
    }

    const handleExport = () => {
        setLoadingExport(true);

        let csvText = [
            [
                "status",
                "name",
                "nickname",
                "email",
                "phone",
                "users",
                "customers",
                "jobs",
                "plan",
                "active_until",
                "domain",
                "signup_date",
            ],
            ...data.map(item => [
                (item.active_plan && item.payment_methods.length > 0) ? item.active_payment_method ? "Ativo" : "Cancelado" : "Trial",
                item.name,
                ((item.users || [])[0]?.name ?? item.name).split(" ")[0],
                item.email,
                `${item.phone_country_code ?? "55"}${item.phone}`.replace(/ |\(|\)|\-|\+|\_/g, ""),
                (item.users || []).length,
                (item.customers || []).length,
                (item.jobs || []).length,
                item.active_plan?.title,
                moment(item.active_until).format("YYYY-MM-DD"),
                (item.domains ?? [])[0] ? `https://${item.domains[0].url}` : "",
                item?.createdAt,
            ])
        ].map(items => items.map(it => `"${(it ?? "").toString().trim()}"`).join(",")).join("\n")
    
        const byteCharacters = Buffer.from(csvText, "utf-8");
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) { byteNumbers[i] = byteCharacters[i]; }
        const byteArray = new Uint8Array(byteNumbers);
    
        const blob = new Blob([byteArray], { type: "application/csv" });
    
        const url = URL.createObjectURL(blob);;
        const a = document.createElement('a');
        a.href = url;
        a.download = `Empresas_Aprova_Ai.csv`;
        a.click();
        URL.revokeObjectURL(url);

        setLoadingExport(false);
    }

    React.useEffect(refresh, []);

    React.useImperativeHandle(ref, () => ({
        refresh: () => { refresh(); }
    }));

    return <>
        <Paper shadow="xs" p="sm" mb="md">
            <Group align='flex-end'>
                <InputField
                    name="status"
                    fieldType={InputFieldTypes.SELECT}
                    title="Status"
                    clearable={false}
                    searchable={false}
                    options={[
                        { label: "Ativos", value: "active" },
                        { label: "Inativos", value: "inactive" },
                    ]}
                    value={filters.status}
                    onChange={({status}) => setFilters(f => ({ ...f, status }))}
                />
                {/* <InputField
                    name="trial"
                    title="Trials"
                    clearable={false}
                    searchable={false}
                    fieldType={InputFieldTypes.SELECT}
                    options={[
                        { label: "Todos", value: "all" },
                        { label: "Trials", value: "yes" },
                        { label: "Não Trials", value: "no" },
                    ]}
                    value={filters.trial}
                    onChange={({trial}) => setFilters(f => ({ ...f, trial }))}
                /> */}
                <InputField
                    name="sub"
                    title="Assinatura"
                    clearable={false}
                    searchable={false}
                    fieldType={InputFieldTypes.SELECT}
                    options={[
                        { label: "Todos", value: "all" },
                        { label: "Ativo", value: "sub" },
                        { label: "Cancelado", value: "canceled" },
                        { label: "Trial", value: "trial" },
                    ]}
                    value={filters.sub}
                    onChange={({sub}) => setFilters(f => ({ ...f, sub }))}
                />
                
                <Button loading={loadingExport} onClick={handleExport}>Exportar</Button>
            </Group>
        </Paper>
        <Paper shadow="xs" p="sm" mb="md">
            <Table 
                data={shownCompanies}
                columns={COLUMNS}
                error={error}
                loading={loading}
                mih={600}
            />
        </Paper>
    </>
}

export default React.forwardRef(List);