import { Button, Grid, Group, Paper } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { SAVE_PLAN, GET_ALL_PLANS, DELETE_PLAN } from "../services/plans";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { useApp } from "../contexts/app.context";
import { showNotification } from "@mantine/notifications";

export default function PlansPage(){
    const [plans, setPlans] = useState<any[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();

    const loadPlans = () => {
        GET_ALL_PLANS()
        .then(res => {
            setPlans(res);
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
        })
    }
    
    const handleSave = (res) => {
        let params = { ...selectedPlan };

        setLoadingSave(true)
        SAVE_PLAN(params)
        .then((res) => {
            loadPlans();
            setSelectedPlan(res)
            setLoadingSave(false);
        })
        .catch(err => {
            showNotification({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }

    useEffect(loadPlans, []);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            <Grid.Col md={12}>
                <Paper shadow="xs" p="md" mb="md">
                    <Group>
                        <InputField
                            name="pl"
                            style={{flex: 1}}
                            onChange={({pl}) => {
                                pl === "new"
                                ? setSelectedPlan({  })
                                : setSelectedPlan(plans.find(p => p._id === pl))
                            }}
                            value={selectedPlan ? selectedPlan?._id || "new" : null}
                            fieldType={InputFieldTypes.SELECT}
                            clearable={false}
                            searchable={false}
                            options={[
                                ...plans.map(p => ({label: p.title, value: p._id})),
                                { value: "new", label: "Adicionar Plano" }
                            ]}
                        />
                        {selectedPlan?._id && <Button color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this plan?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_PLAN(selectedPlan?._id)
                                        .then(() => {
                                            loadPlans();
                                            setSelectedPlan(null);
                                        })
                                        .catch(err => {
                                            showNotification({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        >Deletar Plano</Button>}
                    </Group>
                </Paper>

                {selectedPlan && <>
                    <Paper shadow="xs" p="md" mb="md">
                        <Group grow>
                            <InputField
                                name="recurrence_period"
                                fieldType={InputFieldTypes.NUMBER}
                                onChange={({recurrence_period}) => setSelectedPlan(sp => ({...sp, recurrence_period}))}
                                value={selectedPlan?.recurrence_period ?? 1}
                                title="Período da recorrência"
                                style={{flex: 1}}
                            />
                            <InputField
                                name="recurrence_type"
                                title="Recorrência"
                                value={selectedPlan?.recurrence_type ?? "month"}
                                fieldType={InputFieldTypes.SELECT}
                                placeholder='Period'
                                searchable={false}
                                clearable={false}
                                style={{flex: 1}}
                                onChange={({recurrence_type}) => { setSelectedPlan(sp => ({...sp, recurrence_type})) }}
                                options={[
                                    { value: "month", label: "Mensal" },
                                    // { value: "week", label: "Semanal" },
                                    { value: "year", label: "Anual" },
                                ]}
                            />
                        </Group>

                        <Group grow mt="md">
                            <InputField
                                name="price"
                                mask={InputFieldMasks.MONEY}
                                onChange={({price}) => setSelectedPlan(sp => ({...sp, price}))}
                                value={selectedPlan?.price}
                                title="Valor Total"
                                style={{flex: 1}}
                            />
                            <InputField
                                name="max_installments"
                                fieldType={InputFieldTypes.NUMBER}
                                type="number"
                                onChange={({max_installments}) => setSelectedPlan(sp => ({...sp, max_installments}))}
                                value={selectedPlan?.max_installments}
                                title="Máximo de Parcelas"
                                style={{flex: 1}}
                            />
                        </Group>

                        <Group mt="md">
                            <InputField
                                name="max_customers"
                                fieldType={InputFieldTypes.NUMBER}
                                type="number"
                                onChange={({max_customers}) => setSelectedPlan(sp => ({...sp, max_customers}))}
                                value={selectedPlan?.max_customers}
                                title="Max. Clientes"
                                style={{flex: 1}}
                            />
                            <InputField
                                name="add_on_customer"                                
                                mask={InputFieldMasks.MONEY}
                                onChange={({add_on_customer}) => setSelectedPlan(sp => ({...sp, add_on_customer}))}
                                value={selectedPlan?.add_on_customer}
                                title="Valor Adicional por Cliente"
                                style={{flex: 1}}
                            />
                            {/* <InputField
                                name="max_users"
                                fieldType={InputFieldTypes.NUMBER}
                                onChange={({max_users}) => setSelectedPlan(sp => ({...sp, max_users}))}
                                value={selectedPlan?.max_users}
                                title="Max. Usuários"
                                style={{flex: 1}}
                            /> */}
                        </Group>
                        {/* <InputField
                            name="exceed_price"
                            mt="md"
                            mask={InputFieldMasks.MONEY}
                            onChange={({exceed_price}) => setSelectedPlan(sp => ({...sp, exceed_price}))}
                            value={selectedPlan?.exceed_price}
                            title="Exceed Price"
                        /> */}
                    </Paper>
                    <Paper shadow="xs" p="md">
                        <InputField
                            name="title"
                            mt="md"
                            onChange={({title}) => setSelectedPlan(sp => ({...sp, title}))}
                            value={selectedPlan?.title}
                            title="Nome do Plano"
                        />
                        <InputField
                            name="description"
                            mt="md"
                            fieldType={InputFieldTypes.TEXTAREA}
                            onChange={({description}) => setSelectedPlan(sp => ({...sp, description}))}
                            value={selectedPlan?.description}
                            title="Descrição do Plano"
                        />
                    </Paper>
                    <Button mt="md" loading={loadingSave} onClick={handleSave}>Save</Button>
                </>}
            </Grid.Col>
        </Grid>
    </div>
}
