import {
  ActionIcon,
  AppShell,
  Button,
  Group,
  Menu,
  Text,
  Tooltip,
  UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AgentAvatar from "../components/agent_avatar";
import RolesSelect from "../components/roles_select";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import routes from "../utility/routes";
import { bestContrast } from "../utility/util";

export type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const {
    user: currentUser,
    role,
    hasPermission,
    logout,
    userData,
  } = useAuth();

  const [opened, setOpened] = useState(false);
  const [active, setActive] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1200px)');

  React.useEffect(() => {
    const route = navbarContent.find(
      (route) => route.url === window.location.pathname
    );
    if (route) {
      const findIndex = navbarContent.findIndex((r) => r.url === route.url);

      if (findIndex !== -1) {
        setActive(findIndex);
      }
    }
  }, []);

  const navigate = useNavigate();

  const { startPlan, configs, confirmDialog, agents, openedSidebar, toggleOpenedSidebar } = useApp();

  //Contrução da barra de navegação
  const navbarContent: {
    title: string;
    key: string;
    icon: React.ReactNode;
    url: string;
  }[] = routes
    .filter(
      (route) =>
        route.menu &&
        hasPermission(route) &&
        (!route.condition || route.condition({ user: userData?.user, role }))
    )
    .map(({ title, key, icon, url }) => ({ title, key, icon, url }));

  return (
    <>
      <AppShell
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        fixed
        padding={0}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: isMobile ? "20px 20px" : "20px 100px",
            background: `${configs?.brand}00`,
            borderBottom: `1px solid ${configs?.contrast}33`,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <Group style={{flex: 1}} align="center">
            {isMobile && <Menu>
              <Menu.Target>
                <ActionIcon variant="light"><FaBars /></ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {navbarContent.map((item, index) => <Menu.Item
                    onClick={() => { navigate(item.url); setActive(index) }}
                    color="lime"
                  >{item.title}</Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>}

            <div style={isMobile ? { flex: 1, textAlign: 'center' } : {}}>
              <img alt={configs?.name ?? "AprovaAi"} src={configs?.image ?? `/logo-white.png`} style={{height: 35}}/>
            </div>

            {!isMobile && navbarContent.map((item, index) => <Button
                onClick={() => { navigate(item.url); setActive(index) }}
                color="lime"
                variant={active === index ? "filled" : "light"}
                // style={{ color: `${configs?.contrast}`, border: `1px solid ${configs?.contrast}55` }}
                ml="lg"
              >{item.title}</Button>
            )}
          </Group>
          {
            !userData?.company?.active_pla && role?.profile?.title === "Administrador" &&
            <Button mr="md" variant="filled" color="yellow" onClick={() => startPlan()}>Upgrade</Button>
          }
          <Menu>
            <Menu.Target>
              <Tooltip position="bottom" label={currentUser?.name}>
                <UnstyledButton>
                  <Group>
                    <Text size="sm" weight="bold">
                      <AgentAvatar />
                    </Text>
                  </Group>
                </UnstyledButton>
              </Tooltip>
            </Menu.Target>
            <Menu.Dropdown
              style={{
                minWidth: 300,
                marginLeft: "-10px",
                marginTop: "2px",
              }}
            >
              <RolesSelect />
              <Menu.Divider />
              <Menu.Item color="gray" onClick={() => window.open("https://aprova.ai/privacy-policy/", "_blank")}>Políticas de Privacidade</Menu.Item>
              <Menu.Item color="gray" onClick={logout}>Sair</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
        <div style={{
          // backgroundImage: configs?.navbar === "lime" ? 'url(/header-bg.jpg)' : undefined,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: configs?.brand,
          height: 300
        }}></div>
        <div style={{marginTop: -200, padding: isMobile ? "20px 20px" : "0 100px",}}>
          {children}
        </div>
        <div style={{height: 80}}></div>
      </AppShell>
    </>
  );
};

export default Layout;
