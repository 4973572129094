import { api } from "./api"

export const GET_ALL_SCHEDULES = async () => {
    const {data} = await api.get("/schedules")
    return data.schedules || [];
}

export const DELETE_SCHEDULE = async (id) => {
    const {data} = await api.delete(`/schedules/${id}`)
    return data;
}

export const SAVE_SCHEDULE = async (params : any) => {
    const {data} = await api.post(`/schedules`, params)
    return data;
}
