import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, LoadingOverlay, Menu, Paper, Popover, Progress, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FaEllipsisV, FaExclamationTriangle, FaEye, FaFilter, FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerAvatar from '../components/customer_avatar';
import InputField from '../components/input_field';
import useOauth, { OAUTH } from '../components/oauth/oauth';
import SimpleHeader from '../components/simple_header';
import { CustomerModal } from '../components/customers/customers_modal';
import { CustomerFlow } from '../components/customers/customers_flow';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { DELETE_CUSTOMER, GET_ALL_CUSTOMERS } from '../services/customers';
import { getMedia, getOauthTitle, medias, parseKeyword } from '../utility/util';
import { DELETE_OAUTH } from '../services/oauths';

export default function CustomersPage(){
    const [items, setItems] = React.useState<any[]>([]);
    const [modal, setModal] = React.useState<any>(null);
    const [flow, setFlow] = React.useState<any>(null);
    const [filters, setFilters] = React.useState<any>({
        status: true
    });
    const [loadingItems, setLoadingItems] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState({});
    const theme = useMantineTheme();

    const { connect } = useOauth();

    const app = useApp();
    const { userData } = useAuth();
    const navigate = useNavigate();
    const { customerId } = useParams();

    const loadItems = () => {
        setLoadingItems(true)
        GET_ALL_CUSTOMERS()
        .then(res => {
            setItems(res);
            setLoadingItems(false)
        })
        .catch(err => {
            setLoadingItems(false)
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const disconnectOauth = (oauth) => {
        DELETE_OAUTH(oauth._id)
        .then(() => {
            loadItems();
        })
        .catch(err => {
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const deleteItem = (customerId) => {
        setLoadingDelete(ld => ({...ld, [`${customerId}`]: true}))
        DELETE_CUSTOMER(customerId)
        .then(res => {
            setLoadingDelete(ld => ({...ld, [`${customerId}`]: false}))
            loadItems()
        })
        .catch(err => {
            setLoadingDelete(ld => ({...ld, [`${customerId}`]: false}))
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }

    useEffect(loadItems, []);
    
    useEffect(() => {
        const existent = items.find(i => customerId === i._id);
        if(existent || customerId === "novo") setModal(m => m ? m : customerId === "novo" ? {} : existent);
    }, [items, customerId]);

    return <>
        <SimpleHeader title="Clientes"
            right={<Group>
                <InputField
                    placeholder='Buscar cliente...'
                    name="keyword"
                    size="md"
                    value={filters.keyword}
                    onChange={({keyword}) => setFilters(f => ({ ...f, keyword }))}
                />
                <Menu position='bottom'>
                    <Menu.Target>
                        <Button size="md" variant='white' leftIcon={<FaFilter />}>{
                            filters.status === null ? "Todos" : filters.status ? "Ativos" : "Inativos"
                        }</Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{maxWidth: 300, minWidth: 300}}>
                        <Menu.Item onClick={() => { setFilters(f => ({ ...f, status: null })); }}>Ver Todos</Menu.Item>
                        <Menu.Item onClick={() => { setFilters(f => ({ ...f, status: true })); }}>Ativos</Menu.Item>
                        <Menu.Item onClick={() => { setFilters(f => ({ ...f, status: false })); }}>Inativos</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>}
            buttons={[
                {leftIcon: <FaPlus />, title: "Cliente", onClick: () => { setModal(true)}},
            ]}
        />
        
        <LoadingOverlay visible={loadingItems} />

        <Grid>
            {items.length === 0 && <Grid.Col xs={12} md={12}><Paper p="md"><Text align="center">Nenhum cliente cadastrado</Text></Paper></Grid.Col>}
            {items.filter(i => (
                (filters.status === null || i.active === filters.status) &&
                (parseKeyword(i.name ?? "").includes(parseKeyword(filters.keyword ?? "")))
            )).map((item, i) => {
                const qtToApprove = ((item.jobs || []).filter(j => ["draft", "to-approve"].includes(j.status)).length);
                const qtAdjust = ((item.jobs || []).filter(j => ["adjust"].includes(j.status)).length)
                const qtApproved = ((item.jobs || []).filter(j => ["approved"].includes(j.status)).length)

                const instagram = item.oauths.find(o => o.origin === "instagram");
                return (
                    <Grid.Col xs={12} md={3}>
                        <Paper shadow='xs' p="0">
                            <Box p="md">
                                <Group align='center'>
                                    <div style={{flex: 1}}>
                                        <CustomerAvatar image={item.image} name={item.name} />
                                    </div>
                                    <Menu>
                                        <Menu.Target>
                                            <ActionIcon color="gray"><FaEllipsisV /></ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown style={{minWidth: 140}}>
                                            <Menu.Item
                                                color={"blue"}
                                                icon={<FaEye />}
                                                onClick={() => { navigate(`/jobs?customer=${item._id}`) }}
                                            >Ver Jobs</Menu.Item>
                                            <Menu.Item
                                                color={"green"}
                                                icon={<FaPlus />}
                                                onClick={() => { navigate(`/jobs/novo?customer=${item._id}`) }}
                                            >Novo Job</Menu.Item>

                                            <Menu.Divider />

                                            <Menu.Item
                                                color={"gray"}
                                                icon={<FaPencilAlt />}
                                                onClick={() => { setModal(item) }}
                                            >Editar</Menu.Item>
                                            <Menu.Item
                                                color={"gray"}
                                                icon={<FaPencilAlt />}
                                                onClick={() => { setFlow(item) }}
                                            >Fluxo de Aprovação</Menu.Item>
                                            
                                            <Menu.Divider />

                                            <Menu.Item
                                                color={"red"}
                                                icon={<FaTrash />}
                                                onClick={() => {
                                                    app.confirmDialog(
                                                        {text: "Realmente deseja deletar?"},
                                                        (({confirmed}) => {
                                                            if(confirmed) deleteItem(item._id);
                                                        })
                                                    )
                                                }}
                                            >Inativar</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                                <Divider mt="lg" mb="lg" />
                                <UnstyledButton style={{width: '100%'}} onClick={() => navigate(`/jobs?customer=${item._id}`)}>
                                    <Text color="gray" weight="bold">{(item.jobs || []).length} jobs realizados</Text>
                                    <Progress
                                        size="xl"
                                        sections={[
                                            { label: "Aguardando aprovação", value: (item.jobs || []).length === 0
                                                ? 0
                                                : (qtToApprove * 100) / (item.jobs || []).length, color: "gray",
                                                tooltip: `${qtToApprove} - Aguardando aprovação`},
                                            { label: "Em Ajuste", value: (item.jobs || []).length === 0
                                                ? 0
                                                : (qtAdjust * 100) / (item.jobs || []).length, color: "yellow",
                                                tooltip: `${qtAdjust} - Em Ajuste`},
                                            { label: "Aprovados", value: (item.jobs || []).length === 0
                                                ? 0
                                                : (qtApproved * 100) / (item.jobs || []).length, color: "green",
                                                tooltip: `${qtApproved} - Aprovados`},
                                        ]}
                                    />
                                </UnstyledButton>
                                <Group mt="md" spacing={4}>
                                    {item.active ? <Badge variant="filled" color="green">Ativo</Badge> : <Badge variant="filled" color="red">Inativo</Badge>}
                                    {
                                        !(item.flow ?? []).some(f => (f.approvers ?? []).some(a => a.email || a.phone)) &&
                                        <UnstyledButton
                                            onClick={() => setFlow(item)}>
                                            <Badge
                                                color="yellow"
                                                variant="filled"
                                                leftSection={<FaExclamationTriangle />}
                                            >FLUXO DE APROVAÇÃO</Badge>
                                        </UnstyledButton>
                                    }
                                </Group>
                            </Box>

                            <Group spacing={0} style={{borderTop: '1px solid #DFDFDF'}}>
                                {
                                    item.oauths.sort((a,b) => getMedia(a.origin)?.title > getMedia(b.origin)?.title ? 1 : -1).map((oauth) => {
                                        const media = getMedia(oauth.origin);
                                        const { Icon } = media;
                                        return <Popover>
                                            <Popover.Target>
                                                <ActionIcon
                                                    variant='filled'
                                                    color={media?.color}
                                                    size="lg"
                                                    style={{borderRadius: 0, borderRight: '1px solid #CACACA'}}
                                                >
                                                    <Icon />
                                                </ActionIcon>
                                            </Popover.Target>
                                            <Popover.Dropdown
                                                p="8px 20px"
                                                style={{
                                                    backgroundColor: theme.colors[media.color][8],
                                                }}
                                            >
                                                <Group>
                                                    <Icon color="white" />
                                                    <Box style={{flex: 1}}>
                                                        <Text ta="center" c="white" size="sm">{getOauthTitle(oauth)}</Text>
                                                        {
                                                            <Text ta="center" c="white" size="10px">
                                                                {
                                                                    !oauth.infinite_renew
                                                                    ? `Expira ${moment(oauth?.data?.auth?.full_expires_preview ?? oauth?.data?.auth?.expires_at).format("DD/MM/YYYY")}`
                                                                    : `Conectado`
                                                                }
                                                            </Text>
                                                        }
                                                    </Box>
                                                </Group>
                                                <Button mt="xs" variant='subtle' c="white" fullWidth onClick={() => { disconnectOauth(oauth); }} size="xs">Desconectar</Button>
                                            </Popover.Dropdown>
                                        </Popover>
                                    })
                                }
                                <div style={{flex: 1}}></div>
                                {userData?.user.isAdmin && <>
                                    <Menu>
                                        <Menu.Target>
                                            {/* <Button
                                                size="md"
                                                fullWidth
                                                variant='light'
                                                color="gray"
                                                leftIcon={<FaPlus />}
                                            >Conectar Rede Social</Button> */}
                                            <ActionIcon
                                                size="lg"
                                                variant='light'
                                                color="gray"
                                                style={{borderRadius: 0}}
                                            ><FaPlus /></ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            {
                                                medias
                                                .filter(m => [
                                                    "instagram",
                                                    "twitter",
                                                    "pinterest",
                                                    "facebook",
                                                    "linkedin",
                                                    "youtube",
                                                    "tiktok",
                                                ].includes(m.key))
                                                .map(({ Icon, title, key, color }) => (
                                                    <Menu.Item
                                                        color={color}
                                                        icon={<Icon />}
                                                        onClick={() => {
                                                            connect(key as OAUTH, loadItems, { customer: item._id });
                                                        }}
                                                    >{title}</Menu.Item>
                                                ))
                                            }
                                        </Menu.Dropdown>
                                    </Menu>
                                </>}
                            </Group>
                        </Paper>
                    </Grid.Col>
                )
            })}
        </Grid>

        <CustomerModal
            data={modal}
            opened={!!modal}
            onClose={() => setModal(null)}
            onSave={(res) => { loadItems(); navigate("/clientes"); setFlow(!modal?._id ? res : null); setModal(null); }}
        />
        
        <CustomerFlow
            data={flow}
            opened={!!flow}
            onClose={() => setFlow(null)}
            onSave={() => { loadItems(); navigate("/clientes"); setFlow(null) }}
        />
    </>
}
