import { api } from "./api"

export const GET_ALL_WHATSAPPS = async () => {
    const {data} = await api.get(`/phones/whatsapps`)
    return data.whatsapps || [];
}
export const GET_ALL_WHATSAPP_GROUPS = async (wppId: string) => {
    const {data} = await api.get(`/phones/whatsapps/${wppId}/groups`)
    return data ?? [];
}
export const GET_ALL_ADMIN_WHATSAPPS = async () => {
    const {data} = await api.get(`/phones/admin/whatsapps`)
    return data.whatsapps || [];
}
export const SAVE_WHATSAPP = async (params) => {
    const {data} = await api.post(`/phones/whatsapps`, params)
    return data.item;
}
export const DELETE_WHATSAPP = async (whatsappId) => {
    const {data} = await api.delete(`/phones/whatsapps/${whatsappId}`)
    return data;
}