import { ActionIcon, Menu } from '@mantine/core';
import { default as React } from 'react';
import { FaEllipsisV } from 'react-icons/fa';

interface ActionProps{
    leftIcon?: any;
    onClick:any;
    title?:any;
    color?:string;
    loading?: ( item: any ) => boolean;
    disabled?: ( item: any ) => boolean;
}

const ActionsRender = ({ actions = [], item, index }: { actions: ActionProps[]; item?: any; index?: any }) => {
    return actions.length > 0 && 
        <Menu>
            <Menu.Target>
                <ActionIcon variant="light"><FaEllipsisV /></ActionIcon>
            </Menu.Target>
            <Menu.Dropdown style={{minWidth: 140}}>
                {actions.map((action, index) => <Menu.Item
                    key={index}
                    color={action.color || "lime"}
                    icon={action.leftIcon}
                    disabled={(
                        (action.loading ? action.loading(item) : false)
                        ||
                        (action.disabled ? action.disabled(item) : false)
                    )}
                    onClick={() => {action.onClick && action.onClick({item, index})}}
                >{action.title}</Menu.Item>)}
            </Menu.Dropdown>
        </Menu>
}

export { ActionProps, ActionsRender };