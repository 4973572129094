import { UnstyledButton } from '@mantine/core';
import React, { useRef, useState } from 'react';

const useFilePicker = () => {
  const [funcs, setFuncs] = useState<any>(null);
  const fileInputRef = useRef<any>(null);

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const selectedFiles = Array.from(event.target.files);
    funcs.onSelect && funcs.onSelect(selectedFiles);
  };

  const Component = (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelected}
        multiple
      />
    </>
  );
  return {
    pickFiles: ({ onSelect }) => {
      setFuncs({ onSelect })
      openFilePicker();
    },
    Component,
  }
  
};

export default useFilePicker;
