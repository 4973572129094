import { api } from "./api"

export const GET_ALL_OAUTHS = async () => {
    const {data} = await api.get("/oauths")
    return data.oauths || [];
}

export const DELETE_OAUTH = async (id) => {
    const {data} = await api.delete(`/oauths/${id}`)
    return data;
}

export const SAVE_OAUTH = async (params : any) => {
    const {data} = await api.post(`/oauths`, params)
    return data;
}
