import {
    ActionIcon,
    Box,
    Button,
    Grid, Group,
    Loader,
    Paper,
    Text,
    Title, Tooltip, UnstyledButton
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { FaCheck, FaCheckCircle, FaFacebook, FaLinkedin, FaMicrochip, FaPinterest, FaPlus, FaReddit, FaTimesCircle, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import { useMediaQuery } from "@mantine/hooks";
import InputField, { InputFieldTypes } from "../components/input_field";
import { CONSULT_LABEL_AVAILABILITY, GET_COMPANY_ONBOARDING_DATA, SAVE_COMPANY_ONBOARDING_DATA } from "../services/companies";
import { showNotification } from "@mantine/notifications";
import { useApp } from "../contexts/app.context";
import { CustomerModal } from "../components/customers/customers_modal";
import { CustomerFlow } from "../components/customers/customers_flow";
import { GET_ALL_WHATSAPPS, SAVE_WHATSAPP } from "../services/phones";
import QRCode from "react-qr-code";
import Whatsapps from "./whatsapp";
import Zapier from "./zapier";
import GoogleDrive from "../components/integrations/google_drive";
import Canva from "../components/integrations/canva";
  
  export default function Signup({ customer = false, shareLinkId = null, partner = false }){
    const { userData, role, companyHost } = useAuth();
    const { configs, setConfigs, socket } = useApp();

    const navigate = useNavigate();
    const topRef = useRef<any>();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSaveWhatsapp, setLoadingSaveWhatsapp] = useState<boolean>(false);
    const [steps, setSteps] = useState<any[]>([]);
    const [whatsapps, setWhatsapps] = useState<any[]>([]);
    const [data, setData] = useState<any>({});
    const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null);
    const [selectedFlow, setSelectedFlow] = useState<number | null>(null);
    const [tempPrimary, setTempPrimary] = useState<string>(null);
    const [tempNavbar, setTempNavbar] = useState<string>(null);
    const [consultingLabel, setConsultingLabel] = useState<boolean>(false);
    const [validLabel, setValidLabel] = useState<boolean>(false);
    
    const [loadingSuggestion, setLoadingSuggestion] = useState<any>({});
  
    const handleChangeWhitelabel = (dt) => setData(c => ({ ...c, whitelabel: {...c.whitelabel, ...dt }}))
  
    const navigateToTab = (index, save = false) => {
        const cb = () => {
            if(index === steps.length) navigate("/");
            else setSelectedTab(index);
        }
        const key = steps[index - 1]?.key;
        if(save && key){
            setLoading(true)
            SAVE_COMPANY_ONBOARDING_DATA({ key, data })
            .then(() => {
                setLoading(false);
                cb();
            })
            .catch((err) => {
                showNotification({ message: err.message, color: "red" })
                setLoading(false)
            })
        }else{
            cb();
        }
    }

    const handleConnectWhatsapp = () => {
        setLoadingSaveWhatsapp(true)
        SAVE_WHATSAPP({ })
        .then((res) => {
            setLoadingSaveWhatsapp(false)
            loadWhatsapps()
            showNotification({message: "Siga os passos para conectar sua conta", color: 'green'})
        })
        .catch(err => {
            setLoadingSaveWhatsapp(false)
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const WelcomeStep = <Box>
        <Title order={3} mt="md">Bem-vindo ao Aprova.ai!</Title>
        <Title order={5} mt="md" c="gray">Parabéns por se inscrever com sucesso! Estamos entusiasmados por tê-lo a bordo. Sua inscrição inicial está completa, e agora é hora de personalizar sua experiência.</Title>
        <Title order={3} mt="md">Começar os Primeiros Passos ou Pular por Enquanto?</Title>
        <Title order={5} mt="md" c="gray">Você está a apenas um passo de começar com o Aprova.ai. Para adaptar sua experiência e garantir que tudo esteja configurado conforme suas preferências, você pode iniciar o processo de integração agora. Vamos guiá-lo pelos passos essenciais para personalizar sua conta.</Title>
        <Title order={5} mt="md" c="gray">Ainda não está pronto para personalizar? Sem problemas! Você pode pular isso por enquanto e voltar a essa etapa mais tarde nas configurações do seu painel.</Title>
    </Box>;
  
    const WhitelabelStep = <Box>
        <Title order={3} mb="md">Nome da Empresa</Title>
        <Title order={5} mb="md" c="gray">Confirme o nome da sua agência abaixo. Este nome será exibido em toda a plataforma.</Title>

        <InputField
            name="name"
            mb="md"
            value={data?.whitelabel?.name}
            onChange={handleChangeWhitelabel}
        />


        <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row'}}>
            <Box style={{flex: 1,}} pr={isMobile ? undefined : "md"}>
                <Title order={4}>Cor do Cabeçalho</Title>
                <Text c="gray" size="md">Escolha a cor a ser utilizada no cabeçalho da plataforma.</Text>
                <InputField
                    name="navbar"
                    mb="md"
                    fieldType={InputFieldTypes.COLOR}
                    value={data?.whitelabel?.navbar ?? configs?.navbar}
                    onBlur={({ }) => {
                        handleChangeWhitelabel({ navbar: tempNavbar });
                        setConfigs({ navbar: tempNavbar });
                    }}
                    onChange={({ navbar }) => setTempNavbar(navbar)}
                />
            </Box>
            <Box style={{flex: 1,}} pr={isMobile ? undefined : "md"}>
                <Title order={4}>Altere sua cor primária</Title>
                <Text c="gray" size="md">Escolha sua cor primaria que vai ser utilizada na plataforma.</Text>
                <InputField
                    name="primary"
                    mb="md"
                    fieldType={InputFieldTypes.COLOR}
                    value={tempPrimary ?? data?.whitelabel?.primary ?? configs?.primary}
                    onBlur={({ }) => {
                        handleChangeWhitelabel({ primary: tempPrimary });
                        setConfigs({ primary: tempPrimary });
                    }}
                    onChange={({ primary }) => setTempPrimary(primary)}
                />
            </Box>
        </div>
        
        <Text c="gray" mb="md" size="sm">Nota: As cores que você selecionar serão destacadas em diversos elementos no painel da sua agência.</Text>

        <Box mb="md">
            <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row'}}>
                <Box style={{flex: 1,}} pr={isMobile ? undefined : "md"}>
                    <InputField
                        name="image"
                        mb="md"
                        title="Insira a Logo"
                        fieldType={InputFieldTypes.IMAGE}
                        value={data?.whitelabel?.image}
                        onChange={({ image }) => {
                            handleChangeWhitelabel({ image });
                            setConfigs({ image });
                        }}
                    />
                </Box>
                <Box style={{flex: 1}} pl={isMobile ? undefined : "md"}>
                    <InputField
                        name="icon"
                        mb="md"
                        title="Insira o ícone"
                        fieldType={InputFieldTypes.IMAGE}
                        value={data?.whitelabel?.icon}
                        onChange={({ icon }) => {
                            handleChangeWhitelabel({ icon });
                            setConfigs({ icon });
                        }}
                    />
                </Box>
            </div>
        </Box>
        
        <Title order={3} mb="md">URL da Empresa (Subdomínio)</Title>
        <Title order={5} mb="md" c="gray">Escolha um subdomínio único para a presença online da sua agência dentro do Aprova.ai. Este subdomínio será usado para acessar o painel personalizado da sua agência.</Title>

        <Title order={5} mb="md" c="gray">Exemplo: Insira um subdomínio preferido para sua agência. Por exemplo, se o nome da sua agência é "MinhaAgencia", você pode usar "minha-agencia.aprova.ai" como sua URL.</Title>

        <InputField
            name="label"
            mb="md"
            value={data?.whitelabel?.label}
            leftSection={
                consultingLabel
                ? <Loader size="xs" />
                : validLabel ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />
            }
            error={!validLabel && "Esse domínio já está em uso"}
            rightSection={<Text style={{marginLeft: -120}}>.aprova.ai</Text>}
            onChange={({label}) => {
                label = label.replace(/ |\.|\[|\]|\(|\)|\@|\!|\*|\,/g, "")
                handleChangeWhitelabel({ label });
                
                if(label?.length >= 3){
                    setConsultingLabel(true);
                    CONSULT_LABEL_AVAILABILITY(label)
                    .then(({available}) => {
                        setValidLabel(available);
                        setConsultingLabel(false);
                    })
                    .catch(err => {
                        setValidLabel(false)
                        setConsultingLabel(false);
                    })
                }else{
                    setValidLabel(false);
                }
            }}
        />
        <Text c="gray">Nota: O subdomínio escolhido fará parte da URL da sua agência dentro da nossa plataforma.</Text>
    </Box>;

    const CustomersStep = <Box>
        <Box mt="md">
            <Title order={3} mb="md">Cadastre seus clientes</Title>
            <Title order={5} mt="md" c="gray">Personalize a experiência dos seus clientes, assim eles receberão notificações em todas etapas de seus jobs.</Title>

            {[{},{},{}].map((it, i) => (
                <UnstyledButton
                    style={{width: '100%'}}
                    onClick={() => {
                        setSelectedCustomer((data?.customers ?? [])[i] ? i : (data?.customers ?? []).length);
                    }}
                >
                    <Paper mt="md" p="md" style={{border: '1px solid #DFDFDF', background: selectedCustomer === i ? "#EFEFEF" : '#FDFDFD'}}>
                        <Group align="center">
                            <Text style={{flex: 1}}>{(data?.customers ?? [])[i]?.name ?? "Cadastrar novo"}</Text>
                            {!(data?.customers ?? [])[i] && <FaPlus size={20} />}
                        </Group>
                    </Paper>
                </UnstyledButton>
            ))}
        </Box>
    </Box>;
    
    const IntegrationsStep = <Box>
        <Box mt="md">
            <Title order={3} mb="md">Conecte Suas Plataformas</Title>
            <Title order={5} mt="md" c="gray">Personalize sua experiência conectando suas plataformas diretamente à Aprova.ai e automatize ações.</Title>

            <Box mt="md">
                <Whatsapps />
            </Box>
            <Box mt="md">
                <GoogleDrive />
            </Box>
            <Box mt="md">
                <Canva />
            </Box>
            <Box mt="md">
                <Zapier />
            </Box>
        </Box>
    </Box>;

    const ShareStep = <Box>
        <Title ta="center" order={3} mt="md">Primeiros Passos Concluídos!</Title>
        <Title ta="center" order={5} c="gray" mt="md">Agora, tudo o que você precisa fazer é cadastrar seus jobs para seus clientes aprovarem. E lembre-se de que, sempre que precisar, basta ir ao menu Configurações no lado superior do painel para gerenciar todos os seus produtos e informações.</Title>
        
        {/* <Title ta="center" order={5} c="gray" mt="xl">Esse é o link whitelabel da sua plataforma:</Title>
        
        <UnstyledButton style={{width: '100%'}} onClick={() => {
            window.open(`https://${data?.whitelabel?.label}.aprova.ai`, "_blank")
        }}>
            <Text ta="center" c="lime" size="md" mt="xs" style={{textDecoration: 'underline'}}>https://{data?.whitelabel?.label}.aprova.ai</Text>
        </UnstyledButton> */}
    </Box>;
  
    const activeForm = [
      WelcomeStep,
      WhitelabelStep,
      IntegrationsStep,
      CustomersStep,
      ShareStep,
    ].filter(nn => nn)[selectedTab];

    const loadData = () => {
        GET_COMPANY_ONBOARDING_DATA()
        .then(res => {
            setValidLabel(true);
            setData(res);
        })
        .catch(err => {
            showNotification({title: "Ops", message: err.message, color: 'red'})
        })
    }
  
    useEffect(() => {
        setSteps([
          {title: "Bem vindo", subtitle: ""},
          {title: "Whitelabel", key: "whitelabel", subtitle: ""},
          {title: "Integrações", subtitle: ""},
          {title: "Seus Clientes", key: "customers", subtitle: ""},
          {title: "Compartilhe", subtitle: ""},
        ])
    }, [customer])

    useEffect(() => {
        loadData()
    }, [])
    
    useEffect(() => {
        topRef.current && topRef.current.scrollIntoView({ behavior: "smooth" });
    }, [selectedTab])

    const loadWhatsapps = () => {
        setLoading(true);
        GET_ALL_WHATSAPPS()
        .then((res) => {
            setLoading(false);
            setWhatsapps(res)
            if(res.some(r => r.instance?.status?.isQr)){
                setTimeout(loadWhatsapps, 10000);
            }
        })
        .catch(err => {
            setLoading(false);
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => {
        const func = ({ configured, data, whatsapp_id }) => {
            loadWhatsapps();
        }
        socket.on(`whatsapp_updated_${userData?.company?._id}`, func);
        return () => {
            socket.removeAllListeners(`whatsapp_updated_${userData?.company?._id}`);
        };
    }, [socket, userData]);

    React.useEffect(() => { loadWhatsapps() }, [])
  
    if(!role) return null;
    return role?.profile?.title === "Client"
    ? <Navigate to="/" />
    : <>
        <div ref={topRef}></div>
        <Grid style={{}} gutter={0}>
            <Grid.Col xs={12} md={4} style={{padding: 0}}>
                <div style={{
                    padding: isMobile ? 0 : "40px 40px",
                    boxShadow: "0px 4px 4px #CACACA77",
                    minHeight: isMobile ? 'auto' : 'calc(100vh + 8px)',
                    overflowY: 'auto',
                    background: configs.navbar,
                }}>
                <div style={{display: 'flex', margin: '40px 0' ,flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <img alt="AprovaAi" style={{height: 80, width: "60%", objectFit: 'contain'}} src={configs.image ?? "/logo.png"}/>
                </div>
                {!isMobile && <div>
                    {steps.map((step, index) => (
                    <UnstyledButton
                        disabled={selectedTab > index ? false : (true)}
                        onClick={() => navigateToTab(index)} style={{width: '100%'}}
                    >
                        <Group mt="md" mb="md">
                        <ActionIcon size="xl" color={selectedTab > index ? "green" : "lime"} variant={selectedTab > index ? "filled" : selectedTab === index ? "filled" : "light"}>
                            {selectedTab > index
                            ? <FaCheck color={"white"} size={14} />
                            : <Title order={4} color={selectedTab >= index ? "white" : "lime"}>{index + 1}</Title>}
                        </ActionIcon>
                        <Box>
                            <Title c="white" order={5}>{step.title}</Title>
                            <Text c="gray">{step.subtitle}</Text>
                        </Box>
                        </Group>
                    </UnstyledButton>
                    ))}
                </div>}
                </div>
            </Grid.Col>
            <Grid.Col md={8}>
                <div style={{padding: isMobile ? '40px' : '80px 140px', height: isMobile ? 'auto' : 'calc(100vh + 8px)', overflowY: 'auto'}}>
                    <form onSubmit={e => {e.preventDefault(); navigateToTab(selectedTab+1, true) }}>
                        {activeForm}
                        <Group position="right" mt="xl">
                            {selectedTab+1 < steps.length && <Button variant="transparent" onClick={() => navigate("/")}>Pular Primeiros Passos</Button>}

                            <Button
                            className='draw-button'
                            type="submit"
                            loading={loading}
                            color="lime"
                            size="md">{selectedTab+1 === steps.length ? "Acessar Dashboard" : "Continuar"}</Button>
                        </Group>
                    </form>
                </div>
            </Grid.Col>
        </Grid>

        <CustomerModal
            data={(data?.customers ?? [])[selectedCustomer]}
            opened={selectedCustomer !== null}
            onClose={() => setSelectedCustomer(null)}
            onSave={(res) => { loadData(); setSelectedFlow(selectedCustomer); setSelectedCustomer(null); }}
        />
        
        <CustomerFlow
            data={(data?.customers ?? [])[selectedFlow]}
            opened={selectedFlow !== null}
            onClose={() => setSelectedFlow(null)}
            onSave={() => { loadData(); setSelectedFlow(null) }}
        />
    </>
};
  