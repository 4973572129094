import { api } from "./api"

export const GET_ALL_TEAMS = async () => {
    const {data} = await api.get("/teams")
    return data.teams || [];
}


export const GET_TEAM_DATA = async () => {
    const {data} = await api.get("/teams/data")
    return data;
}

export const DELETE_TEAM = async (id) => {
    const {data} = await api.delete(`/teams/${id}`)
    return data;
}

export const CREATE_TEAM = async (params : any) => {
    const {data} = await api.post(`/teams`, params)
    return data;
}

export const UPDATE_TEAM = async (teamId: string, params : any) => {
    const {data} = await api.patch(`/teams/${teamId}`, params)
    return data;
}

export const GET_ALL_TEAMS_USER = async () => {
    const {data} = await api.get("/teams/user")
    return data.teams || [];
}

