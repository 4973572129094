import { ActionIcon, Button, Card, Checkbox, Divider, Grid, Group, Loader, LoadingOverlay, Paper, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import InputField, { InputFieldTypes } from '../components/input_field';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_COMPANY_PREFERENCES, UPDATE_COMPANY_PREFERENCES } from '../services/companies';
import { GET_USER_DATA, UPDATE_USER } from '../services/users';

export default function PreferencesPage(){
    const { user: currentUser, role } = useAuth();
    const app = useApp();

    const [userPreferences, setUserPreferences] = useState<any>({});
    const [companyPreferences, setPreferences] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const statusChange = { ...userPreferences?.jobs?.status_change };

    const loadPreferences = () => {
        setLoading(true);
        GET_COMPANY_PREFERENCES()
        .then(({company, user}) => {
            setLoading(false);
            setPreferences(company);
            setUserPreferences(user);
        })
        .catch(err => {
            setLoading(false);
            showNotification({message: err.message, color: 'red'})
        })
    }

    const handleSave = () => {
        setLoadingSave(true);
        UPDATE_COMPANY_PREFERENCES({ company: companyPreferences, user: userPreferences })
        .then(() => {
            setLoadingSave(false);
        })
        .catch(err => {
            setLoadingSave(false);
            showNotification({message: err.message, color: 'red'})
        })
    }

    const handleStatusChange = (dt) => {
        setUserPreferences(p => ({
            ...p,
            jobs: {
                ...p?.jobs,
                status_change: {
                    ...p?.jobs?.status_change,
                    ...dt,
                }
            }
        }))
    }

    useEffect(loadPreferences, [])

    return <>
        <LoadingOverlay visible={loading} />
        <Paper p="md" shadow='xs'>
            <Title order={4}>Notificações Pessoais</Title>

            <Grid pt="md" pb="md">
                <Grid.Col xs={12} md={6}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Alteração de status do job</Title>
                            <ActionIcon color="yellow" onClick={() => app.expandText("Notificações enviadas para o seu usuário assim que o job é aprovado ou solicitado ajuste")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <Checkbox mb="md" label="E-mail" checked={statusChange?.email ?? true} onChange={(e) => {
                            handleStatusChange({ email: e.target.checked })
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={statusChange?.whatsapp ?? true} onChange={(e) => {
                            handleStatusChange({ whatsapp: e.target.checked })
                        }} />
                        <Divider mt="md" mb="md" />
                        <InputField
                            title="Notificar Somente Esses Status"
                            name="types"
                            multiple
                            fieldType={InputFieldTypes.SELECT}
                            value={(statusChange?.types ?? ["adjust", "approved"])}
                            onChange={handleStatusChange}
                            options={[
                                { label: "Em Ajuste", value: "adjust" },
                                { label: "Aprovado", value: "approved" },
                            ]}
                        />
                    </Card>
                </Grid.Col>
            </Grid>

            <Title mt="md" order={4}>Notificações aos Clientes</Title>

            <Grid pt="md" pb="md">
                <Grid.Col xs={12} md={6}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Solicitação de aprovação</Title>
                            <ActionIcon color="yellow" onClick={() => app.expandText("Notificações enviadas ao cliente assim que enviado para aprovação")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <Checkbox mb="md" label="E-mail" checked={companyPreferences?.notifications?.approve_request?.email} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_request: {...p?.notifications?.approve_request, email: e.target.checked}}}))
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={companyPreferences?.notifications?.approve_request?.whatsapp} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_request: {...p?.notifications?.approve_request, whatsapp: e.target.checked}}}))
                        }} />
                    </Card>
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Solicitação de ajuste</Title>
                            <ActionIcon color="yellow" onClick={() => app.expandText("Notificações enviadas aos usuários assim que o cliente solicita ajuste")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <Checkbox mb="md" label="E-mail" checked={companyPreferences?.notifications?.adjust_request?.email} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, adjust_request: {...p?.notifications?.adjust_request, email: e.target.checked}}}))
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={companyPreferences?.notifications?.adjust_request?.whatsapp} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, adjust_request: {...p?.notifications?.adjust_request, whatsapp: e.target.checked}}}))
                        }} />
                    </Card>
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <Card shadow='xs'>
                        <Group>
                            <Title style={{flex: 1}} order={5}>Lembretes de aprovação</Title>
                            <ActionIcon color="yellow" onClick={() => app.expandText("Lembrete enviado ao cliente dias antes da deadline")}><FaExclamationCircle /></ActionIcon>
                        </Group>
                        <Divider mt="md" mb="md" />
                        <InputField
                            name="n"
                            title="Dias antes da deadline"
                            type="number"
                            mb="md"
                            value={companyPreferences?.notifications?.approve_remember?.days_before}
                            onChange={({n}) => {
                                setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, days_before: n}}}))
                            }}
                        />
                        <Checkbox mb="md" label="E-mail" checked={companyPreferences?.notifications?.approve_remember?.email} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, email: e.target.checked}}}))
                        }} />
                        <Checkbox mb="md" label="Whatsapp" checked={companyPreferences?.notifications?.approve_remember?.whatsapp} onChange={(e) => {
                            setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, whatsapp: e.target.checked}}}))
                        }} />
                        <Divider mt="md" mb="md" />
                        <InputField
                            title="Notificar Somente Esses Dias"
                            name="weekdays"
                            multiple
                            fieldType={InputFieldTypes.SELECT}
                            value={(companyPreferences?.notifications?.approve_remember?.weekdays ?? ["0", "1", "2", "3", "4", "5", "6"])}
                            onChange={({weekdays}) => {
                                setPreferences(p => ({...p, notifications: {...p?.notifications, approve_remember: {...p?.notifications?.approve_remember, weekdays}}}))
                            }}
                            options={[
                                { value: "0", label: "Domingo"},
                                { value: "1", label: "Segunda"},
                                { value: "2", label: "Terça"},
                                { value: "3", label: "Quarta"},
                                { value: "4", label: "Quinta"},
                                { value: "5", label: "Sexta"},
                                { value: "6", label: "Sábado"},
                            ]}
                        />
                    </Card>
                </Grid.Col>
            </Grid>

            <Group position='right'>
                <Button onClick={handleSave} loading={loadingSave}>Salvar</Button>
            </Group>
        </Paper>
    </>
}
