import { ActionIcon, Avatar, Box, Button, Group, Loader, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../services/companies";
import InputField, { InputFieldTypes } from "../components/input_field";
import { FaCopy } from "react-icons/fa";

export default function Zapier(){
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const clipboard = useClipboard();

    // const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        GET_COMPANY_INTEGRATION("zapier")
        .then((res) => {
            setData(res)
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        
        SET_COMPANY_INTEGRATION("zapier", {...data}, [{key: "key"}])
        .then((res) => {
            setLoadingSave(false);
            loadData()
            showNotification({message: "Re-generated zapier API KEY", color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        {!data?.key
        ? <UnstyledButton disabled={loadingSave} style={{width: '100%'}} onClick={() => { handleSave() }}>
            <Paper p="xl" shadow="xs" style={{ border: '1px solid #DFDFDF', borderRadius: 5, backgroundColor: '#FAFAFA' }}>
                <Group>
                    {loadingSave ? <Loader size={30} /> : <Avatar size={30} src="/assets/zapier.png" />}
                    <Box style={{flex: 1}}>
                        <Text size="xl" c="#ff4f00" fw="bold">Conectar Zapier</Text>
                    </Box>
                </Group>
            </Paper>
        </UnstyledButton>
        : <UnstyledButton disabled={loadingSave} style={{width: '100%'}} onClick={() => {
            clipboard.copy(data?.key);
            showNotification({ message: "Copied to clipboard", color: "yellow" })
        }}>
            <Paper p="xl" shadow="xs" style={{ border: '1px solid #DFDFDF', borderRadius: 5, backgroundColor: '#FAFAFA' }}>
                <Group>
                    {loadingSave ? <Loader size={30} /> : <Avatar size={30} src="/assets/zapier.png" />}
                    <Box style={{flex: 1}}>
                        <Text size="sm" c="#ff4f00" fw="bold">{data?.key}</Text>
                    </Box>
                </Group>
            </Paper>
        </UnstyledButton>}
        
        {/* <Group position="right" mt="md">
            <Button size="xs" onClick={handleSave} loading={loadingSave}>Re-Generate</Button>
        </Group> */}
    </>
}
