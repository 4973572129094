import {
  Button,
  Center, Paper, PasswordInput,
  Space, Title
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";

export default function Invite(){
  const { signIn } = useAuth();
  const {key} = useParams();
  const navigate = useNavigate();

  const [recoverData, setRecoverData] = useState<any>(null);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  React.useEffect(() => {
    if(key){
      api.get(`/auth/recover/${key}`)
      .then(({data}) => {
        setRecoverData(data);
      })
      .catch(() => {
        showNotification({title: "Ops", message: "Token para recuperar senha expirado, tente novamente", color: 'red'});
        navigate("/")
      })
    }
  }, [key])

  return (
    <Center style={{ width: "100vw", height: "100vh" }}>
      <Paper
        shadow="xs"
        p="md"
        style={{
          width: "40vw",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            api.post("/auth/recover/confirm", {
              uuid: key,
              password,
            })
            .then(() => {
              navigate("/");
              showNotification({title: "Recuperação de senha", message: "Senha alterada com sucesso", color: 'green'})
            })
            .catch(err => {
              showNotification({title: 'Ops', message: 'Seu token expirou', color: 'red'})
            })
          }}
        >
          <Title order={3} mb="lg">Alterar senha - {recoverData?.email}</Title>
          <PasswordInput
            mt="md"
            label="Escolha sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            mt="md"
            label="Confirmar senha"
            value={passwordConfirmation}
            error={passwordConfirmation && passwordConfirmation !== password && "Você inseriu 2 senhas distintas"}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Space h="xl" />
          <Button
            type="submit"
            mt="xl"
          >
            Alterar Senha
          </Button>
        </form>
      </Paper>
    </Center>
  );
};
