import { api } from "./api"

export const UPLOAD_FILES = async (files : any[] = [], company = null, others = {}) => {
    const formData = new FormData();
    files.forEach(file => { formData.append('files', file) });
    if(company) formData.append('company', company);
    const {data} = await api.post(`/files/upload`, formData, others)
    return data.files || [];
}

export const UPLOAD_DRIVE_FILE = async ({ fileId, oauthId }, others = {}) => {
    const {data} = await api.post(`/files/upload/drive`, { fileId, oauthId }, others)
    return data;
}

export const UPLOAD_CANVA_FILE = async ({ designId, format, oauthId }, others = {}) => {
    const {data} = await api.post(`/files/upload/canva`, { designId, format, oauthId }, others)
    return data;
}

export const GET_ALL_FILES = async (files : any[] = []) => {
    const {data} = await api.get(`/files/all`)
    return data;
}

export const GET_FILE = async (fileId: string) => {
    const {data} = await api.get(`/files/${fileId}`)
    return data;
}
