import { ActionIcon, Loader, Modal, UnstyledButton } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { FaDrawPolygon, FaPlay, FaStop } from "react-icons/fa";
import { CanvasDrawer } from './canvas_drawer';

const DrawView = ({ items = [] }) => {
  const [opened, setOpened] = useState<boolean>(false);

  return <>
    <ActionIcon variant="outline" color="gray" size="lg" onClick={() => setOpened(true)}>
      <FaDrawPolygon />
    </ActionIcon>

    <Modal
      opened={opened}
      size="lg"
      onClose={() => setOpened(false)}
    >
      {items.map(item => <CanvasDrawer
        actions={false}
        image={item.image}
        draw={item.draw}
      />)}
    </Modal>
  </>
}

export default DrawView;